import React from 'react';
import { Translation } from 'react-i18next';
import '../../../assets/css/form.css';

class Entrega extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            initdata:[], 
            camposRequeridos: [],
            direccion:'',
            documento:'',
            nombre:'',
            telefono:'',telefono2:''
        }
        this._isMounted = false;
        //stepzilla
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.isValidated = this.isValidated.bind(this);
        
        //props functions
        this.fetchApi = props.fetchApi.bind(this); //bind from props 
        this.handleToast = props.handleToast.bind(this); //bind from props 
        this.setValuesEntrega = props.setValuesEntrega.bind(this); //bind from props 
        this.getValuesEntrega = props.getValuesEntrega.bind(this); //bind from props 
    }
    componentDidMount(){
        const _self = this;
        this.fetchApi({
            endpoind:'index.php/paqueteria/EntregaForm',
          }, function(result){
                if (result && result.status ==='ok' && result.result){
                    _self.setState({isLoaded: false,  camposRequeridos: result.result.campos});
                }else{
                    _self.setState({isLoaded: true, error: result.message})
                }
                 //obtenemos valores ya informados
                 const values = _self.getValuesEntrega();
                 if (values){
                    result.result.campos.forEach(function(a, i) {
                        _self.setState({ [a]: values[a] });
                     }, this);
                 }
                 _self._isMounted = true;
            }
        );
    }
    componentWillUnmount() {
        this._isMounted = false;
    }//componentWillUnmount

    isValidated() {
        let isDataValid = this._validateData();
        if (!isDataValid){
            this.handleToast(`Todos los campos (*) son requeridos`,'bg-warning');
        }else{
            let dataFinal = {};
            this.state.camposRequeridos.forEach(function(a, i) {
                dataFinal[a] =  this.state[a]; 
            }, this);
            this.setValuesEntrega(dataFinal);
        }
        return isDataValid;
    }
    _validateData() {
        //todos los campos son requeridos
        let valid = true;
        this.state.camposRequeridos.forEach(function(a, i) {
            if (a!=='telefono2' && !this.state[a]) valid = false; 
        }, this);
            
        return valid;
    }

   
    render() {
        const {error, isLoaded, initdata, camposRequeridos} = this.state;
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
       
            return(
                <Translation ns={['paqueteria']}>{(t) =>
                <>
                    <div className="step_content mt-3">
                    <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                         {/*BANNER*/}
                         <div className={"d-flex align-items-end banner-header entrega" }>
                            <div className="title">{t('Información de entrega ')} (Venezuela)</div>
                        </div>
                        <div className="first_step_content_item_wrap d-block mt-4 p-3">

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label className='text-left label'>{t('Dirección')} <span className="required"> (*)</span></label>
                                    <input type="text" id="direccion" name="direccion" className="form-control"
                                        onChange={e => this.setState({ direccion: e.target.value })}
                                        value={this.state.direccion}
                                        autoFocus
                                    />
                                </div>
                                <div className='col-lg-6 pt-3 pb-3'>
                                    <label className='label'>{t('Nombre y apellidos')} <span className="required"> (*)</span></label>
                                    <input type="text" id="nombre" name="nombre" className="form-control"
                                        onChange={e => this.setState({ nombre: e.target.value })}
                                        value={this.state.nombre}
                                    />
                                </div>
                                <div className='col-lg-6 pt-3 pb-3'>
                                    <label className='label'>{t('Nº de documento')} <span className="required"> (*)</span></label>
                                    <input type="text" id="documento" name="documento" className="form-control"
                                        onChange={e => this.setState({ documento: e.target.value })}
                                        value={this.state.documento}
                                    />
                                </div>
                                <div className='col-lg-6 pt-3 pb-3'>
                                    <label className='label'>{t('Teléfono')} <span className="required"> (*)</span></label>
                                    <input type="number" id="telefono" name="telefono" className="form-control"
                                        onChange={e => this.setState({ telefono: e.target.value })}
                                        value={this.state.telefono}
                                    />
                                </div>
                                <div className='col-lg-6 pt-3 pb-3'>
                                    <label className='label'>{t('Teléfono adicional')}</label>
                                    <input type="number" id="telefono2" name="telefono2" className="form-control"
                                        onChange={e => this.setState({ telefono2: e.target.value })}
                                        value={this.state.telefono2}
                                    />
                                </div>
                            </div>
                           
                        </div>
                    
                    </div>
                    </div>
                </>
                }
                </Translation>
            );
        }    
  }
}

export default Entrega;
