import React from 'react';
import Contacto from "./contacto";
import useGetHTMLFetch from "../../server/useGetHTMLFetch";

import {NavLink} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';

export default function AvisoLegal(props) {
    const content = useGetHTMLFetch('index.php/contenidos/avisolegal');

    return(
         <>
            <div dangerouslySetInnerHTML={{__html: content}} />
        </>
     );
}

