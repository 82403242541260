import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import './config/i18n';
import App from './App';


// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

render(<React.StrictMode>
  <App />
</React.StrictMode>, document.getElementById('root'));
