import React,{useState} from "react";
import Auth from '../../middlewares/Auth';
import getLanguage from '../../language/getLanguage';
const lang = getLanguage();
const apiURL = process.env.REACT_APP_API_URL;


class Home extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        content : ''
      }
      this.tokenAccess = Auth.getToken();
  }

    componentDidMount(){
      fetch(apiURL+'index.php/contenidos/home',{
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({token:this.tokenAccess, lang:lang})
      }).then( response => response.json())
      .then(
          (result) => {
              if (result.status ==='ok'){
                  this.setState({ content : result.result});
              }
          }
      );
    }
    render() {
        return(
          <>
             <div dangerouslySetInnerHTML={{__html: this.state.content}} />
          </>

        );

    }
}

export default Home;




/* export default function Home(props) {
    const tokenAccess = props.tokenAccess;
    // const content = useGetHTMLFetch('index.php/contenidos/home');

    const html = getContent();
    document.body.classList.add('home');
    return (
        <InnerHTML html={html} />
    )


    const getContent = () =>{


    }




}*/
