import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './assets/css/styleCustom.css';

import Auth from './middlewares/Auth';
import PrivateRoute from './middlewares/PrivateRoute';


/*-- Todas las rutas --*/
import ScrollToTop from "./components/common/ScrollToTop";
import Header from "./components/common/header";
import Footer from "./components/common/footer";

import Home from './components/publicPages/home';
import Contacto from './components/publicPages/contacto';
import SobreNosotros from './components/publicPages/nosotros';
import Cesta from './components/publicPages/cesta';

import Politicas from './components/publicPages/politicasdeprivacidad';
import AvisoLegal from './components/publicPages/avisolegal';
import Terminos from './components/publicPages/terminosycondiciones';
import PreguntasFrecuentes from './components/publicPages/preguntas-frecuentes';
import NuestrosServicios from './components/publicPages/nuestros-servicios';
import ViewCuponesDigitales from './components/cuponesDigitales/View';
import CanjearCupon from './components/cuponesDigitales/CanjearCupon';
import DetalleServicios from './components/publicPages/detalle-servicios';
import ComoFunciona from './components/publicPages/como-funciona';
import FormularioDinamico from './components/dev/formulario-dinamico';


import CookieConsentNotification from './components/common/CookieConsentNotification';


import Login from './components/login/Login';
import Logout from './components/login/logout';
import RecPass from './components/login/RecPass';
import CambiarPassDesdeEnlace from './components/login/CambiarPassDesdeEnlace';
import CambiarPass from './components/login/CambiarPass';
import Registro from './components/login/Registro';
import ActivarNuevaCuenta from './components/login/ActivarNuevaCuenta';
import AvisoVerificacion from './components/login/AvisoVerificacion';
import Dashboard from './components/cliente/Dashboard';

import Profile from './components/cliente/Profile';
import SaldoMovimientos from './components/cliente/SaldoMovimientos';
import Beneficiarios from './components/beneficiarios/Beneficiarios';
import BeneficiarioManage from './components/beneficiarios/BeneficiarioManage';

/** REMESAS **/
import RemesaCalculadora from './components/remesas/RemesaCalculadora';
import Remesa from './components/remesas/Remesa';
import RemesasHistorico from './components/remesas/Historico';
import RemesaRespuestaPago from './components/remesas/RemesaRespuestaPago';
import RemesaPagoKo from './components/remesas/RemesaPagoKo';
import RespuestaPagosServiciosNoEfectivos from './components/pago/RespuestaPagosServiciosNoEfectivos';
import RespuestaPagosServiciosEfectivas from './components/pago/RespuestaPagosServiciosEfectivas';
import RespuestaPagoCryptocoin from './components/pago/RespuestaPagoCryptocoin';
import RespuestaPagoDefault from './components/pago/RespuestaPagoDefault';

/** PAQUETERIA **/
import EnvioAereoPaqueteria from './components/publicPages/EnvioAereoPaqueteria';
import EnvioMaritimoPaqueteria from './components/publicPages/EnvioMaritimoPaqueteria';
import Paqueteria from './components/paqueteria/Paqueteria';
import TrackingPaqueteria from './components/paqueteria/TrackingPaqueteria';
// import CalculadoraDigitalEnvio from './components/paqueteria/Calculadora-digital-envio-paqueteria';

import CestaCompra from './components/cestaCompra/CestaCompra';

import PageNotFound from './components/PageNotFound';

function App() {
  const publicURL = process.env.PUBLIC_URL;
  const [isAuth, setIsAuth] = React.useState(Auth.getAuth());

  /*--login--*/
  const handleLogin = async (isLogged,token, name, perfil) => {
  // const handleLogin = (isLogged,token, name, perfil) => {
    new Promise((resolve) => {
      setTimeout(() => resolve(Auth.authenticate(token, name, perfil)), 250);
    });
    // Auth.authenticate(token, name, perfil);
    const a = await fakeAuth();
    setIsAuth(a);
  };
  const fakeAuth = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve('2342f2f1d131rf12'), 250);
  });

  /*-- return --*/
  return (
    <Suspense fallback={null}>

      <Router basename={publicURL}>
          <ScrollToTop />
          <React.Fragment>
            <Header isAuth={isAuth}/>
          </React.Fragment>

          <Routes>
            <Route path='/' element={<Home/>}  />
            <Route path='/contacto' element={<Contacto/>} />
            <Route exact="true" path='/sobrenosotros' element={<SobreNosotros/>} />
            <Route exact="true" path='/cesta' element={<Cesta/>} />
            <Route exact="true" path='/politicasdeprivacidad' element={<Politicas/>} />
            <Route exact="true" path='/avisolegal' element={<AvisoLegal/>} />
            <Route exact="true" path='/terminosycondiciones' element={<Terminos/>} />
            <Route exact="true" path='/preguntas-frecuentes' element={<PreguntasFrecuentes/>} />
            <Route exact="true" path='/nuestros-servicios' element={<NuestrosServicios/>} />

            <Route exact="true" path="/detalle-servicios/:servicio/:atributo"  element={<DetalleServicios/>}/>
            <Route exact="true" path='/como-funciona' element={<ComoFunciona/>}/>
            <Route exact="true" path='/formulario-dinamico' element={<FormularioDinamico/>} />


            {/* <Route exact="true" path='/calculadora-digital-envio-paqueteria' element={<CalculadoraDigitalEnvio/>}/> */}

            <Route exact="true" path="/cupones-digitales/:tipo" element={<ViewCuponesDigitales/>} />
            <Route exact="true" path='/cesta-compra' element={<CestaCompra/>}/>

            <Route exact="true" path='/login'
              element={<Login handleLogin={handleLogin}/>}
            />

            <Route exact="true" path='/logout'
              element={<Logout handleLogin={handleLogin}/>}
            />

            <Route exact="true" path='/registro' element={<Registro/>} />
            <Route exact="true" path="/aviso-verificacion" element={<AvisoVerificacion/>} />
            <Route exact="true" path='/recuperar-contrasena' element={<RecPass/>} />
            <Route exact="true" path='/activar-nueva-cuenta/:token' element={<ActivarNuevaCuenta/>} />
            <Route exact="true" path='/cambiar-contrasena-desde-enlace/:correo/:token' element={<CambiarPassDesdeEnlace/>} />

            <Route exact="true" path='/remesa-calculadora' element={<RemesaCalculadora/>} />
            <Route exact="true" path="/remesa-respuesta-pago/:mensaje" element={<RemesaRespuestaPago/>} />
            <Route exact="true" path="/remesa-respuesta-pago/" element={<RemesaRespuestaPago/>} />
            <Route exact="true" path="/remesa-respuesta-pago-KO" element={<RemesaPagoKo/>}  />
            <Route exact="true" path="/RemesaPagoKO" element={<RemesaPagoKo/>}  />

            <Route exact="true" path="/servicios-respuesta-pago-KO" element={<RespuestaPagosServiciosNoEfectivos/>}  />
            <Route exact="true" path="/RespuestaPagosServiciosNoEfectivos" element={<RespuestaPagosServiciosNoEfectivos/>}  />
            <Route exact="true" path="/servicios-respuesta-pago/:mensaje" element={<RespuestaPagosServiciosEfectivas/>}  />
            <Route exact="true" path="/servicios-respuesta-pago/" element={<RespuestaPagosServiciosEfectivas/>}  />
            <Route exact="true" path="/RespuestaPagosServiciosEfectivas/:mensaje" element={<RespuestaPagosServiciosEfectivas/>}  />
            <Route exact="true" path="/RespuestaPagosServiciosEfectivas/" element={<RespuestaPagosServiciosEfectivas/>}  />

            <Route path="/servicios-respuesta-cryptocoin" element={<RespuestaPagoCryptocoin tipo={"servicios"} />} />
            <Route exact="true" path="/respuesta-pago/:attr" element={<RespuestaPagoDefault />} />

            {/* 👇️ PAQUETERIA */}
            <Route exact="true" path='/envio-aereo-paqueteria' element={<EnvioAereoPaqueteria/>} />
            <Route exact="true" path='/envio-maritimo-paqueteria' element={<EnvioMaritimoPaqueteria/>} />

            {/* 👇️ only match this when no other routes match */}
            <Route element={<PageNotFound/>}/>
          </Routes>



          <PrivateRoute exact="true" path='/cambiar-contrasena' element={<CambiarPass/>}  />
          <PrivateRoute exact="true" path="/dashboard" element={<Dashboard/>}/>
          <PrivateRoute exact="true" path="/perfil" element={<Profile/>}  />
          <PrivateRoute exact="true" path="/saldo-movimientos" element={<SaldoMovimientos/>}  />
          <PrivateRoute exact="true" path="/beneficiarios" element={<Beneficiarios/>}  />
          <PrivateRoute exact="true" path="/beneficiario/:id" element={<BeneficiarioManage/>} />

          <PrivateRoute exact="true" path="/remesa/:moneda_destino" element={<Remesa/>}  />
          <PrivateRoute exact="true" path="/remesa" element={<Remesa/>}  />
          <PrivateRoute exact="true" path="/remesas-historico" element={<RemesasHistorico/>}  />


          <PrivateRoute exact="true" path="/canjear-cupon" element={<CanjearCupon/>}  />

          {/* 👇️ PAQUETERIA */}
          <PrivateRoute exact="true" path="/enviarpaqueteria/:tipo" element={<Paqueteria/>}  />
          <PrivateRoute exact="true" path="/tracking-paqueteria" element={<TrackingPaqueteria/>}  />



          <React.Fragment><Footer /></React.Fragment>
          <React.Fragment> <CookieConsentNotification/></React.Fragment>

      </Router>
    </Suspense>
  );
}

export default App;
