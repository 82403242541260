import React, { Component } from 'react';
import PrivateArea from '../cliente/PrivateArea';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import AlertasTrimestre from './AlertasTrimestre';
import { Translation,Trans } from 'react-i18next';
import variables from "../../variables/env.js";

class RemesasHistorico extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            columns :  [],
            data:[], alldata:[], //data sin filtrar
            selectOptions:[],
            totalEnviado:null,
            totalRecibido:null
        };

        this.conditionalRowStyles = [{
            when: row => row.procesado === '1',
            style: {
              backgroundColor: 'green',
              color: 'white',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }];
    }

    calcularTotales = (data,calcularRecibido) => {
        let totalEnviado = 0;
        if (data && data.length > 0){
            data.map((d)=>{ totalEnviado=Number(totalEnviado) + Number(d.cantidad_enviar);});
            //this.setState({totalEnviado:totalEnviado.toFixed(2)});
            if (calcularRecibido){
                let totalRecibido = 0, moneda='';
                data.map((d)=>{
                    totalRecibido = Number(totalRecibido) + Number(d.cantidad_recibir);
                    moneda=  d.cantidad_recibida.replace(d.cantidad_recibir,'');//d.moneda;
                });
                this.setState({totalRecibido:totalRecibido.toFixed(2)+moneda});
            }else{
                this.setState({totalRecibido:null});
            }
        }

    }
    /* al seleccionar un beneficiario del select */
    handleFiltroBeneficiario = selectedOption => { console.log(selectedOption);
        let allData = this.state.alldata, filteredData;
        if (selectedOption.value){
            filteredData = allData.filter(value => {
                return ( value.beneficiario.includes(selectedOption.label));
            });
        }else{
            filteredData = allData;
        }
        this.setState({data:filteredData});
        let calcularRecibido = (selectedOption.value)?true:false;
        this.calcularTotales(filteredData, calcularRecibido);
    }
    creaSelectBeneficiarios=(r)=>{
        if (r && r.length){
            const options = r.map((beneficiario, index) => {
                 return {
                    label: beneficiario.nombre_beneficiario,
                    value: beneficiario.id
                 }
            });
            options.unshift({label: '-', value:''});
            this.setState({selectOptions : options});
        }
    }
    obtenerListaBeneficiariosFiltro = () => {
        fetch(variables.apiurl+'index.php/beneficiario/usuarioBeneficiarios',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess, deleted:1})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.creaSelectBeneficiarios(result.result.data);
                }
            }
        );
    }
    obtenerColumnas=(columns)=>{
        const adminURL = process.env.REACT_APP_ADMIN_URL;
        const cols = columns.map((c) => {
            let s = c.selector;
            if (s === 'n_factura'){
                c.ignoreRowClick = true;
                c.cell = row => (
                      <a
                        href={variables.apiurl+'factura-remesa.php?token='+this.tokenAccess+'&mienv='+variables.mienv+'&iid=' + row['n_factura']}
                        target="_blank"
                        rel="noopener noreferrer">
                        {row.n_factura}
                      </a>
                );
            }
            c.selector = row => (row[s]);
            c.name  = this.getStringTranslation(c.name);
            return c;
        });
        this.setState({columns : cols});
    }
    getStringTranslation(jsonElement){
      return (<Trans defaults={jsonElement} />)
    }//getStringTranslation

    componentDidMount(){
      this.tokenAccess = variables.auth('token');
      this.servicioRemesaActivo = variables.auth('servicioRemesaActivo');
        fetch(variables.apiurl +'index.php/cliente/obtenerHistoricoRemesas',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    this.obtenerColumnas(result.result.columns);
                    this.setState({
                        data : result.result.data,
                        alldata : result.result.data
                    });
                    this.setState({columns : result.result.columns});
                    this.obtenerListaBeneficiariosFiltro();
                    this.calcularTotales(result.result.data);
                }else{
                    this.setState({
                        isLoaded: true,
                        error: result.message
                    });
                    if (result.action==='login'){
                      //  window.location.href = '/Login';
                    }
                }
            },
            // Handle error
            (error) => {
                console.log(error);
                this.setState({
                    isLoaded: true
                })
            },
        )
    }
    render() {
        const {error, isLoaded, columns, data, selectOptions,totalEnviado,totalRecibido} = this.state;

        if(!isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
<Translation ns={['beneficiarios']}>
{
  (t) =><PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="historico_tab" activeLink="historico" servicioRemesaActivo={this.servicioRemesaActivo}>
  <div className="history-tab_wrap">
      {error && <div className="alert alert-danger text-uppercase" role="alert"><i className="fa fa-times" aria-hidden="true"></i> {error}</div>}

      {columns && <div>
          <div className="history-tab_title">
            <h3>{t('historico.titulo')}</h3>
            {/* filtros*/}
            {selectOptions && <Select className="col-12 col-md-6"
                 onChange={this.handleFiltroBeneficiario}
                 placeholder={t("Seleccione un beneficiario")}
                 options={selectOptions}
                 value={this.state.beneficiarioDefaultValue}
                 conditionalRowStyles={this.conditionalRowStyles}
              />
            }
            {totalEnviado && <div className="col-md-3 col-12 mb-3 small">{t('historico.Total enviado')}: <br/><strong>{totalEnviado}</strong></div>}
            {totalRecibido && <div className="col-md-3 col-12 mb-3 small">{t('historico.Total recibido')}: <br/><strong>{totalRecibido}</strong></div>}
          </div>
          <DataTable
              columns={columns}
              data={data}
              pagination={true}
          />
      </div>}
      <AlertasTrimestre tokenAccess={this.tokenAccess}/>
      </div>
  </PrivateArea>
}
</Translation>
        );
        }
    }
}
export default RemesasHistorico;
