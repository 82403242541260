// useFetch.js
import { useState, useEffect } from "react";
import getLanguage from '../language/getLanguage';
import Auth from '../middlewares/Auth';
const lang = getLanguage();

export default function useGetHTMLFetch(url) {
    const token = Auth.getToken();
    const bodyjson = {lang:lang};
    if (token) bodyjson.token = token;
    const apiURL = process.env.REACT_APP_API_URL;
    const [data, setData] = useState('');

    async function getData() {
        const response = await fetch(apiURL+url,{
            method: 'post',
            //mode: 'no-cors',
            headers: {
                'Accept': 'text/html',
                'Content-Type': 'text/html; charset=utf-8'
            },
            body: JSON.stringify(bodyjson)
        });
        const data = await response.text();
        setData(data);
    }

    useEffect(() => {
        getData();
    }, []);

    return data;
}
