import React,{useState,useEffect} from 'react';
import { Link,NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import variables from "../../variables/env.js";
import getLanguage from "../../language/getLanguage";

function PrivateArea(props) {
  const [activeLink] = useState(props.activeLink);
  const { t } = useTranslation(['menu']);
  const [serviciosActivos, setServiciosActivos] = useState(null);
  const lang = getLanguage();
  const tokenAccess = variables.auth('token');
  let arrayServi = [];

  /****** movemos scroll menu ******/
  useEffect(() => {
    const menuDashboard = document.getElementById('v-pills-tab');
    if (menuDashboard){
      const menuDashboardActive = menuDashboard.querySelector('.active');
      if (menuDashboardActive) menuDashboard.scrollLeft = menuDashboardActive.offsetLeft;
    }
    getServiciosActivos();
  }, [location]);
   
  const getServiciosActivos = () => {
      if (!serviciosActivos){
        fetch(variables.apiurl + 'index.php/cliente/serviciosActivos', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({lang: lang, token:tokenAccess})
        }).then(response => response.json())
            .then(
                (result) => {
                  if (result.status === 'ok') {
                    setServiciosActivos(result.result.data);
                  }
                }
            );
      }
    }
    if (serviciosActivos !== null){arrayServi = serviciosActivos.split(',')}
  return (
    <>
      <main className={props.mainClass + 'd-flex pt-0'} id="wrapper">
          <div className="container-fluid p-0">
            <div className="row m-0">
              <div className="col-12 p-0">
                {variables.auth() &&
                  <div className={props.wrapperClass + ' d-flex'}>
                    <aside className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                        <Link to="/dashboard" className={(activeLink === 'dashboard')? 'nav-link active':'nav-link'}>
                          <span className="icon" style={(activeLink === 'dashboard')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/home.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/home.svg)'}}></span>
                          <span className="text">{t('Dashboard')}</span>
                        </Link>
                      {/* Bloque de servicio de remesa */}
                        {arrayServi && arrayServi.length > 0 && arrayServi.indexOf('4') > -1  && <>
                            <Link to="/remesa" className={(activeLink === 'remesa')?'nav-link active ':'nav-link '}>
                              <span className="icon" style={(activeLink === 'remesa')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/envia-remesa.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/envia-remesa.svg)'}}></span>
                              <span className="text">{t('Envia Remesa')}</span>
                            </Link>

                            <Link to="/remesas-historico" className={(activeLink === 'historico')?'nav-link active':'nav-link'}>
                              <span className="icon" style={(activeLink === 'historico')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/historico-remesas.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/historico-remesas.svg)'}}></span>
                              <span className="text">{t('Histórico de Remesas')}</span>
                            </Link>

                          <Link to="/beneficiarios" className={(activeLink === 'beneficiarios')?'nav-link active':'nav-link'}>
                            <span className="icon" style={(activeLink === 'beneficiarios')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/beneficiarios.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/beneficiarios.svg)'}}></span>
                            <span className="text">{t('Beneficiarios')}</span>
                          </Link>
                        </>
                        }

                      {/* Bloque de servicio Paquetería */}
                      {arrayServi && arrayServi.length > 0 && (arrayServi.indexOf('11') > -1 || arrayServi.indexOf('12') > -1 || serviciosActivos.indexOf('13') > -1) && <>
                          <div className='navsep d-none d-lg-block'></div>
                          {/* usamos href porque ambos usan el mismo componente */}
                          <a href="/enviarpaqueteria/1" className={(activeLink === 'PaqueteriaAerea')?'nav-link active':'nav-link'}>
                            <span className="icon" style={(activeLink === 'PaqueteriaAerea')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/localizar-paquete.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/localizar-paquete.svg)'}}></span>
                            <span className="text">{t('Paqueteria Aerea')}</span>
                          </a>
                          <a href="/enviarpaqueteria/2" className={(activeLink === 'PaqueteriaMaritima')?'nav-link active':'nav-link'}>
                            <span className="icon" style={(activeLink === 'PaqueteriaMaritima')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/localizar-paquete.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/localizar-paquete.svg)'}}></span>
                            <span className="text">{t('Paqueteria Maritima')}</span>
                          </a>
                          <Link to="/tracking-paqueteria" className={(activeLink === 'TrakingPaqueteria')?'nav-link active':'nav-link'}>
                            <span className="icon" style={(activeLink === 'TrakingPaqueteria')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/localizar-paquete.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/localizar-paquete.svg)'}}></span>
                            <span className="text">{t('Localiza tu Paquete')}</span>
                          </Link>
                      </>}
                      <div className='navsep d-none d-lg-block'></div>
                      {/* Bloque de servicio Digitales (Recargas Telefónica) */}
                      {arrayServi && arrayServi.length > 0 && arrayServi.indexOf('6') > -1 &&  <>
                        
                        <Link to='/cupones-digitales/recargas-telefonicas' className={(activeLink === 'recargas-telefonicas')?'nav-link active':'nav-link'} tabIndex="1">
                          <span className="icon" style={(activeLink === 'recargas-telefonicas')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/recarga-telefonica.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/recarga-telefonica.svg)'}}></span>
                          <span className="text">{t('Recargas Telefónicas')}</span>
                        </Link>
                      </>
                      }

                      {/* Bloque de servicio Digitales (Cupones Tarjeta Regalo) */}
                      {arrayServi && arrayServi.length > 0 && arrayServi.indexOf('9') > -1  && <>
                        <Link to="/cupones-digitales/tarjeta-regalo" className={(activeLink === 'tarjeta-regalo')?'nav-link active':'nav-link'} tabIndex="2">
                          <span className="icon" style={(activeLink === 'tarjeta-regalo')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/tarjetas-regalos.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/tarjetas-regalos.svg)'}}></span>
                          <span className="text">{t('Tarjeta Regalos')}</span>
                        </Link>
                      </>
                      }
                        {/* Bloque de servicio Digitales (Cupones Contenidos digitales) */}
                        {arrayServi && arrayServi.length > 0 && arrayServi.indexOf('7') > -1 && <>
                            <Link to="/cupones-digitales/contenidos-digitales" className={(activeLink === 'contenidos-digitales')?'nav-link active':'nav-link'}>
                              <span className="icon" style={(activeLink === 'contenidos-digitales')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/tarjetas-regalos.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/tarjetas-regalos.svg)'}}></span>
                              <span className="text">{t('Contenidos Digitales')}</span>
                            </Link>
                        </>
                            }
                        {/* Bloque de servicio Digitales (Cupones Contenidos digitales) */}
                        {arrayServi && arrayServi.length > 0 && arrayServi.indexOf('8') > -1 && <>
                            <Link to="/cupones-digitales/medio-pagos" className={(activeLink === 'medio-pagos')?'nav-link active':'nav-link'}>
                              <span className="icon" style={(activeLink === 'medio-pagos')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/medios-pago.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/medios-pago.svg)'}}></span>
                              <span className="text">{t('Medios de Pago')}</span>
                            </Link>

                        <Link to="/canjear-cupon" className={(activeLink === 'canjearCupon')?'nav-link active':'nav-link'}>
                          <span className="icon" style={(activeLink === 'canjearCupon')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/canjear-cupon.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/canjear-cupon.svg)'}}></span>
                          <span className="text">{t('Canjear Cupon')}</span>
                        </Link>
                      </>
                      }
                      {/* Movimiento con compra de saldos Euri */}
                        {arrayServi && arrayServi.length > 0 && arrayServi.indexOf('4') > -1  && variables.includeSaldo()  &&
                            <Link  to="/saldo-movimientos" className={(activeLink === 'movimientosSaldo')?'nav-link active':'nav-link'}>
                          <span className="icon" style={(activeLink === 'movimientosSaldo')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/movimiento-saldo.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/movimiento-saldo.svg)'}}></span>
                          <span className="text">{t('Movimientos de Saldo')}</span>
                        </Link>
                        }
                        <div className='navsep d-none d-lg-block'></div>
                        <Link to="/perfil" className={(activeLink === 'profile')?'nav-link active':'nav-link'}>
                          <span className="icon" style={(activeLink === 'profile')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/datos-personales.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/datos-personales.svg)'}}></span>
                          <span className="text">{t('Datos Personales')}</span>
                        </Link>

                        <Link to="/cambiar-contrasena" className={(activeLink === 'password')?'nav-link active':'nav-link'}>
                          <span className="icon" style={(activeLink === 'password')? {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/cambiar-contrasenya.svg)'} : {backgroundImage: 'url('+variables.appurl+'assets/img/menuicons/cambiar-contrasenya.svg)'}}></span>
                          <span className="text">{t('Cambiar Contraseña')}</span>
                        </Link>

                        <Link to="/logout" id="logout" className="logout">
                          <img src={variables.appurl+"/assets/img/log-out.svg"} alt={t('Cerrar Sesion')} />
                          <span className="text">{t('Cerrar Sesion')}</span>
                        </Link>
                    </aside>

                    <div className="tab-content" id="v-pills-dashboard-tab">
                      <div className={props.tabClass + ' tab-pane fade show active'} id="v-pills-dashboard" role="tabpanel" aria-labelledby="v-pills-dashboard-tab">
                        {props.children}
                      </div>
                    </div>
                  </div>
                }
                {!variables.auth() &&
                  <div className="profile_wrap">
                      <div className="result_header">
                          <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.sitename} /></a>  </div>
                      </div>
                      <div className="result_body text-center">
                          <NavLink exact="true"  to="/login" className="accent_btn">{t('Login', { ns: 'menu' })}</NavLink>
                      </div>
                      <div className="result_footer ">
                          <div className="polygon_item"></div>
                      </div>
                  </div>
                }
              </div>
            </div>
            </div>

      </main>
    </>
  );
}


export default PrivateArea;
