
import React from 'react';
import { Translation } from 'react-i18next';
import {MdInfoOutline} from 'react-icons/md';

class Confirmacion extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : false,
            dataFinalEnvio:null, 
        }
        this._isMounted = false;
        //props functions
        this.idTipoPaqueteria = props.idTipoPaqueteria; 
        this.fetchApi = props.fetchApi.bind(this); //bind from props 
        this.handleToast = props.handleToast.bind(this); //bind from props 
        this.getValuesConfirmacion = props.getValuesConfirmacion.bind(this); //bind from props
        this.moneda = props.moneda;

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.isValidated = this.isValidated.bind(this);
       
    }
    isValidated() {
        let isDataValid = this.state.dataFinalEnvio.continuar;
        return isDataValid;
    }
    componentDidMount(){
        //obtenemos valores ya informados
        this.getValuesConfirmacion(); //usamos junto a *componentDidUpdate async function
        this._isMounted = true;
    }
    /*Whenever a class component is updated*/
    componentDidUpdate(prevProps) {
        if (prevProps.dataFinalEnvio !== this.props.dataFinalEnvio) {
          this.setState({dataFinalEnvio: this.props.dataFinalEnvio});
        }
        
      }
    componentWillUnmount() {
        this._isMounted = false;
    }//componentWillUnmount

    render() {
        const {error, isLoaded, dataFinalEnvio} = this.state;
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
                <Translation ns={['paqueteria']}>{(t) =>
                <>
                <div className="step_content mt-3">
                    <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                        <h4>{t('Confirmación')}</h4>
                        <p className="small"><MdInfoOutline className="text-warning"/> {t('Por favor confirma la información suministrada antes de continuar')} </p>
                        <div className="mt-2 remesa-confirmacion first_step_content_item_wrap d-block bg-light border p-3">
                            {dataFinalEnvio &&
                            <div className="row">
                                <div className="col-12">
                                    {dataFinalEnvio.origendestino && 
                                    <div className="row border-bottom pt-2 pb-2 m-0">
                                        <div className="col-lg-4">
                                            <p className="text-uppercase">{t('Origen')}</p>
                                        </div>
                                        <div className="col-lg-8 text-lg-right">
                                            <p className="text-uppercase font-weight-bold">{dataFinalEnvio.origendestino.pais_origen}, {dataFinalEnvio.origendestino.ciudad_origen}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className="text-uppercase">{t('Destino')}</p>
                                        </div>
                                        <div className="col-lg-8 text-lg-right">
                                            <p className="text-uppercase font-weight-bold">{dataFinalEnvio.origendestino.pais_destino}, {dataFinalEnvio.origendestino.estado_destino} {dataFinalEnvio.origendestino.ciudad_destino}</p>
                                        </div>
                                    </div>
                                    }
                                    {dataFinalEnvio.paquetes &&
                                    <div className="row border-bottom pt-2 pb-2 m-0">
                                        <div className="col-lg-6">
                                            <p className="text-uppercase">{t('Paquetes')}</p>
                                        </div>
                                        <div className="col-lg-6 text-lg-right">
                                            {dataFinalEnvio.paquetes.map((p, index) => { 
                                                return  <p key={index} className="font-weight-bold">{p.paquete} </p>
                                            })}
                                        </div>   
                                    </div> 
                                    }  
                                   
                                    {dataFinalEnvio.recogida &&  dataFinalEnvio.recogida.incluye_recogida == 'si' &&
                                    <div className="row border-bottom pt-2 pb-2 m-0">
                                        <div className="col-lg-6">
                                            <p className="text-uppercase">{t('Información de recogida')}</p>
                                        </div>
                                        <div className="col-lg-6 text-lg-right">
                                           
                                            {dataFinalEnvio.recogida.direccion && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 p-0">
                                                    <p className="text-uppercase">{t('Dirección')} </p> <span className="font-weight-bold">{dataFinalEnvio.recogida.direccion}</span>
                                                </div>
                                            } 
                                            {dataFinalEnvio.recogida.codigo_postal && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 p-0">
                                                     <p className="text-uppercase">{t('Código postal')}</p> <span className="font-weight-bold">{dataFinalEnvio.recogida.codigo_postal}</span>
                                                </div>
                                            }
                                            {dataFinalEnvio.recogida.telefono && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 p-0">
                                                    <p className="text-uppercase">{t('Teléfono')}</p> <span className="font-weight-bold">{dataFinalEnvio.recogida.telefono}</span>
                                                </div>
                                            }
                                        </div>
                                        
                                    </div>
                                    }  
                                    {dataFinalEnvio.entrega && 
                                    <div className="row border-bottom pt-2 pb-2 m-0">
                                        <div className="col-lg-6">
                                            <p className="text-uppercase">{t('Información de entrega')}</p>
                                        </div>
                                        <div className="col-lg-6">
                                            {dataFinalEnvio.entrega.direccion && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 pb-0">
                                                    <p className="text-uppercase">{t('Dirección')}</p><span className="font-weight-bold">{dataFinalEnvio.entrega.direccion}</span>
                                                </div>
                                            } 
                                            {dataFinalEnvio.entrega.nombre && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 p-0">
                                                    <p className="text-uppercase">{t('Nombre y apellidos')}</p><span className="font-weight-bold">{dataFinalEnvio.entrega.nombre}</span>
                                                </div>
                                            }
                                             {dataFinalEnvio.entrega.documento && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 p-0">
                                                 <p className="text-uppercase">{t('Nº de documento')} </p><span className="font-weight-bold">{dataFinalEnvio.entrega.documento}</span>
                                                </div>
                                            }
                                            {dataFinalEnvio.entrega.telefono && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 p-0">
                                                    <p className="text-uppercase">{t('Teléfono')} </p><span className="font-weight-bold">{dataFinalEnvio.entrega.telefono}</span>
                                                </div>
                                            }
                                            {dataFinalEnvio.entrega.telefono2 && 
                                                <div className="beneficiaries_wrap_table_item border-bottom-0 p-0">
                                                     <p className="text-uppercase">{t('Teléfono adicional')} </p><span className="font-weight-bold">{dataFinalEnvio.entrega.telefono2}</span>
                                                </div>
                                            }
                                        </div>
                                        
                                    </div>
                                    } 

                                    {dataFinalEnvio.desglose && dataFinalEnvio.desglose.length > 0 &&<>
                                        {dataFinalEnvio.desglose.map((d, index) => {
                                            //  return  <option key={index} value={d.value}>{t(d.label)}</option>
                                             return <div key={index} className="row border-bottom pt-2 pb-2 m-0">
                                                <div className="col-6">
                                                    <p className="text-uppercase">{t(d.label)}</p>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <p className="text-uppercase font-weight-bold">{d.valor} {this.moneda}</p>
                                                </div>
                                            </div>
                                        })}
                                    </>}
                                    
                                    <div className="row pt-4 pb-4 m-0">
                                            <div className="col-lg-6 text-center text-lg-left">
                                                <h5 className="h3 color-naranja">{t('Total a pagar')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-center text-lg-right">
                                                <p className="h3 font-weight-bold color-naranja">{dataFinalEnvio.totalformat} {this.moneda}</p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            }
                        </div>     
                    </div>
                </div>    
                </>
            }
            </Translation>
            );
        }
    }    
}

export default Confirmacion;
    