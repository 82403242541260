/*---------------------------------------------*/
//login save data in sessionStorage
/*---------------------------------------------*/
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import variables from "../../variables/env.js";
import getLanguage from '../../language/getLanguage';
const lang = getLanguage();

function Login(props) {
    const { t, i18n } = useTranslation(['login']);
    const {register, handleSubmit, formState: { errors }  } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [KOmessage, setKOmessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [apiBanner, setApiBanner] = useState('');
    const navigate = useNavigate();

    /*BANNER DESDE API-----------------------------*/
    if (apiBanner === ''){
      fetch(variables.apiurl+'index.php/contenidos/bannerlogin',{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({lang:lang})
      }).then( response => response.json())
      .then(
          (result) => {
              if (result.status ==='ok'){
                setApiBanner(result.result);
              }
          }
      );
    }
    /*---------------------------------------------*/
    const setLoggedIn = async (result) => {//= (result) => {
        setLoading(true);
        const token = await props.handleLogin(true,result.token,result.name, result.perfil);
        variables.setCountCesta(result.cesta.count || 0);
        navigate('/dashboard');
        //props.handleLogin(true,result.token,result.name, result.perfil);
    }
    const handlePasswordShow = event =>{ setShowPassword(!showPassword); }

    const handleInputFocus = event => {
          setKOmessage('');
    }
    const onError = (errors, e) => { console.log('errors', errors)};
    
    
    
    /** Enviamos nombre de la cookie para el traspaso una vez se haya loguinado */
    const onSubmit = () => {
        fetch(variables.apiurl+'login/login.php', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: document.getElementById('email').value,
                password: document.getElementById('password').value,
                cestaCookie:variables.getIdCesta(),
                DEVENV : variables.isdev || false,
                lang:lang
            })
        }).then((Response) => Response.json())
            .then((result) => {
                if (result.status === 'ok' && result.result && result.result.token){
                   setLoggedIn(result.result);
                }else{
                    setKOmessage(result.message);
                }
            })
    }
    /* body class */
    const addClassToBody = () => { document.body.classList.add('login'); }
    addClassToBody();
    /* control usuario autenticado */
    const isAuth = variables.auth('');
    useEffect(() => {
    if (isAuth){
     navigate("/dashboard")
    }
},[])
  return (

    <main className="login-main">
      <section className="login_section">

          <div className='container'>
            <div className='row align-items-center bg-light'>

                  <div className="col-lg-6 col-12 pt-4 pr-md-4 order-lg-2">
                      {KOmessage && <div className="alert alert-danger text-uppercase" role="alert">
                        <div dangerouslySetInnerHTML={{__html: KOmessage}} />
                      </div>}
                     
                     
                      <h2 className="primary-color">{t('iniciaSesion.titulo', { ns: 'login' })}
                         {/* CUSTOM APPNAME */}
                         { variables.appname === 'I-ven' && <><span className="color-rojo1">i-</span><span className="color-verde2">V</span>en</> }
                          { variables.appname === 'IntercomWWS' && <>Intercom<span className="text-dark">wws</span></> }
                      !</h2>
                      <h3 className="text-normal pb-4">
                          {t('iniciaSesion.subtitulo', { ns: 'login' })} 
                      </h3>
                      <div className="login_form">
                      {loading && <div className="loading Login"><div className="spinner-border text-muted"></div></div>}

                          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
                      {!loading &&    <form id="frm-login" onSubmit={handleSubmit(onSubmit, onError)}>
                          {/* register your input into the hook by invoking the "register" function */}

                              <label className="mb-3">
                                {t('form.Email', { ns: 'login' })}
                                <br />
                                {errors && errors.email && errors.email.type==='required' && <span id="emailHelp">{t('form.error.Email requerido', { ns: 'login' })}</span>}
                                {errors && errors.email && errors.email.type==='pattern' && <span id="emailHelp">{t('form.error.Email inválido', { ns: 'login' })}</span>}
                                <input {...register('email', { required: true, pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })}
                                  type="email" name='email' id='email' autoComplete="on" onFocus={handleInputFocus}  placeholder={t('form.Email', { ns: 'login' })} aria-describedby="emailHelp" />
                              </label>

                                <label className="mb-3">
                                    {t('form.Pass', { ns: 'login' })}
                                  <br/>
                                  <div className="input-group" >
                                    {errors && errors.password && errors.password.type==='required' && <span id="passHelp">{t('form.error.Contraseña requerida', { ns: 'login' })}</span>}
                                    {errors && errors.password && errors.password.type==='minLength' && <span id="passHelp">{t('form.error.Contraseña inválida', { ns: 'login' })}</span>}
                                    <input {...register('password',{ required: true,minLength: 4 })} className="form-control"
                                      type={showPassword ? "text" : "password"} name='password' id='password' autoComplete="on" onFocus={handleInputFocus} placeholder={t('form.Pass', { ns: 'login' })} aria-describedby="passHelp" />
                                      <div className="input-group-append">
                                         <button className="btn btn-outline-secondary" type="button" onClick={handlePasswordShow}><i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></button>
                                       </div>
                                  </div>
                                </label>

                              <NavLink exact="true" to="/recuperar-contrasena" className="forgot_pasword small d-block text-right">
                                {t('Recuperar.enlace', { ns: 'login' })}
                              </NavLink>

                              <button type="submit" className="transparent_btn">{t('iniciaSesion.boton', { ns: 'login' })}</button>


                              <p>
                                {t('login:Registro.enlace')}
                              </p>

                              <NavLink exact="true" to="/registro" className="register_link">{t('Registro.botonregistrate', { ns: 'login' })} </NavLink>

                          </form>
                        }
                      </div>
                  </div>
                  <div className='col-lg-6 col-12 order-lg-1'>
                    <div dangerouslySetInnerHTML={{__html: apiBanner}} />
                  </div> 

            </div>{/* row */}

          </div>{/* container */}

      </section>

    </main>


   );
}

export default Login;
