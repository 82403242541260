
const FormTools = {

    transformErrors(errors) {
      return errors.map(error => {
        if (error.name === "required") {
          error.message = "Valor requerido";
        }
        if (error.name === "pattern" || error.name === "format") {
          error.message = "valor no permitido";
        }
        // if (error.name === "enum") {
        //   error.message = "debe ser igual a uno de los valores permitidos";
        // }
        if (error.name === "minLength") {
          error.message = "NO debe tener menos de "+error.params.limit+" caracteres";
        }
        if (error.name === "uniqueItems") {
          error.message = "uniqueItems";
        }
        return error;
      });
    }



};
export default FormTools;
