import React,{ useState, useEffect } from 'react';
import Auth from '../../middlewares/Auth';
import Cookie from 'universal-cookie';


const removeCookie = () => {
    const cestaCookieName = process.env.REACT_APP_CESTA_COOKIE_NAME;
    const domain = process.env.REACT_APP_DOMAIN;
    const cookie = new Cookie();
    cookie.remove(cestaCookieName, { path: '/',domain: domain,sameSite: 'none',secure: true });
}
const removeBodyClass = () => { document.body.className = "";}

const removeCountCesta = () => {
    const divCountCesta = document.getElementById("cart_menu_num");
    if (divCountCesta) divCountCesta.innerHTML = '';
}

function Logout(props) {
  // trigger on component mount
  useEffect(() => {
    props.handleLogin(false);
  }, []);
    Auth.signOut();
    removeCookie();
    removeBodyClass();
    removeCountCesta();
    window.location.href = '/';
    return (

      <>
        <div className="loading Logout"><div className="spinner-border text-muted"></div></div>
      </>
    );
}

export default Logout;
