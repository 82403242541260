import React, {useState}  from 'react';
import withRouter from '../../withRouter';
import variables from "../../variables/env.js";
import PrivateArea from '../cliente/PrivateArea';
import CuponesDigitales from "./CuponesDigitales";

function ViewCuponesDigitales(props) {
  const [tipo, setTipo] = useState(props.params.tipo);

  return (
<>
        {variables.auth() &&
          <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper cupones_wrap" tabClass="cupones-tab" activeLink={tipo} >
            <CuponesDigitales {...props}/>
          </PrivateArea>
        }
        {!variables.auth() &&
          <>
            <CuponesDigitales {...props}/>
          </>
        }
</>
  );
}


export default withRouter(ViewCuponesDigitales);
