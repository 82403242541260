/*
viene de FVAPI "customer_callback_url"
redirige a login_url
*/

import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from 'react-i18next';

class AvisoVerificacion extends Component {
    constructor(props){
        super(props);
    }

    render() {
            return(
            <Translation ns={['translation', 'login']}>
              {
                (t) =>

                <div className="cover-container d-flex w-100 h-100 mx-auto flex-column text-center p-5">

                      <main role="main" className="inner container jumbotron">
                        <h2 className="lead"> {t('login:Verificacion.callback')} </h2>
                        <p className="lead pt-5">
                          <NavLink exact className="btn btn-lg bg-naranja color-white" to="/Login">{t('Identifícate')}</NavLink>
                        </p>
                      </main>
                </div>
              }
              </Translation>
            );
  }
}
export default AvisoVerificacion;
