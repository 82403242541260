import React, { Component } from 'react';
import InputNumber from 'react-input-number';
import InputGroup from 'react-bootstrap/InputGroup';
import { Translation } from 'react-i18next';

class ImporteCuponesDigitales extends Component{
    constructor(props) {
        super(props);
        this.state = {
            opcionSeleccionada: null,
            importe: '',
            importeManual: '',
            cantidad:1,
            siglaMoneda: null,
            siglaMonedaDestino: null,
            importeComision: null,
        };
        /*-- CuponesDigitales.js --*/
        this.dataCupon = props.dataCupon;
        this.setImporte = props.setImporte.bind(this);
        this.setCantidad = props.setCantidad.bind(this);
    }
    /************ Al seleccionar de la lista de importes **********/
    seleccionarImporteDesdeLista = (event, data, key) => {
        this.setState({ opcionSeleccionada: key});
        this.setState({importe:data.importe.importe});
        this.setImporte(data.importe);
    }
    /************ Al seleccionar importe manual **********/
    habilitarImporteManual = (event, data, key) => {
        this.setState({ opcionSeleccionada: 'manual'});
    }

    /************ Al cambiar importe manual **********/
    handleChangeImporteManual = e => {
        // console.log('handleChangeImporteManual',this.dataCupon);
        let v = Number(e.target.value);
        if (v === 0 && String(v).length === 1) {
            this.setState({importeManual:''});
        }else{
          this.setState({importeManual:v});
        }
        //tomamos los datos del primer item del array de importes y cambiamos el valor
        let d = Object.assign({}, this.dataCupon.importes[0]);
        d.importeManual = v;
       this.setImporte(d);

    }
    /************ Al cambiar cantidad de cupones a comprar **********/
    handleChangeCantidad = (cantidad) => {
        this.setState({cantidad : cantidad});
        this.setCantidad(cantidad);
    };
    /***********  handle para disminuir cantidad  ***************/
    handleMinus = () => {
        if (parseInt(this.state.cantidad, 10) - 1 >= 1){
            this.setState({cantidad: parseInt(this.state.cantidad) - 1});
            this.setCantidad( parseInt(this.state.cantidad) - 1);
        }
    }
    /***********  handle para aumentar cantidad  ***************/
    handlePlus = () => {
        this.setState({cantidad: parseInt(this.state.cantidad) + 1});
        this.setCantidad( parseInt(this.state.cantidad) + 1);
    };



    render() {
        const {importe,importeManual,cantidad,opcionSeleccionada} = this.state;
        return(

            <Translation ns={['cupones-digitales']}>{
                (t) =>
                    <>
                        <h4> {t('Indica el importe')}</h4>
                        {/* LISTA DE IMPORTES (r_importe_codigo_digital) */}
                        <span className="ListaImportes">
                            { this.dataCupon.importes.map((importe, index) => {
                                    return <span key={index}
                                                 id={`importe-cupones-digitales-${index}`}
                                                 onClick={(e) => this.seleccionarImporteDesdeLista(e, {importe}, index)}
                                                 className={opcionSeleccionada === index ? 'cursor-pointer btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3 border-active-intercom' : 'btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3 cursor-pointer'}
                                                > {importe.importe + ' '+ importe.monedas_destino }
                                    </span>
                            })}
                            {Number(this.dataCupon.importe_multiplo) > 0 &&
                              <span
                                   id={`importe-manual-${this.dataCupon.id}`}
                                   onClick={(e) => this.habilitarImporteManual()}
                                   className={opcionSeleccionada === 'manual' ? 'cursor-pointer btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3 border-active-intercom' : 'btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3 cursor-pointer'}
                                  > {t('Otro') }
                              </span>
                            }
                        </span>
                        {opcionSeleccionada === 'manual' &&
                          <>
                            <h6 className="mt-4 mb-0 font-weight-bold">{t('Importe manual')}</h6>
                            <div className="row m-0">
                              <div className="col-lg-8 mt-2 pl-0">
                                <input type="number" id="importe_manual"  className="form-control mt-0"
                                  onChange={this.handleChangeImporteManual}
                                  onWheel={(e) => e.target.blur()}
                                  value={importeManual}
                                  autoFocus={true}
                                  />
                                <small className="form-text text-muted">
                                  Min: {String(this.dataCupon.importe_minimo).replace(".", ",")} {this.dataCupon.importes[0].monedas_destino}&nbsp;/&nbsp;
                                  Max: {String(this.dataCupon.importe_maximo).replace(".", ",")} {this.dataCupon.importes[0].monedas_destino}&nbsp;/&nbsp;
                                  {t('Intervalo')}: {String(this.dataCupon.importe_multiplo).replace(".", ",")} {this.dataCupon.importes[0].monedas_destino}
                                </small>
                              </div>
                            </div>

                          </>
                        }
                        {(Number(importe) > 0 || Number(importeManual) > 0) && <>
                        <h6 className="mt-4 mb-0 font-weight-bold">{t('Indica la cantidad', { ns: 'cupones-digitales' })}</h6>
                          <div className="col-lg-3 col-md-6 mt-2 pl-0">
                               <InputGroup>
                                   {/*   https://rsuitejs.com/components/input-number/*/}
                                  <InputGroup>
                                      <button type={"button"} onClick={this.handleMinus} className='btn btn-outline-default border-right-none'>-</button>
                                      <InputNumber className={'form-control border-color-default'}
                                                   value={cantidad}
                                                   onChange={this.handleChangeCantidad}
                                                   min={1}
                                                   max={100}
                                                   disabled={"disabled"}
                                      />
                                      <button type={"button"}  onClick={this.handlePlus} className='btn btn-outline-default border-left-none'>+</button>
                                  </InputGroup>
                              </InputGroup>
                          </div>
                        </>}
                    </>
            }
            </Translation>
        )
    }
}
export default ImporteCuponesDigitales;
