import React from 'react';
import Contacto from "./contacto";
import useGetHTMLFetch from "../../server/useGetHTMLFetch";

export default function SobreNosotros(props) {
    const content = useGetHTMLFetch('index.php/contenidos/sobrenosotros');


    return(
         <main id="wrapper" className="container pt-5 bg-white" role="main">
            <div dangerouslySetInnerHTML={{__html: content}} />
            <Contacto/>
        </main>
     );
}

