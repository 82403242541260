
import React from 'react';
import { Translation } from 'react-i18next';
import '../../../assets/css/form.css';


class Dimensiones extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            initdata:null, 
            paquetes: null,
        }    
        this._isMounted = false;
        this.idTipoPaqueteria = props.idTipoPaqueteria; 
        this.fetchApi = props.fetchApi.bind(this); //bind from props 
        this.handleToast = props.handleToast.bind(this); //bind from props 
        this.setValuesDimensiones = props.setValuesDimensiones.bind(this); //bind from props 
        this.getValuesDimensiones = props.getValuesDimensiones.bind(this); //bind from props
        this.getValuesOrigenDestino = props.getValuesOrigenDestino.bind(this); //bind from props
        this.moneda = props.moneda
    }
    componentDidMount(){
        const _self = this;
        this.fetchApi({
            endpoind:'index.php/paqueteria/dimensionesEnvioForm',
            body:{ id: this.idTipoPaqueteria}
          }, function(result){
                if (result && result.status ==='ok' && result.result){
                    _self.setState({isLoaded: false, initdata: result.result});
                }else{
                    _self.setState({isLoaded: true, error: result.message})
                }
                //obtenemos valores ya informados
                const paquetes = _self.getValuesDimensiones();
                _self.setState({ paquetes: paquetes });
                _self._isMounted = true;
            },
        )
    }
    componentWillUnmount() {
        this._isMounted = false;
    }//componentWillUnmount

    isValidated() {
        let isDataValid = this._validateData();
        if (!isDataValid){
            this.handleToast(`Todos los campos (*) son requeridos`,'bg-warning');
        }else{
            this.setValuesDimensiones(this.state.paquetes);
        }
        return isDataValid;
    }
    _validateData() {
        const paquetes = this.state.paquetes;
        //todos los campos son requeridos, solo validamos un paquete
        let valid = true;
        for (let x in paquetes[0]) {
            if ( x !== 'valor' && !paquetes[0][x]) valid = false; 
        }
        
        return valid;
    }
    /*------------------------------------*/
    onChange = (index,o)=>{
        const paquetes = this.state.paquetes;
        for (let x in o) {
            paquetes[index][x] = o[x];
        }
        this.setState({paquetes: paquetes});
    }
    addPaquete = ()=>{
        const paquetes = this.state.paquetes;
        //validamos paquetes[0] antes de agregar
        let valid = true;
        for (let x in paquetes[0]) {
            if (x !== 'valor' && !paquetes[0][x]) valid = false; 
        }
        if (valid){
            //const paquete = paquetes[0].map( () => '');
            const paquete = Object.entries(paquetes[0])
                .reduce((a, [key, { number }]) => {
                    a[key] = number;
                    return a;
                }, {});
            paquetes.push(paquete);
            this.setState({paquetes: paquetes});
        }else{
            this.handleToast(`Todos los campos son requeridos`,'bg-warning');
        }    
    }
    removePaquete = (index)=>{
        const paquetes = this.state.paquetes;
        paquetes.splice(index, 1); //eliminamos la linea
        this.setState({paquetes: paquetes});
    }

    calcularPaquete = (index)=>{
        //calculo ocurre en la api
        const _self = this;
        const paquetes = this.state.paquetes;
        let obj = paquetes[index];
        obj.id_tipo_paqueteria = this.idTipoPaqueteria;
        obj.origen_destino= this.getValuesOrigenDestino();
        this.fetchApi({
            endpoind:'index.php/paqueteria/dimensionesEnvioCalcular',
            body:obj
          }, function(result){
                if (result && result.status ==='ok' && result.result){
                    paquetes[index].valor = result.result;
                    _self.setState({paquetes: paquetes});
                }
            },
        )

    }    
    /*------------------------------------*/
    render() {
        const {error, isLoaded, initdata, paquetes} = this.state;
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
                <Translation ns={['paqueteria']}>{(t) =>
                <>
                    <div className="step_content mt-3">
                    <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                         {/*BANNER*/}
                         <div className={"d-flex align-items-end banner-header dimensiones" }>
                            <div className="title">{t('Dimensiones de tu envío')} <span>{t(initdata.nombre)}</span></div> 
                        </div>
                        {initdata && <div>
                            <div className="first_step_content_item_wrap d-block p-3">
                                {paquetes && <>
                                <div className='row'>
                                    <div className='col-lg-8 order-2 order-lg-1'>
                                         {/*dimensiones de cada caja */}
                                        {paquetes.map((p, index) => {
                                            return  <div key={index} className='row linea-dimenciones align-items-start mt-3'>
                                               
                                                <div className='col-lg-2 col-6 mb-3'>
                                                    <label className='label'>{t('Largo')} <span className="required"> (*)</span></label>
                                                    <input type="number" id={'largo'+index} name="largo[]" className="form-control bg-ccc"
                                                        onChange={e => this.onChange(index, { largo: e.target.value })}
                                                        value={p.largo}
                                                        required
                                                        autoFocus
                                                    />
                                                </div>
                                                <div className='col-lg-2 col-6 mb-3'>
                                                    <label className='label'>{t('Ancho')} <span className="required"> (*)</span></label>
                                                    <input type="number" id={'ancho'+index} name="ancho[]" className="form-control bg-ccc"
                                                        onChange={e => this.onChange(index, { ancho: e.target.value })}
                                                        value={p.ancho}
                                                        required
                                                    />
                                                </div>
                                                <div className='col-lg-2 col-6 mb-3'>
                                                    <label className='label'>{t('Alto')} <span className="required"> (*)</span></label>
                                                    <input type="number" id={'alto'+index} name="alto[]" className="form-control bg-ccc"
                                                        onChange={e => this.onChange(index, { alto: e.target.value })}
                                                        value={p.alto}
                                                        
                                                        required
                                                    />
                                                </div>
                                                <div className='col-lg-2 col-6 mb-3'>
                                                    <label className='label'>{t('Peso')} <span className="required"> (*)</span></label>
                                                    <input type="number" id={'peso'+index} name="peso[]" className="form-control bg-ccc"
                                                        onChange={e => this.onChange(index, { peso: e.target.value })}
                                                        value={p.peso}
                                                        required
                                                    />
                                                </div>
                                                {/*boton para agregar/eliminar paquetes */}
                                                <div className='col-lg-4 col-12 d-flex'>
                                                    {index === 0 && 
                                                        <button type='button' onClick={() => this.addPaquete()} className='btn-paqueteria btn btn-sm'> + </button>
                                                    }
                                                    {index > 0 && 
                                                        <button type='button' onClick={() => this.removePaquete(index)} className='btn-paqueteria btn btn-sm'> - </button>
                                                    }
                                                    {/*boton calcular */}
                                                    {p.alto && p.ancho && p.largo && p.peso &&
                                                        <button type='button' onClick={() => this.calcularPaquete(index)} className='btn-paqueteria btn btn-sm accent_btn'> {t('calcular')} </button>
                                                    }    
                                                </div>
                                            </div>    
                                        })}
                                    </div>
                                    <div className='col-lg-4 order-1 order-lg-2'>
                                        <div className='mt-3 p-3 border bg-light'>
                                            {initdata.precio_kilo_default &&
                                                <div className='row mb-2 font-weight-bold text-right small'>
                                                    <div className='col-8'>{t('Precio')} Kg: </div>
                                                    <div className='col-4 bg-highlighted'>{initdata.precio_kilo_default} {this.moneda}</div>
                                                </div>
                                            }
                                            {initdata.gastos_aduanales &&
                                                <div className='row mb-2 font-weight-bold text-right small'>
                                                    <div className='col-8'>{t('Gastos de aduana')}: </div>
                                                    <div className='col-4 bg-highlighted'>{initdata.gastos_aduanales} {this.moneda}</div>
                                                </div>
                                            }
                                            {paquetes.map((p, index) => {
                                                return  <div key={index} className='linea-dimenciones-result '>
                                                     { Number(p.valor) > 0 && 
                                                    <div className='row mt-2 mb-2 border-top border-bottom pt-1 pb-1 bg-white'>
                                                        <div className='col-6 small'>{'#'+(index+1)} Dim/Kg:  </div>
                                                        <div className='col-6 font-weight-bold text-right'>
                                                            {p.valor} {this.moneda}
                                                        </div>
                                                    </div> }
                                                   </div> 
                                             })}        
                                        </div>    
                                    </div>

                                </div>
                                   
                                </>}

                                <div className='mt-5 p-4 bg-light mb-5'>
                                    <div className='row'>
                                        <div className='col-12'><p className='text-muted' dangerouslySetInnerHTML={{__html: initdata.descripcion}} /></div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>}
                    </div>
                    </div>    
                </>
                }
                </Translation>
            );
        }    
  }
}

export default Dimensiones;
