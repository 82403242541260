import React, { useState } from 'react';
import withRouter from '../../withRouter';

import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';



function CambiarPassDesdeEnlace(props) {
    const baseURL = process.env.REACT_APP_API_URL;
    const miENV = process.env.REACT_APP_MIENV;
    const { t } = useTranslation(['login']);

    const {register, watch, handleSubmit, formState: { errors } } = useForm();
    const [KOmessage, setKOmessage] = useState('');
    const [OKmessage, setOKmessage] = useState('');

    const tokenAccess = props.params.token || '';
    const correo = props.params.correo || '';
    const handleInputFocus = event => {
            setKOmessage('');
    }
    const onSubmit = data => {
        data.token = tokenAccess;
        data.email = correo;
        fetch(baseURL+'login/cambiar-contrasena-desde-enlace.php', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((Response) => Response.json())
        .then((result) => {
            if (result.status === 'ok'){
                setOKmessage(result.message);
            }else{
                setKOmessage(result.message);
            }
            setTimeout(() => window.location.href = '/login', 3000);

        })
    }

  return (

    <main id="wrapper" className="pt-5" role="main">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-9 col-lg-7 col-xl-6">
                    <div className="card mb-4">
                        <div className="card-body">
                            <h5 className="card-title pt-4 text-center font-weight-bold">{t('login:newPass.titulo')}</h5>
                            <p className="text-danger text-center small">{t('login:newPass.subtitulo')}</p>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                  <input type="hidden" name='site_name' defaultValue={miENV} {...register('site_name',{ required: true})}  className="form-control"/>
                                  <input type="hidden" name='enviar_email' defaultValue={0} {...register('enviar_email',{ required: true})}  className="form-control"/>
                                  <div className="form-group mb-4">
                                        <div className="input-group mb-2 mt-4">
                                            <input  {...register('password1',{ required: true,minLength: 6 })}
                                            type="password" name='password1' onFocus={handleInputFocus}
                                            placeholder={t('form.Escribe tu nueva Contraseña')} aria-describedby="passHelp" className="form-control rounded"/>
                                        </div>
                                        {errors && errors.password1 && errors.password1.type==='required' && <small id="passHelp" className="form-text text-danger">{t('login:form.error.Contraseña requerida')}</small>}
                                        {errors && errors.password1 && errors.password1.type==='minLength' && <small id="passHelp" className="form-text text-danger">{t('login:form.error.Contraseña inválida')}</small>}
                                  </div>
                                  <div className="form-group mb-4">
                                        <div className="input-group mb-2">
                                            <input type="password" name='password2' onFocus={handleInputFocus} {...register('password2',{
                                                required: true,
                                                minLength: 6,
                                                validate: (value) => {
                                                  return value === watch('password1'); // value is from password2 and watch will return value from password1
                                                }
                                              })} placeholder={t('form.Confirma tu nueva Contraseña')} aria-describedby="passHelp" className="form-control rounded"/>
                                        </div>
                                        {OKmessage && !KOmessage && <div className="alert box-success pr-25 pl-25 text-jutifify" role="alert">{OKmessage}</div>}
                                        {KOmessage && !OKmessage && <div className="alert alert-danger text-jutifify" role="alert">{KOmessage}</div>}
                                        {errors && errors.password2 && errors.password2.type==='required' && <small id="passHelp" className="form-text text-danger">{t('login:form.error.Contraseña requerida')}</small>}
                                        {errors && errors.password2 && errors.password2.type==='minLength' && <small id="passHelp" className="form-text text-danger">{t('login:form.error.Contraseña inválida')}</small>}
                                        {errors && errors.password2 && errors.password2.type==='validate' && <small id="passHelp" className="form-text text-danger">{t('login:form.error.Contraseñas diferentes')}</small>}
                                  </div>
                                  <div className="text-center">
                                        <button type="submit" className="btn-color-intercom">{t('login:newPass.boton')}</button>
                                  </div>
                                  <p className="pt-4 text-center">
                                      <Link to={'/login'}>{t('login:iniciaSesion.enlace')}</Link>
                                  </p>
                              </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="py-5"></div>
    </main>

   );
}

export default withRouter(CambiarPassDesdeEnlace);
