import React, { Component } from 'react';
// import { Input } from '@mui/material/input';
import Select from "react-select";
import { Translation } from 'react-i18next';


import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
// import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import '../../assets/css/form.css';


class Calculadora extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            data : [],
            destinoSeleccionado:props.idDestinoTarifa ? { label: props.pais, value: props.idDestinoTarifa }: null,
            destinos : [],
            tarifa : props.tarifa || 0,
            tarifaId : null,
            moneda : props.moneda || 'EUR',
            valueRecibe : props.valueRecibe || '',
            valueEnvia : props.valueEnvia || '',
            buttonSubmit : props.buttonSubmit,
            buttonText : props.buttonText,
            tarifasCondicionadas : props.tarifasCondicionadas || false,
            valueEnviaMaximo : null,
            valueEnviaMinimo : null
        };
        this.tokenAccess = props.tokenAccess;
        this.setStateData = props.setStateData;
        this.submitForm = props.submitForm;
        this.getMonedaDestinoDefault = (props.getMonedaDestinoDefault)?props.getMonedaDestinoDefault.bind(this):null;
        this.handleDestinoChange = this.handleDestinoChange.bind(this);
        this.handleCantidadEnviarChange = this.handleCantidadEnviarChange.bind(this);
        //this.handleTarifaPromo = props.setNuevaTarifa;
    }



    componentWillReceiveProps(nextProps){
      //console.log("componentWillRecieveProps!",nextProps);
      if (nextProps.tarifa && Number(nextProps.tarifa) !== Number(this.state.tarifa)){
        this.cambioTarifaReCalcular(Number(nextProps.tarifa));
      }
    }

    /*-- AL INICIAR EL COMPONENTE --*/
    componentDidMount(){
        const apiURL = process.env.REACT_APP_API_URL;
        //REFACTO 12.12.2020 agregamos monedas origen
        const url = (this.state.tarifasCondicionadas) ? 'index.php/remesa/tarifasWebCondicionadas' : 'index.php/remesa/tarifasWebCalculadora';
        let body ='';
        if (this.state.tarifasCondicionadas){
           body = JSON.stringify({token:this.tokenAccess})
        }
        //const url = 'index.php/remesa/tarifasWebCalculadora';
        fetch(apiURL+url,{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: body
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    //console.dir(result.result);
                    this.setState({data : result.result}); //origenes, destinos, tarifas
                    this.setSelectDestinos();
                    this.setSelectMonedasOrigen();
                    this.handleTarifa();
                    if (this.state.tarifasCondicionadas){
                       this.handleTarifasCondicionadas();
                    }
                    this.setState({isLoaded : true});
                }else{
                    this.setState({
                        error: result.message
                    });
                    if (result.action==='login'){
                        window.location.href = '/Login';
                    }
                }
                this.setState({
                    isLoaded: false,
                });
            },
            // Handle error
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            },
        )
    }
    handleFocus = (event) => event.target.select();

    /*-- TARIFAS CONDICIONADAS (Remesas --> RemesaCantidadEnviar) --*/
    handleTarifasCondicionadas = () =>{
      //seteamos valores default
      if (this.state.tarifasCondicionadas){
        let destinoDefault = this.state.data.destinos[0];
        const destinoDefaultId = this.getMonedaDestinoDefault();
        if (destinoDefaultId){ //cuando viene por parámetro get
            this.state.data.destinos.map((d) => {
              if (d.id === destinoDefaultId){
                destinoDefault=d;
              }
            });
        }
        if (destinoDefault){
          this.setState({
            destinoSeleccionado:{ label: destinoDefault.pais, value: destinoDefault.id },
            pais: destinoDefault.pais,
            tarifa: this.state.tarifa,
            moneda: destinoDefault.moneda
          });
          this.setStateData(destinoDefault);
        }
      }
    }
    /*-- MONEDAS Y TARIFAS DESTINO --*/
    setSelectDestinos = () =>{
        let destinos = [];
        if (this.state.data.destinos && this.state.data.destinos.length > 0){
            this.state.data.destinos.map((res) => {
                destinos.push({ value: res.id, label: res.pais });
                if (this.state.destinoSeleccionado && Number(res.id) === Number(this.state.destinoSeleccionado.value)){
                  this.setState({
                    valueEnviaMaximo : res.maximo,
                    valueEnviaMinimo : res.minimo,
                    moneda : res.moneda
                  });
                }
            });
            //si no hay destino seleccionado, se toma el primero
            if (!this.state.destinoSeleccionado){
              this.setState({
                destinoSeleccionado: { label: this.state.data.destinos[0].pais, value: this.state.data.destinos[0].id },
                valueEnviaMaximo : this.state.data.destinos[0].maximo,
                valueEnviaMinimo : this.state.data.destinos[0].minimo,
                moneda : this.state.data.destinos[0].moneda
              });

            }
            this.setState({
                destinos : destinos
            });
        }
    }

    /*-- MONEDAS ORIGEN --*/
    setSelectMonedasOrigen = () =>{
        let origenes = [];
        if (this.state.data.origenes && this.state.data.origenes.length > 0){
            this.state.data.origenes.map((res) => {
                origenes.push({ label: res.moneda , value: res.id });
            });
            //si no hay destino seleccionado, se toma el primero
            this.setState({
              origenSeleccionado: { label: this.state.data.origenes[0].moneda, value: this.state.data.origenes[0].id },
            });
            this.setState({origenes : origenes});
        }
    }


    /*-- TARIFAS --*/
    handleTarifa = () =>{
      const o = Number(this.state.origenSeleccionado.value);
      const d = Number(this.state.destinoSeleccionado.value);
        // console.dir(d);
        // console.dir(this.state);
      let filtertarifa = this.state.data.tarifas.filter(t => Number(t.id_moneda_destino) === d && Number(t.id_moneda_origen) === o);
      this.setState({tarifa : filtertarifa[0].tarifa});
      this.setState({tarifaId : filtertarifa[0].id});

      this.setStateData({
        tarifa:this.state.tarifa,
        moneda:this.state.origenSeleccionado.label
      }); //enviamos al componente padre RemesaCalculadora

      //si cambia la tarifa recalculamos cantidad a recibir
      if (this.state.valueEnvia){
        let recibe = (this.state.valueEnvia * Number(this.state.tarifa));
        let data = {
            valueRecibe : recibe.toFixed(2),
            maximo : this.state.valueEnviaMaximo,
            minimo : this.state.valueEnviaMinimo
        };
        this.setState(data);
        this.setStateData(data);//enviamos al componente padre RemesaCalculadora
      }
      this.setStateRemesaData();
    }

    /*-- AL CAMBIAR ORIGEN --*/
    handleMonedaOrigenChange = (event) =>{
      const self = this;
      const o_id = Number(event.target.dataset.id);
      let filterorigen = this.state.data.origenes.filter(o => Number(o.id) === o_id);
      this.setState({
        origenSeleccionado: { label: filterorigen[0].moneda, value: filterorigen[0].id }
      });
      setTimeout(function(){ self.handleTarifa();}, 0);

    }
    /*-- AL CAMBIAR DESTINO --*/
    handleDestinoChange = event => {
      if (this.state.data.destinos.length ===1) return false;
      const self = this;
      const d_id = event.target.value;
      let filterdestino = this.state.data.destinos.filter(d => Number(d.id) === d_id);
      this.setState({
        destinoSeleccionado: { label: filterdestino[0].moneda, value: filterdestino[0].id },
      });
      setTimeout(function(){ self.handleTarifa();}, 0);
    }
    /*-- AL CAMBIAR CANTIDADES --*/
    handleCantidadEnviarChange = event => {
        const value = event.target.value;
       // console.log('handleCantidadEnviarChange',value);
        if (value && this.state.tarifa) {
            this.cambiaCantidadEnviar(Number(value));
        }else{
          this.setState({valueEnvia:''});
        }
        // const y = event.target.getBoundingClientRect().top + window.scrollY;
        // window.scroll({
        //   top: y,
        //   behavior: 'smooth'
        // });
    }
    cambiaCantidadEnviar = (cantidadEnviar) =>{
      let recibe = (cantidadEnviar * Number(this.state.tarifa));
      let data = {
          valueRecibe: recibe.toFixed(2),
          valueEnvia : cantidadEnviar,
          maximo : this.state.valueEnviaMaximo,
          minimo : this.state.valueEnviaMinimo
      };
      this.setState(data);
      this.setStateData(data);//enviamos al componente padre
    }
    handleCantidadRecibirChange = event => {
        const value = event.target.value;
        if (this.state.tarifa && value !== '' && Number(value)>0) {
            this.cambiaCantidadRecibir(Number(event.target.value));
        }else{
          this.setState({valueRecibe:''});
        }
    }
    cambiaCantidadRecibir = (cantidadRecibe) =>{
      let envia = (cantidadRecibe / Number(this.state.tarifa));
      let data = {
          valueEnvia: envia.toFixed(2),
          valueRecibe : cantidadRecibe,
          maximo : this.state.valueEnviaMaximo,
          minimo : this.state.valueEnviaMinimo
      };
      this.setState(data);
      this.setStateData(data);
    }

    setStateRemesaData=()=>{
      //enviamos a RemesaCantidadEnviar.js (ejecutando la remesa)
      const d_id = Number(this.state.destinoSeleccionado.value);
      const o_id = Number(this.state.origenSeleccionado.value);
      let filterdestino = this.state.data.destinos.filter(d => Number(d.id) === d_id);
      let filterorigen = this.state.data.origenes.filter(d => Number(d.id) === o_id);
      if (filterdestino && filterdestino.length>0 && filterorigen && filterorigen.length>0){
        this.setStateData({
            monedaDestinoData:{
                id:filterdestino[0].id,
                pais:filterdestino[0].pais,
                id_tarifa:this.state.tarifaId,
                tarifa:this.state.tarifa,
                moneda:filterdestino[0].moneda,
                condiciones:filterdestino[0].condiciones,
                comision:filterdestino[0].comision
            },
            monedaOrigenData:{
                id:filterorigen[0].id,
                moneda:filterorigen[0].moneda
            }
        });
      }
    }

    cambioTarifaReCalcular = (nuevaTarifa) => {
        const tarifa = (nuevaTarifa) || this.state.tarifa
        if (this.state.valueEnvia){
          let recibe = (this.state.valueEnvia * Number(tarifa));
          this.setState({valueRecibe : recibe.toFixed(2)});
        }
        this.setState({tarifa :tarifa});
    }

    /*--- RENDER ---*/
    render() {
        if(this.state.error || this.state.isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
<Translation ns={['remesas']}>
{(t) =><form className = 'form-calculadora' onSubmit={this.submitForm}>
              <div className="send_to_wrap">
                      <label>{t('Enviar a')}</label>
                      <select id="send_city"
                          className="reactSelect"
                          name="destinos"
                          placeholder="Seleccione un país"
                          onChange={this.handleDestinoChange}
                          value={this.state.destinoSeleccionado}>
                          {this.state.destinos.map((d, index) => {
                             return  <option key={index} value={d.value}>{d.label}</option>
                          })}
                      </select>

                      <div className="send_quantity_label">
                        {t('Cantidad a enviar')}
                      
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <DropdownButton
                                  as={InputGroup.Prepend}
                                  variant="outline-secondary"
                                  title={this.state.origenSeleccionado.label}
                                  id="input-group-dropdown-1">
                                  {this.state.origenes.map((item) => {
                                      return <Dropdown.Item onClick={this.handleMonedaOrigenChange} key={item.value} data-id={item.value}>{item.label}</Dropdown.Item>
                                  })}
                              </DropdownButton>
                            </div>
                            <input type="number" id="amount_to_send"  className="form-control mt-0"
                              placeholder={t('Escriba la cantidad a enviar')}
                              onChange={this.handleCantidadEnviarChange}
                              value={this.state.valueEnvia}/>
                          </div>
                      </div>
                      <label className="amount_to_receive_label">
                        {t('El beneficiario recibe')}
                        <input type="number" id="amount_to_receive" className="form-control"
                          placeholder={t('Escriba la cantidad a recibir')}
                          onChange={this.handleCantidadRecibirChange}
                          value={this.state.valueRecibe}/>
                        <span>{this.state.moneda}</span>
                      </label>
                    </div>
                        {this.state.buttonSubmit &&
                            <button type="submit">{this.state.buttonText}</button>
                        }
                  </form>
            }
</Translation>
            );
        }

    }

}
export default Calculadora;
