import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Translation } from 'react-i18next';

class AlertDialog extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          isOpen: props.isOpen || false,
          title: props.title || null,
          message: props.message
      };
      this.handleClickOpen = this.handleClickOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleResponse = props.handleResponse;
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  handleClickOpen = () => {
    this.setState({ isOpen: true });
  }

  handleClose = (r) => {
    this.setState({ isOpen: false });
    this.handleResponse(r);
  }
  /*Whenever a class component is updated*/
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({isOpen: this.props.isOpen});
    }
    if (prevProps.message !== this.props.message) {
      this.setState({message: this.props.message});
    }
  }
  render() {
    const {isOpen, title, message} = this.state;
    return (
      <Translation ns={['translation']}>
      {
      (t) =>
          <Dialog
            open={isOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {this.handleClose(false);}} color="primary">
                No
              </Button>
              <Button onClick={() => {this.handleClose(true);}} color="primary" autoFocus>
                {t("Si")}
              </Button>
            </DialogActions>
          </Dialog>
        }
        </Translation>
    );
  }

}
export default AlertDialog;
