import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import variables from "../../variables/env.js";

function ContactForm() {
    const { t, i18n } =  useTranslation(['contactoform']);
    const {register, handleSubmit, formState: { errors }  } = useForm();
    const [KOmessage, setKOmessage] = useState('');
    const [OKmessage, setOKmessage] = useState('');
    const [sending, setSending] = useState(false);

    const handleInputFocus = event => {
            setKOmessage('');
    }
    const onError = (errors, e) => {console.log('errors', errors)};
    const onSubmit = () => {
        setSending(true);
        fetch(variables.apiurl+'contacto/formulario.php', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nombre: document.getElementById('nombre').value,
                email: document.getElementById('email').value,
                telefono: document.getElementById('telefono').value,
                comentarios: document.getElementById('comentarios').value,
            })
        }).then((Response) => Response.json())
            .then((result) => {
                setSending(false);
                if (result.status === 'ok'){
                    setOKmessage(result.message);
                }
            })
    }

    return (
      <div>
        {OKmessage && !KOmessage && <div className="alert alert-success text-uppercase" role="alert"><i className="fa fa-check" aria-hidden="true"></i> {OKmessage}</div>}
        {KOmessage && <div className="alert alert-danger text-uppercase" role="alert"><i className="fa fa-times" aria-hidden="true"></i> {KOmessage}</div>}
        {!OKmessage  && <>
         
          <div className="contact-form">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
                {/* register your input into the hook by invoking the "register" function */}
                <label>{t('form.label.Nombre')}  <span> ({t('form.label.Obligatorio')})</span><br/>
                      {errors && errors.nombre && errors.nombre.type==='required' && <span id="nombreHelp">{t('form.error.Nombre requerido')}</span>}
                    <input {...register('nombre',{ required: true})}
                      type="text" name='nombre' id='nombre' onFocus={handleInputFocus} placeholder={t('form.placeholder.Nombre')} aria-describedby="nombreHelp"/>
                </label>
                <label>
                    {t('form.label.Correo')} <span>({t('form.label.Obligatorio')})</span><br/>
                    {errors && errors.email && errors.email.type==='required' && <span id="emailHelp">{t('form.error.Email requerido')}</span>}
                    {errors && errors.email && errors.email.type==='pattern' && <span id="emailHelp">{t('form.error.Email inválido')}</span>}
                    <input {...register('email',{ required: true, pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })}
                        type="text" name='email' id='email' onFocus={handleInputFocus} placeholder={t('form.placeholder.Correo')} aria-describedby="emailHelp"/>
                </label>
                <label>
                    {t('form.label.Teléfono')}<br/>
                    <input type="text" name='telefono' id='telefono'  onFocus={handleInputFocus} placeholder={t('form.placeholder.Teléfono')}/>
                </label>
                <label>
                  {t('form.label.Comentario')}<span>({t('form.label.Obligatorio')})</span><br/>
                  {errors && errors.comentarios && errors.comentarios.type==='required' && <span id="comentariosHelp">{t('form.error.Comentario requerido')}</span>}
                  <textarea {...register('comentarios',{required: true})}
                    type="text" name='comentarios' id='comentarios' onFocus={handleInputFocus} aria-describedby="comentariosHelp"/>
                </label>
                {!sending && 
                  <button type="submit">{t('form.boton')}</button>
                }  
              </form>
          </div>
        </>}
      </div>
    );
}

export default ContactForm;
