import React, { Component } from 'react';
import PrivateArea from '../cliente/PrivateArea';
import DataTable from 'react-data-table-component';
import { Translation,Trans } from 'react-i18next';

import variables from "../../variables/env.js";

class SaldoMovimientos extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            columns :  [],
            data:[], alldata:[], //data sin filtrar
            selectOptions:[],
            userSaldo : 0,
        };
        this.tokenAccess = variables.auth('token');
    }

    obtenerColumnas=(columns)=>{
        const cols = columns.map((c) => {
            let s = c.selector;
            if (s === 'n_factura'){
                c.ignoreRowClick = true;
                c.cell = row => (
                      <a
                        href={variables.appurl+'factura-remesa.php?token='+this.tokenAccess+'&iid=' + row.id}
                        target="_blank"
                        rel="noopener noreferrer">
                        {row.n_factura}
                      </a>
                );
            }
            c.selector = row => (row[s]);
            c.name = this.getStringTranslation(c.name);
            return c;
        });
        this.setState({columns : cols});
    }
    getStringTranslation(jsonElement){
      return (<Trans defaults={jsonElement} />)
    }//getStringTranslation
    /** Total Saldo **/
    saldoDisponibleIntercom = () =>{
        fetch(variables.apiurl+'index.php/remesa/consultaSaldo',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        userSaldo : result.result.replace(".", ",")
                    });
                }
            }
        );
    }

    componentDidMount(){
        this.saldoDisponibleIntercom();
        /**  Obtener historico de saldo y movimientos **/
        fetch(variables.apiurl +'index.php/cliente/obtenerMovimientosSaldo',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    this.obtenerColumnas(result.result.columns);
                    this.setState({
                        data : result.result.data,
                        alldata : result.result.data
                    });
                    this.setState({columns : result.result.columns});
                }else{
                    this.setState({
                        isLoaded: true,
                        error: result.message
                    });
                    if (result.action==='login'){
                          window.location.href = '/Login';
                    }
                }
            },
            // Handle error
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            },
        )
    }

    render() {
        const {error, isLoaded, columns, data, userSaldo} = this.state;
        if(!isLoaded) {
            return <div className="loading"><div className="loading">Loading</div></div>
        }else{
            return(
              <Translation ns={['translation']}>
              {(t) =>
                  <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper saldoMovimientos" tabClass="saldoMovimientos-tab">
                      <div className="row ">
                          <div className="col-lg-6 col-xs-12 mb-4">
                              <div className="card rounded-top">
                                  <div className="card-body">
                                      <h4 className="bold">{t('Tu saldo')}:  <span className="color-green">{userSaldo ? userSaldo : '0,00 EURi'}</span></h4>
                                      <a className="transparent_btn rounded w-100" href="/canjear-cupon" target="_top" rel="follow" style={{fontSize: '.88em'}}>{t('Canjear un cheque regalo')}</a>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12">
                              {error && <h2>{error}</h2>}
                              {columns && <div className="mb-4">
                                  <h3 className="pb-2"> {t('saldo movimientos')}</h3>
                                  <DataTable
                                      columns={columns}
                                      data={data}
                                      pagination={true}
                                  />
                              </div>}
                          </div>
                      </div>
                  </PrivateArea>
              }
          </Translation>
        );
        }
    }
}
export default SaldoMovimientos;
