import React from "react";
import CookieConsent from 'react-cookie-consent-notification';
import { Translation } from 'react-i18next';

class CookieConsentNotification extends React.Component {
  constructor(props) {
    super(props);
  }

  checkStatus = (status) => {
    if(status) {
      // To set a cookies
    }
  }

  render() {
    return (
<Translation ns={['cookieconsent']}>
{
  (t) =>
      <div className="cookie-consent-notification">
        <CookieConsent
          background={'#000'}
          bottomPosition={true}
          buttonText={t('I agree')}
          buttonBackground={'#fff'}
          buttonColor={'#000'}
          buttonFontSize={16}
          color={'#fff'}
          consentFunction={this.checkStatus}
          padding={20}
        >
          {t('texto_parte_1')} <a href={'/politicasdeprivacidad'} style={{ color: '#fff' }}>{t('Cookie Policy')}</a>.
           {t('texto_parte_2')}.
        </CookieConsent>
      </div>
}
</Translation>
      );
  }
}
export default CookieConsentNotification;
