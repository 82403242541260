import React from "react";

class FormularioDinamico extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formValues: [{ cantidad: "", alto : "", largo : "", vmEstimado : "", pesoAprox : "" }]
        };
    }

    handleChange(i, e) {
        let formValues = this.state.formValues;
        formValues[i][e.target.name] = e.target.value;
        this.setState({ formValues });
    }

    addFormFields() {
        this.setState(({
            formValues: [...this.state.formValues, { cantidad: "", alto : "", largo : "", vmEstimado : "", pesoAprox : ""}]
        }))
    }

    removeFormFields(i) {
        let formValues = this.state.formValues;
        formValues.splice(i, 1);
        this.setState({ formValues });
    }

    render() {

        return (<div className="form-row" id="box-row">
                {this.state.formValues.map((element, index) => (
                    <div className="form-row w-100" key={index}>
                        <div className="col-xs-12 col-md-2 mb-3">
                            <label className='invisible'>option</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <button type="button" className="btn-sm width80 mt-6 height-30 p-0 ml-2 mr-2 border-radius" onClick={() => this.addFormFields()}><i  className="fa fa-plus" aria-hidden="true"></i></button>
                                    {index ? <button type="button"  className="btn-sm width80 mt-6 height-30 p-0 border-radius button remove" onClick={() => this.removeFormFields(index)}><i className="fa fa-times" aria-hidden="true"></i></button>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-2 mb-3">
                            <label htmlFor="">Cantidad</label>
                            <input type="text" className="form-control-sm mr-2" id={index}  defaultValue="1" disabled/>
                        </div>

                        <div className="col-md-2 mb-3">
                            <label htmlFor="">Alto *</label>
                            <input type="text" className="form-control-sm" id="inputAlto-0" required/>
                        </div>

                        <div className="col-md-2 mb-3">
                            <label htmlFor="">Largo *</label>
                            <input type="text" className="form-control-sm" id="inputLargo-0" required/>
                        </div>

                        <div className="col-md-2 mb-3">
                            <label htmlFor="">Vm³ Estimado *</label>
                            <input type="text" className="form-control-sm" id="inputVolumen-0" required/>
                        </div>

                        <div className="col-md-2 mb-3">
                            <label htmlFor="">Peso aprox *</label>
                            <input type="text" className="form-control-sm" id="inputPeso-0" required/>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
export default FormularioDinamico;