
import React, { Component }  from 'react';
import {NavLink} from 'react-router-dom';
import { Translation,Trans } from 'react-i18next';
import variables from "../../variables/env.js";


class EjecutarPago extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe : null,
      error : null,
      isLoaded : null,
      mensajeOK :null,
      mensajeKO :null,
      tipoPago:props.tipoPago || 'cesta',

    };
    this.getDataFinal = props.getDataFinal.bind(this);
    this.tokenAccess = props.tokenAccess;

  }

  componentDidMount() {
    switch(this.state.tipoPago) {
      case 'remesa':
        this.iniciarPagoRemesa();
        break;
      case 'paqueteria':
        this.iniciarPagoPaqueteria();
        break;
      default:
        this.iniciarPagoCesta();
    }
  }
  iniciarPagoRemesa = () =>{
      const data = this.getDataFinal();
      if (data && data.transferencia && data.transferencia.metodo_pago && data.transferencia.metodo_pago.metodo){
          this.iniciarPago(data.transferencia.metodo_pago.metodo,data);
      }else{
        //  window.location.reload();
      }
  }
  iniciarPagoPaqueteria = () =>{
    const data = this.getDataFinal();
    //console.log('iniciarPagoPaqueteria', data);
    if (data && data.metodo_pago && data.metodo_pago.metodo){
        this.iniciarPago(data.metodo_pago.metodo,data);
    }else{
      //  window.location.reload();
    }
}
  iniciarPagoCesta = () =>{
      const data = this.getDataFinal();
      if (data.metodosPago && data.metodosPago.metodo){
         this.iniciarPago(data.metodosPago.metodo,data);
      }else{
          window.location.reload();
      }
  }
  iniciarPago = (metodo, data) =>{
       switch(metodo) {
         case 'saldo':
           this.pagarConSaldo(data);
           break;
         case 'stripe':
           this.pagarConStripe(data);
           break;
         default:
           this.pagar(data);
           break;
       }
  }

  /* pagar genérico
  this.state.tipoPago => cesta, remesa o paqueteria */
  pagar(data){
    this.setState({
      mensajeOK : 'En seguida será dirigido a la plataforma de pago seguro, por favor no cierre esta ventana ni salga de la aplicación, hasta completar el proceso.',
      isLoaded:true
    });
      fetch(variables.apiurl +'index.php/'+this.state.tipoPago+'/pagar',{
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token:this.tokenAccess,
            data:data,
            nameApp: variables.appname,
            env :variables.mienv,
            lang:variables.lang,
          })
      })
      .then( response => response.json())
      .then(
          // handle the result
          (result) => {
            if (result.status ==='ok' && result.result.payment_url){
              window.location.href = variables.appurl +'dashboard';
              const windowFeatures = "left=100,top=100,width=600,height=600";
              const handle = window.open(result.result.payment_url,
                '_blank',
                windowFeatures
              );
              if (!handle) {
                window.location.href = result.result.payment_url;
                history.pushState(null, null, variables.appurl + 'dashboard');
                window.addEventListener('popstate', function(event) {
                    window.location.assign(variables.appurl + 'dashboard');
                });
              }
             // window.open(result.result.payment_url, '_blank');
            //  setTimeout(function(){ window.location.href = '/dashboard'; }, 5000);
            }else if (result.status ==='ko' && result.result.payment_url){
              window.location.href = result.result.payment_url;
            }else{
                this.setState({
                  isLoaded: false,
                  error: result.message,
                  mensajeKO:result.message,
                  mensajeOK:'',
                });
                if (result.action==='login'){
                    window.location.href = '/Login';
                }
                
            }
        },
        // Handle error
        (error) => {
            this.setState({
                isLoaded: true
            })
        },
    );
  }

  pagarConStripe(data){
    this.setState({
      mensajeOK : 'En seguida será dirigido a la plataforma de pago seguro, por favor no cierre esta ventana ni salga de la aplicación',
      stripe : window.Stripe(variables.tpvkey),
      isLoaded:true
    });
      fetch(variables.apiurl +'index.php/'+this.state.tipoPago+'/crearCheckoutSessionStripe',{
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token:this.tokenAccess,
            data:data,
            nameApp: variables.appname,
            env :variables.mienv,
            lang:variables.lang,
          })
      })
      .then( response => response.json())
      .then(
          // handle the result
          (result) => {
              if (result.status ==='ok'){
               const checkoutSesion = result.result.checkout_sesion;
                const {error} = this.state.stripe.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: checkoutSesion.id
                });
            }else{
                this.setState({
                  isLoaded: false,
                  error: result.message,
                  mensajeKO:result.message,
                  mensajeOK:'',
                });
                if (result.action==='login'){
                    window.location.href = '/Login';
                }
            }
        },
        // Handle error
        (error) => {
            this.setState({
                isLoaded: true
            })
        },
    );
  }

  pagarConSaldo(data){
      this.setState({
          isLoaded:true
      });
    fetch(variables.apiurl +'index.php/'+this.state.tipoPago+'/pagarConSaldo',{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token:this.tokenAccess,
          data:data,
          nameApp: variables.appname,
          env :variables.mienv,
          lang:variables.lang,
        })
    })
    .then( response => response.json())
    .then(
        // handle the result
        (result) => {
            if (result.status ==='ok'){
              if (result.result.payment_url){
                window.location.href = result.result.payment_url;
              }else{
                this.setState({mensajeOK : result.message, isLoaded: false});
                variables.setCountCesta(0);
              }  
            }else{
                this.setState({
                  isLoaded: false,
                  error: result.message,
                  mensajeKO:result.message,
                  mensajeOK:'',
                });
                if (result.action==='login'){
                    window.location.href = '/Login';
                }
            }
        },
        // Handle error
        (error) => {
            this.setState({
                isLoaded: true,
                error
            })
        },
    );
  }

  render() {
    const {isLoaded,mensajeKO, mensajeOK, tipoPago} = this.state;
    return (
  <Translation ns={['pago']}>
{
  (t) =>
      <div className="profile_wrap col-12">

          <div className="result_header">
              <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.appname} /></a>  </div>
          </div>
          <div className="result_body">
              <p className="lead font-weight-bold">
                  {mensajeKO && <span><i className="fa fa-times text-danger font-weight-600" aria-hidden="true"></i> {t(this.state.mensajeKO)} </span>}
                  {mensajeOK && <span><i className="fa fa-check text-success font-weight-600" aria-hidden="true"></i> {t(this.state.mensajeOK)} </span>}
              </p>
              {isLoaded && <div className="loading EjecutarPago">Loading</div>}
              <div className="orange_divider"></div>
          </div>

          <div className="result_footer ">
              <div className="polygon_item"></div>
          </div>

  </div>
}

</Translation>
            );}
}
export default EjecutarPago;
