import React from 'react';
import { Translation } from 'react-i18next';

import '../../../assets/css/form.css';
class DestinoOrigen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            initdata:[], 
            camposRequeridos: [],
            pais_origen: '', ciudad_origen: '', 
            pais_destino: '', estado_destino:'', ciudad_destino: '', 
        }
        this._isMounted = false;

        //stepzilla
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.isValidated = this.isValidated.bind(this);
        
        //props functions
        this.fetchApi = props.fetchApi.bind(this); //bind from props 
        this.handleToast = props.handleToast.bind(this); //bind from props 
        this.setValuesOrigenDestino = props.setValuesOrigenDestino.bind(this); //bind from props 
        this.getValuesOrigenDestino = props.getValuesOrigenDestino.bind(this); //bind from props 
        this.idTipoPaqueteria = props.idTipoPaqueteria;
    }
    componentDidMount(){
        const _self = this;
        //pedimos datos de formulario origen, destino *paises
        this.fetchApi({
            endpoind:'index.php/paqueteria/origenDestinoForm',
          }, function(result){
                if (result && result.status ==='ok' && result.result){
                    let initdata = {origen:[], destino:[] }; 
                    //destino, origen
                    result.result.origenpais.label.forEach(function(a, i) {
                        initdata.origen.push({label:a, value: result.result.origenpais.value[i]})
                    }, this);
                    result.result.destinopais.label.forEach(function(a, i) {
                        initdata.destino.push({label:a, value: result.result.destinopais.value[i]})
                    }, this);
                  
                    
                    //pais destino default
                     if (result.result.destinopais.value.length === 1){
                        _self.setState({pais_destino: result.result.destinopais.value[0]});
                    }
                    //estado destino
                    if (result.result.destinoestado && result.result.destinoestado.label){
                        initdata.estadodestino = [];
                        result.result.destinoestado.label.forEach(function(a, i) {
                            initdata.estadodestino.push({label:a, value: result.result.destinoestado.value[i]})
                        }, this);
                    }
                    _self.setState({isLoaded: false, initdata: initdata, camposRequeridos: result.result.campos, pais_origen: result.result.origenpais.value[0]});
                }else{
                    _self.setState({isLoaded: true, error: result.message})
                }

                 //obtenemos valores ya informados
                 const values = _self.getValuesOrigenDestino();
                 if (values && result.result.campos){
                    result.result.campos.forEach(function(a, i) {
                        _self.setState({ [a]: values[a] });
                     }, this);
                 }
                 _self._isMounted = true;
            }
        );
    }
    componentWillUnmount() {
        this._isMounted = false;
    }//componentWillUnmount

    isValidated() {
        let isDataValid = this._validateData();
        if (!isDataValid){
            this.handleToast(`Todos los campos (*) son requeridos`,'bg-warning');
        }else{
            let dataFinal = {};
            this.state.camposRequeridos.forEach(function(a, i) {
                dataFinal[a] =  this.state[a]; 
            }, this);
            this.setValuesOrigenDestino(dataFinal);
        }
        return isDataValid;
    }
    _validateData() {
        //todos los campos son requeridos
        let valid = true;
        this.state.camposRequeridos.forEach(function(a, i) {
            if (!this.state[a]) valid = false; 
        }, this);
            
        return valid;
    }
   
    render() {
        const {error, isLoaded, initdata} = this.state;
        const classNameTipo = (this.idTipoPaqueteria == 1) ? 'aereo' : 'maritimo';
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
       
            return(
                <Translation ns={['paqueteria']}>{(t) =>
                <>

               
                    <div className="step_content mt-3">
                    <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                        {/*BANNER*/}
                        <div className={"d-flex align-items-end banner-header " + classNameTipo }>
                            <div className="title">{t('Selecciona Origen y Destino')} </div> 
                        </div>
                        <div className="first_step_content_item_wrap d-block p-3">

                        {initdata && initdata.origen && initdata.destino && <div className='p-4 bg-light'>
                            <div className='row'>
                                {/*origen */}
                                <div className='col-12'><h6>{t('Origen')}</h6></div>
                                <div className='col-lg-6 mb-3'>
                                    <div className='w-input-form'>
                                        <div className='icon pais'/>
                                        <div className='input-form'>
                                            <label className='text-left label'>{t('País')} <span className="required"> (*)</span></label>
                                            <select id="pais_origen"
                                                className="reactSelect"
                                                name="pais_origen"
                                                placeholder={t("Seleccione un país")}
                                                onChange={e => this.setState({ pais_origen: e.target.value })}
                                                value={this.state.pais_origen}
                                                required>
                                                {initdata.origen.map((d, index) => {
                                                return  <option key={index} value={d.value}>{t(d.label)}</option>
                                                })}
                                            </select>
                                        </div>      
                                    </div>    
                                </div>
                                <div className='col-lg-6 mb-3'>
                                     <div className='w-input-form '>
                                        <div className='icon ciudad'/>
                                        <div className='input-form'>
                                            <label className='label'>{t('Ciudad')} <span className="required"> (*)</span></label>
                                            <input type="text" id="ciudad_origen" name="ciudad_origen" className="form-control"
                                                placeholder={t('Ciudad')}
                                                onChange={e => this.setState({ ciudad_origen: e.target.value })}
                                                value={this.state.ciudad_origen}
                                            />
                                         </div>      
                                    </div> 
                                </div>
                            </div>
                            <div className='row'> 
                                {/*destino */}
                                <div className='col-12 mt-5'><h6>{t('Destino')}</h6></div>
                                <div className='col-lg-6 mb-3'>
                                    <div className='w-input-form'>
                                        <div className='icon pais'/>
                                        <div className='input-form'>
                                            <label className='text-left label'>{t('País')} <span className="required"> (*)</span></label>
                                            <select id="pais_destino"
                                                className="reactSelect"
                                                name="pais_destino"
                                                placeholder={t("Seleccione un país")}
                                                onChange={e => this.setState({ pais_destino: e.target.value })}
                                                value={this.state.pais_destino}>
                                                {initdata.destino.map((d, index) => {
                                                return  <option key={index} value={d.value}>{d.label}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>        
                                </div>
                                <div className='col-lg-6 mb-3'>
                                    <div className='w-input-form '>
                                        <div className='icon ciudad'/>
                                        <div className='input-form'>
                                            <label className='label'>{t('Estado')} <span className="required"> (*)</span></label>
                                            {!initdata.estadodestino && 
                                            <input type="text" id="estado_destino" name="estado_destino" className="form-control"
                                                placeholder={t('Estado')}
                                                onChange={e => this.setState({ estado_destino: e.target.value })}
                                                value={this.state.estado_destino}
                                            />
                                            }
                                            {initdata.estadodestino && 
                                            <select id="estado_destino"
                                                className="reactSelect"
                                                name="estado_destino"
                                                placeholder={t("Seleccione un estado")}
                                                onChange={e => this.setState({ estado_destino: e.target.value })}
                                                value={this.state.estado_destino}>
                                                {initdata.estadodestino.map((d, index) => {
                                                return  <option key={index} value={d.value}>{t(d.label)}</option>
                                                })}
                                            </select>
                                            }
                                        </div>
                                    </div>         
                                </div>
                                <div className='col-lg-6 mb-3'>
                                    <div className='w-input-form '>
                                        <div className='icon ciudad'/>
                                        <div className='input-form'>
                                            <label className='label'>{t('Ciudad')} <span className="required"> (*)</span></label>
                                            {!initdata.ciudaddestino && 
                                            <input type="text" id="ciudad_destino" name="ciudad_destino" className="form-control"
                                                placeholder={t('Ciudad')}
                                                onChange={e => this.setState({ ciudad_destino: e.target.value })}
                                                value={this.state.ciudad_destino}
                                            />
                                            }
                                            {initdata.ciudaddestino && 
                                            <select id="ciudad_destino"
                                                className="reactSelect"
                                                name="ciudad_destino"
                                                placeholder={t("Seleccione una ciudad")}
                                                onChange={e => this.setState({ ciudad_destino: e.target.value })}
                                                value={this.state.ciudad_destino}>
                                                {initdata.ciudaddestino.map((d, index) => {
                                                return  <option key={index} value={d.value}>{t(d.label)}</option>
                                                })}
                                            </select>
                                            }
                                        </div>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    
                        }
                        </div>
                    </div>
                    </div>    
                </>
                }
                </Translation>
            );
        }    
  }
}

export default DestinoOrigen;
