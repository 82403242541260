import React, { Component } from 'react';
import Select from 'react-select';
import {MdPhone,MdEmail,MdCheck,MdInfoOutline} from 'react-icons/md';
import {FaPlus,FaAddressCard} from 'react-icons/fa';

import { Translation } from 'react-i18next';

import BeneficiarioNuevaCuenta from "../beneficiarios/BeneficiarioNuevaCuenta";
import BeneficiarioFormCuenta from '../beneficiarios/BeneficiarioFormCuenta';
import BeneficiarioFormDataPopup from '../beneficiarios/BeneficiarioFormDataPopup';
import BeneficiarioFormCuentaPopup from '../beneficiarios/BeneficiarioFormCuentaPopup';

const publicURL = process.env.REACT_APP_URL;
const sitename = process.env.REACT_APP_NAME;
const mienv = process.env.REACT_APP_MIENV;

class RemesaBeneficiario extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            data:[],dataCondiciones:[],camposExtraCuenta:[],validCondiciones : true,
            selectOptions:[], selectOptionsCuentas: [],
            beneficiarioSeleccionado:null,
            beneficiarioDefaultValue:(props.beneficiarioSeleccionadoLabel)? { label: props.beneficiarioSeleccionadoLabel, value: props.beneficiarioSeleccionadoValue }:'',
            beneficiarioDefaultId:(props.beneficiarioSeleccionadoValue)||false,
            monedaDestinoData: props.getMonedaDestino(),
            //idCuentaBancaria:null,
            popupNuevoBeneficirioIsOpen:false,
            popupNuevaCuentaIsOpen:false,
            popupEditBeneficiarioIsOpen:false,

            cuentaSeleccionada:null,
            cuentaDefaultValue:'',
        };
        this.tokenAccess = props.tokenAccess;
        this.jumpToStep = props.jumpToStep;
        this.setBeneficiario = props.setBeneficiario.bind(this);
        this.handleToast = props.handleToast;

        // console.dir('RemesaBeneficiario');
        // console.dir(this.state);
    }
    /* al seleccionar un beneficiario del select */
    handleChange = event => {
        const selectedOption = event.target;
        this.setState({beneficiarioDefaultValue:{ label: selectedOption.label, value: selectedOption.value } });
        //console.log(selectedOption.value);
        this.setBeneficiarioSeleccionado(selectedOption.value);
    }
    setBeneficiarioSeleccionado = (idBeneficiario) => {
        //console.log('setBeneficiarioSeleccionado | idBeneficiario',idBeneficiario);
        let beneficiarioSeleccionado = this.state.data.filter(beneficiario => { return Number(idBeneficiario) === Number(beneficiario.id) });
        this.setState({beneficiarioSeleccionado : beneficiarioSeleccionado[0]});
        //valor default para el select
        if (beneficiarioSeleccionado[0]){
            const normalized = beneficiarioSeleccionado[0].nombre_beneficiario.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let beneficiarioDefaultValue = {
                label: normalized.toUpperCase(),
                value: beneficiarioSeleccionado[0].id
            };
            this.setState({beneficiarioDefaultValue : beneficiarioDefaultValue});
            this.loadSelectCuentas(beneficiarioSeleccionado[0]);
        }
    }
    /* al seleccionar una cuenta del select */
    handleChangeCuentas = event => {
        const selectedOption = event.target;
        this.setState({cuentaDefaultValue:{ label: selectedOption.label, value: selectedOption.value } });
        this.seleccionarCuenta(selectedOption.value);
    }
    seleccionarCuenta = (idCuenta) => {
        // console.log('seleccionarCuenta | this.state.beneficiarioSeleccionado:',this.state.beneficiarioSeleccionado);
        if (idCuenta && this.state.beneficiarioSeleccionado){
            //cuenta seleccionada
            let beneficiarioSeleccionadoCuentas = this.state.beneficiarioSeleccionado.cuentas.filter(beneficiarioCuenta => { return Number(beneficiarioCuenta.id) === Number(idCuenta) });
            if (beneficiarioSeleccionadoCuentas[0]){
              this.setState({
                  cuentaSeleccionada : beneficiarioSeleccionadoCuentas[0]
              });
            }
            // console.log('seleccionarCuenta | cuentaSeleccionada',beneficiarioSeleccionadoCuentas[0]);
        }
    }

    /* al escribir en los campos extra requeridos para cuenta */
    agregaValorCamposExtra = (e) => {
        let camposExtra = this.state.camposExtraCuenta;
        camposExtra.map((campo) => {
            if (campo.name === e.target.id){
                campo.value = e.target.value;
            }
            return campo;
        });
        this.setState({camposExtraCuenta:camposExtra })


    }


    /* agrega nuevo beneficiario */
    agregarBeneficiario = (beneficiario) => {
      //console.log('agregarBeneficiario',beneficiario);
        if (beneficiario){
            let data = this.state.data;
            beneficiario.cuentas=[];
            data.push(beneficiario);

            let options = this.state.selectOptions;
            let beneficiarioDefaultValue = {
                label: beneficiario.nombre_beneficiario,
                value: beneficiario.id
            };
            options.push(beneficiarioDefaultValue);

            this.setState({
                selectOptions : options,
                beneficiarioDefaultValue : beneficiarioDefaultValue,
                data:data,
                beneficiarioSeleccionado : beneficiario,
                cuentaSeleccionada:null
            });


        }
    };
    /* guarda beneficiario editado */
    guardarBeneficiario = (beneficiario) => {
        // console.log('guarda beneficiario', beneficiario);
        if (beneficiario){
            // console.log(beneficiario);
            let d={};
            let newdata = this.state.data;
            newdata.map((b, index) => {
                d = b;
                if (b.id === beneficiario.id){
                    d.nombre_beneficiario = beneficiario.nombre_beneficiario;
                    d.tipo_identificacion = beneficiario.tipo_identificacion;
                    d.nro_identificacion = beneficiario.nro_identificacion;
                    d.telefono = beneficiario.telefono;
                    d.correo = beneficiario.correo;
                }
                return d;
            });
            //  console.log(newdata);
            this.setState({
                data : newdata,
                validCondiciones : true
            });
            this.setBeneficiarioSeleccionado(beneficiario.id);

        }
    };
    /* agrega nueva cuenta */
    agregarCuenta = (cuenta) => {
        //  console.log('agregarCuenta',cuenta);
        if (cuenta){
            let d={};
            let newdata = this.state.data;
            newdata.map((b, index) => {
                d = b;
                if (Number(b.id) === Number(cuenta.id_beneficiario)){
                    b.cuentas.push(cuenta);
                }
                return d;
            });
            this.setState({
                data : newdata,
                popupNuevaCuentaIsOpen: false
            });
            // console.log('agregarCuenta | newdata',newdata);
            //  console.log('agregarCuenta | this.state.beneficiarioSeleccionado',this.state.beneficiarioSeleccionado);
            this.setBeneficiarioSeleccionado(cuenta.id_beneficiario);
            this.seleccionarCuenta(cuenta.id);
        }
    };

    closeNuevaCuenta = () => {
        this.setState({showModalNuevaCuenta:false});
    }


    loadOptions=(r)=>{
        if (r && r.length){
            const options = r.map((beneficiario, index) => {
              const normalized = beneficiario.nombre_beneficiario.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                 return {
                    label: normalized.toUpperCase(),
                    value: beneficiario.id
                 }
            });
            this.setState({selectOptions : options});
            if (r.length === 1){ this.setBeneficiarioSeleccionado(r[0].id); }
        }else{
            //en caso de no tener beneficiarios, abre el popup con el formulario directamente
            this.setState({popupNuevoBeneficirioIsOpen:true});
        }


    }
    loadSelectCuentas=(beneficiarioSeleccionado)=>{
        //    console.log('loadSelectCuentas',beneficiarioSeleccionado);
        if (beneficiarioSeleccionado && beneficiarioSeleccionado.cuentas.length>0){
            const options = beneficiarioSeleccionado.cuentas.map((cuenta, index) => {
                 return {
                    label: cuenta.banco +' - '+cuenta.tipo_de_cuenta,
                    value: cuenta.id
                 }
            });
            this.setState({selectOptionsCuentas : options});
            //if (setBeneficiario){
              let cuentaSeleccionada = beneficiarioSeleccionado.cuentas[beneficiarioSeleccionado.cuentas.length - 1];
              this.setState({
                  cuentaSeleccionada : cuentaSeleccionada,
                  cuentaDefaultValue : {
                      label: cuentaSeleccionada.banco +' - '+cuentaSeleccionada.tipo_de_cuenta,
                      value: cuentaSeleccionada.id
                   }
              });
          //  }

        }

    }
    componentDidMount(){
        let apiURL = process.env.REACT_APP_API_URL;
        fetch(apiURL +'index.php/remesa/listadoBeneficiarios',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess, id_moneda_destino:this.state.monedaDestinoData.id})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        data : result.result['beneficiarios'],
                        dataCondiciones : result.result['condiciones'],
                        camposExtraCuenta : result.result['campos_extra_cuenta']
                    });
                    if (this.state.beneficiarioDefaultId) this.setBeneficiarioSeleccionado(this.state.beneficiarioDefaultId);
                    this.loadOptions(result.result['beneficiarios']);

                }else{
                    this.setState({
                        error: result.message
                    });
                    if (result.action==='login'){
                        window.location.href = '/Login';
                    }
                }
                this.setState({isLoaded: false});
            },
            // Handle error
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            },
        )
    }


    isValidated = () => {
        // console.log(this.state.beneficiarioSeleccionado);
        /* un beneficiario seleccionado */
        if (!this.state.beneficiarioSeleccionado){
            this.handleToast('Debe seleccionar un Beneficiario','error');
            return false;
        }

        let v = true;
        /* miramos las condiciones que debe cumplir el beneficiario para el envio de remesa */
        if (this.state.dataCondiciones && this.state.dataCondiciones.length>0 && this.state.beneficiarioSeleccionado){
            let vc = true;
            this.state.dataCondiciones.map((c) => {
                //c puede ser una igualdad ex. tipo_identificacion=CPF
                if (c.includes('=')){
                    const condicion = c.split('=');
                    Object.keys(this.state.beneficiarioSeleccionado).map((campoBeneficiario) => {
                        if (campoBeneficiario === condicion[0] && (!this.state.beneficiarioSeleccionado[condicion[0]] || this.state.beneficiarioSeleccionado[condicion[0]] !==  condicion[1])){
                            vc = false;
                        }
                    });
                }else{
                    if (!this.state.beneficiarioSeleccionado[c]){
                        vc = false;
                    }
                }
            });
            this.setState({validCondiciones:vc});
            if (!vc){
                //lanzar popup edit
                 this.setState({popupEditBeneficiarioIsOpen:true});
                 v = false;
                 return false;
            }
        }
        /* una cuenta seleccionada */
        let dataSeleccionada = this.state.beneficiarioSeleccionado;

        if (!this.state.cuentaSeleccionada){
            this.handleToast('Debe seleccionar una Cuenta Bancaria','error');
            v = false;
            return false;
        }else{
            //agregamos cuentaSeleccionada a la dataSeleccionada
            dataSeleccionada.cuentas=[this.state.cuentaSeleccionada];
            //nos aseguramos que los datos extra requeridos estén completos
            if (this.state.camposExtraCuenta && this.state.camposExtraCuenta.length>0){
                this.state.camposExtraCuenta.map((campo) => {
                   // console.log('camposExtraCuenta',campo);
                    if (!campo.value || campo.value === ''){
                        //alert('debe ingresar el valor para '+campo.title);
                        this.handleToast('debe ingresar el valor para '+campo.title,'error');
                        v = false;
                    }else{
                        dataSeleccionada.cuentas[0][campo.name]=campo.value;
                    }
                });
            }
        }

        if (v===true){ //enviamos data seleccionada a remesa
        //  console.log('dataSeleccionada',dataSeleccionada);
           this.setBeneficiario(dataSeleccionada);
        }
        return v;
    }




    render() {
        const {error, isLoaded, data, value,editing, toastMsg, toastShow,selectOptions,beneficiarioSeleccionado,cuentasBancarias,popupNuevoBeneficirioIsOpen,validCondiciones,dataCondiciones} = this.state;

        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
<Translation ns={['remesas']}>
{
  (t) =><div className="step_content_item second_step_content_item step_content_item_active">
  <h4>{t('Seleccione el beneficiario de la remesa')} </h4>
  <div className="recipient_wrapper">
        <div className="recipient_wrapper_item">
            {/* select beneficiario */}
            <h6>{t('Seleccione el beneficiario de la remesa')}</h6>
            <div className="recipient_select_wrap">
                <select id="recipient_user"
                    placeholder="Seleccione un beneficiario"
                    onChange={this.handleChange}
                    value={this.state.beneficiarioDefaultValue.value}>
                     <option value="">{t('Seleccione el beneficiario de la remesa', {ns:'remesas'})}</option>
                    {selectOptions.map((d, index) => {
                      return <option key={index} value={d.value}>{d.label}</option>
                    })}
                </select>
                <BeneficiarioFormDataPopup
                    nuevoBeneficiario={true}
                    buttonContent={t('agregar beneficiario')}
                    isOpen={popupNuevoBeneficirioIsOpen}
                    tokenAccess = {this.tokenAccess}
                    guardarBeneficiario={(beneficiario) => (this.agregarBeneficiario(beneficiario))}
                    handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                    requiredFields={this.state.dataCondiciones}
                    id_moneda_destino={this.state.monedaDestinoData.id}
                    buttonClassName="transparent_btn"/>
            </div>
            {/* datos beneficiario seleccionado */}
            { beneficiarioSeleccionado && <div className="recipient_user_info_wrap">
            <div className="recipient_user_info">
                {!validCondiciones && dataCondiciones && dataCondiciones.length > 0 && <p className="small pt-3 pb-1"><MdInfoOutline className="text-warning"/> {t('Por favor verifique los datos requeridos para el beneficiario antes de continuar')}</p>}
                <div className="recipient_user_info_titles">
                  <p className='icon-id'> <span>{t('Identificación')}</span></p>
                  <p className='icon-phone'> <span>{t('telefono')}</span></p>
                  <p className='icon-email'> <span>{t('correo')}</span></p>
                </div>
                <div className="recipient_user_info_text">
                    <p>{beneficiarioSeleccionado.tipo_identificacion} {beneficiarioSeleccionado.nro_identificacion}</p>
                    <p>{(beneficiarioSeleccionado.telefono !== 'NULL')?beneficiarioSeleccionado.telefono:''}</p>
                    <p>{beneficiarioSeleccionado.correo}</p>
                </div>
            </div>

              {/* editar beneficiario  */}
              <BeneficiarioFormDataPopup
                  editBeneficiario = {true} nuevoBeneficiario = {false}
                  isOpen = {this.state.popupEditBeneficiarioIsOpen}
                  introTitle={t('Por favor verifique los datos requeridos para el beneficiario antes de continuar')+' (*).'}
                  intro={t('codicion brasil')}
                  buttonClassName = "edit_btn"
                  tokenAccess = {this.tokenAccess}
                  guardarBeneficiario={(beneficiario) => (this.guardarBeneficiario(beneficiario))}
                  id_beneficiario={(String(beneficiarioSeleccionado.id))}
                  handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                  requiredFields={this.state.dataCondiciones}
                  id_moneda_destino={this.state.monedaDestinoData.id}
              />
            </div>}
            </div>



            {/* datos beneficiario seleccionado cuentas */}
            { beneficiarioSeleccionado && beneficiarioSeleccionado.cuentas.length > 0 &&
            <div className="recipient_wrapper_item">
                <h6>{t('Seleccione la cuenta bancaria destino')} <span>({this.state.monedaDestinoData.moneda})</span></h6>
                <div className="recipient_select_wrap">
                    <select id="recipient_user2"
                        placeholder={t('Seleccione la cuenta bancaria destino')}
                        onChange={this.handleChangeCuentas}
                        value={this.state.cuentaDefaultValue.value}>
                         <option value="">{t('Seleccione el beneficiario de la remesa')}</option>
                        {this.state.selectOptionsCuentas.map((d, index) => {
                           return <option key={index} value={d.value}>{d.label}</option>
                        })}
                    </select>

                         {/* nueva cuenta form */}
                        <BeneficiarioFormCuentaPopup
                            nuevaCuenta={true}
                            buttonContent={t('Nueva cuenta')}
                            tokenAccess = {this.tokenAccess}
                            buttonClassName="transparent_btn"
                            guardarCuenta={(c) => (this.agregarCuenta(c))}
                            id_beneficiario = {(beneficiarioSeleccionado.id)}
                            handleToast={(status, msg) => (this.handleToast(status, msg))}
                            isOpen={this.state.popupNuevaCuentaIsOpen}
                        />
                </div>
                { this.state.cuentaSeleccionada &&
                <div className="recipient_user_info_wrap">
                    <div className="recipient_user_info">
                        <div className="recipient_user_info_titles">
                            <p>{t('Banco')}:</p>
                            <p>{t('Tipo de Cuenta')}:</p>
                            <p>{t('Nro. de Cuenta')}:</p>
                        </div>
                        <div className="recipient_user_info_text">
                            <p>{this.state.cuentaSeleccionada.banco}</p>
                            <p>{this.state.cuentaSeleccionada.tipo_de_cuenta}</p>
                            <p>{this.state.cuentaSeleccionada.n_cuenta}</p>
                        </div>
                    </div>


                    {/* campos extra para la cuenta del beneficiario */}
                    { this.state.camposExtraCuenta.length > 0 &&
                        <div>
                            { this.state.camposExtraCuenta.map((campo, index) => {
                                return <div className="row p-2" key={index}>
                                            <div className="col-md-4">
                                                <label>{campo.title}:</label>
                                            </div>
                                            <div className="col-md-8">
                                                <input type={campo.type} autoFocus className="input border" onChange={this.agregaValorCamposExtra} name={campo.name} id={campo.name}/>
                                            </div>
                                        </div>
                            })}
                        </div>
                    }

                </div>}
            </div>}


                        {/* cuenta seleccionada */}
                        {beneficiarioSeleccionado && beneficiarioSeleccionado.cuentas.length === 0 &&
                            <div>
                                <p className="small pt-3 pb-1">
                                  <MdInfoOutline className="text-warning"/> &nbsp;
                                  {t('Agregue una cuenta Bancaria destino en')} {this.state.monedaDestinoData.moneda}.
                                  {this.state.monedaDestinoData.id =='2' && <span className="color-naranja"> ({t('codicion brasil')})</span>}
                                </p>
                                <div className="col-12 card mt-3 pt-4">
                                    <BeneficiarioNuevaCuenta
                                        tokenAccess = {this.tokenAccess}
                                        id_beneficiario = {(String(beneficiarioSeleccionado.id))}
                                        id_moneda_destino={this.state.monedaDestinoData.id}
                                        handleToast={(status, msg) => (this.handleToast(status, msg))}
                                        guardarCuenta={(cuenta) => (this.agregarCuenta(cuenta))}
                                        cerrarBtn={false}
                                    />
                                </div>
                            </div>
                        }
            </div>
        </div>
        }
</Translation>
);
        }
    }
}
export default RemesaBeneficiario;
