import React from 'react';
import Modal from 'react-bootstrap/Modal';

import BeneficiarioNuevaData from "./BeneficiarioNuevaData";
import BeneficiarioEditData from "./BeneficiarioEditData";
import { Translation } from 'react-i18next';

const publicURL = process.env.REACT_APP_URL;
const sitename = process.env.REACT_APP_NAME;

class BeneficiarioFormDataPopup extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          isOpen: props.isOpen || false,
          editBeneficiario:props.editBeneficiario,
          nuevoBeneficiario:props.nuevoBeneficiario,
          buttonClassName:props.buttonClassName,
          buttonContent:props.buttonContent || null
      };
      this.introTitle = props.introTitle;
      this.intro = props.intro;
      this.id_beneficiario = props.id_beneficiario || null;
      this.tokenAccess = props.tokenAccess;
      this.handleToast = props.handleToast;
      this.guardarBeneficiario = props.guardarBeneficiario;
      this.requiredFields = props.requiredFields;
      this.id_moneda_destino = props.id_moneda_destino;

      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      // console.log('BeneficiarioFormDataPopup',this.id_moneda_destino);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  closeModal() {
    this.setState({ isOpen: false });
  }
  guardarBeneficiarioBridge(b) {
    this.guardarBeneficiario(b);
    this.closeModal();
  }



  /*Whenever a class component is updated*/
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({isOpen: this.props.isOpen});
    }
  }


    render() {
        return(
<Translation ns={['beneficiarios']}>
{
  (t) =><div>
            <button className={this.state.buttonClassName || 'transparent_btn'} onClick={this.openModal}>
              { this.state.nuevoBeneficiario && <span className="pl-2 pr-2"> {this.state.buttonContent} </span> }
              { this.state.editBeneficiario && this.state.buttonContent && <span>{this.state.buttonContent}</span> }
              { this.state.editBeneficiario && !this.state.buttonContent && <span className='edit_btn'></span> }
            </button>
            <Modal className="modal-dialog modal-dialog-centered modal-xl"
              size="lg"
              show={this.state.isOpen}
              onHide={this.closeModal}
              aria-labelledby="beneficiarioForm"
            >
              <Modal.Header closeButton>
                <Modal.Title id="beneficiarioForm">
                  {t('form.titulo')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <div className="form">
                <div className="form-row">
                <div className="col-12">
                    { this.state.nuevoBeneficiario && <div>
                      <p className="text-muted">{this.introTitle || this.intro } </p>
                      <BeneficiarioNuevaData
                          tokenAccess = {this.tokenAccess}
                          handleClose={() => (this.closeModal())}
                          handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                          guardarBeneficiario={(b) => (this.guardarBeneficiarioBridge(b))}
                          requiredFields={this.requiredFields}
                          id_moneda_destino={this.id_moneda_destino}
                      />
                    </div>}
                    { this.state.editBeneficiario &&
                      <BeneficiarioEditData
                          tokenAccess = {this.tokenAccess}
                          id_beneficiario = {this.id_beneficiario}
                          handleClose={() => (this.closeModal())}
                          handleToast={() => (this.handleToast())}
                          guardarBeneficiario={(b) => (this.guardarBeneficiarioBridge(b))}
                          requiredFields={this.requiredFields}
                          id_moneda_destino={this.id_moneda_destino}
                      />
                    }
                </div>
                </div>
              </div>

              </Modal.Body>
            </Modal>

        </div>
}
</Translation>
        );
    }
}

export default BeneficiarioFormDataPopup;
