import Auth from '../middlewares/Auth';

const SendFormData = async(formData,url) => {
    const token =  Auth.getToken();
    const apiURL = process.env.REACT_APP_API_URL;
    const response = await fetch(apiURL+url,{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token, data:formData})
    });
    const respuesta = await response.json();

    return respuesta;
}

const SendFormDataFile = async(formData,url) => {
   // console.log(typeof formData, formData);
    const token =  Auth.getToken();
    const apiURL = process.env.REACT_APP_API_URL;
    const response = await fetch(apiURL+url,{
        method: 'post',
        headers: {'content-type': 'multipart/form-data'},
        body: {token:token, data:formData}
    });
    const respuesta = await response.json();

    return respuesta;
}
export default  SendFormData;