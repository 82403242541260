
import React from 'react';
import SendFormData from "../../server/sendFormData";
import Form from "react-jsonschema-form";
import FormTools from '../common/formTools';
import { Translation } from 'react-i18next';

import '../../assets/css/form.css';


class BeneficiarioFormData extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        error : null,
        data: props.data,
        schemaForm : props.schemaForm
    };
    this.tokenAccess = props.tokenAccess;
    this.handleClose = props.handleClose || null;
    this.handleToast = props.handleToast;
    this.guardarBeneficiario = props.guardarBeneficiario || null;
  }
  customFieldTemplate = (props)=>{
      const {id, classNames, label, help, required, description, errors, children, hidden} = props;
      return (
        <Translation ns={['registro']}>
        {
          (t) =>
          <div className={classNames}>
            {!hidden && label && <label htmlFor={id}>{t(label)}
            {required && <span className="required"> ({t('dato obligatorio')})</span>}
            {!required &&<span className="norequired"> ({t('opcional')})</span>}
            </label>}

                {description}
                {children}
                {help}
                {errors.props && errors.props.errors && errors.props.errors.length > 0 && errors.props.errors.map((k,index) => {
                    return  <div key={index} className="text-danger small">
                        {t(errors.props.errors[index])}
                    </div>
                  })
                }
          </div>
        }
        </Translation>
      );
  }


  onChange = ({formData}) => {
      let d = this.state.data;

      Object.keys(formData).map((key) => {
          //identificacion
          if (formData[key] === null || formData[key] === undefined) { formData[key] = ''; }
          if (key === 'identificacion' && d.result.schema.properties[key]){
            //console.dir(d.result.schema.properties[key]);
             Object.keys(formData[key]).map((k, idx) => {
                 d.result.schema.properties[key]['properties'][k]['default']=formData[key][k];
                 return k;
             });
          }else if (d.result.schema.properties[key]){
             if  (key.includes('nombre')){
               //const normalized = formData[key].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
               //d.result.schema.properties[key].default = normalized.toUpperCase();
               ////^[a-zA-Z\s\b]*$/
               //if(formData[key].match(/^[a-zA-Z\s\b]*$/)){
                 d.result.schema.properties[key].default = formData[key].toUpperCase();
               //}
             }else{
               d.result.schema.properties[key].default = formData[key];
             }
          }
          return key;
      });
      this.setState({data:d});
  }

  onSubmitProfile = ({formData}, e) => {
    SendFormData(formData, 'index.php/beneficiario/guardaDatosPersonales')
    .then((resp) => {
        if (this.handleClose) { this.handleClose(); }
        if (this.handleToast) { this.handleToast(resp.status,resp.message); }
        if (resp.status === 'ok'){
            if (this.guardarBeneficiario) {
              //enviamos registro en formato schema
                if (this.state.schemaForm){
                  let d = this.state.data.result;
                  Object.keys(resp.result).map((k) => {
                    if (d.schema.properties[k]){
                      d.schema.properties[k].default = resp.result[k]
                    }
                    return k;
                  });
                  this.guardarBeneficiario(d);
                }else{
                  this.guardarBeneficiario(resp.result);
                }
            }
        }
    });

  }

    render() {
        const {data} = this.state;
        return(
<Translation ns={['translation']}>
{
  (t) =><div>
                {data && <Form className="col-12 frmBeneficiario"
                  schema={data.result.schema}
                  uiSchema={data.result.uiSchema}
                  customFormats={data.result.customFormats}
                  transformErrors={FormTools.transformErrors}
                  onSubmit={this.onSubmitProfile}
                  onChange={this.onChange}
                  FieldTemplate={this.customFieldTemplate}
                >
                <div className="form-group field-submit full_row">
                  {this.handleClose && <button type="button" onClick={this.handleClose} className="btn-dark btn-close mr-2">{t('Cerrar popup')}</button>}
                  <button type="submit">{t('Guardar cambios')}</button>
                </div>
                </Form>
                }
            </div>
        }
</Translation>
        );

    }
}

export default BeneficiarioFormData;
