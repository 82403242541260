import React from 'react';
import { Link } from 'react-router-dom';
import SendFormData from "../../server/sendFormData";

import BeneficiarioFormDataPopup from './BeneficiarioFormDataPopup';
import AlertDialog from '../common/AlertDialog';

import CustomToast from '../common/CustomToast';

import { Translation } from 'react-i18next';
import variables from "../../variables/env.js";

const linkToEdit = '/beneficiario/';

class BeneficiariosAdmin extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            data:[],
            toast: { msg:null, show:false, tclass:null },
            idBeneficiarioParaEliminar:null,isOpenDialogConfirmarEliminar:false,
            popupFormDataIsOpen:false
        };

    }
    handleToast = (status, msg) =>{
        if (msg){
            this.setState({
                toast: { msg:msg, show:true, tclass:(status==='ok')?'success':'danger' }
            });
        }
    }
    /* elimina beneficiario con confirmacion previa */
    eliminarBeneficiario = (id_beneficiario) => {
        this.setState({
            idBeneficiarioParaEliminar:id_beneficiario,
            isOpenDialogConfirmarEliminar:true
        });
    }
    handleResponseConfirmaEliminar = (r) => {
        if (r) { this.eliminarBeneficiarioConfirmado(this.state.idBeneficiarioParaEliminar); }
        this.setState({
            idBeneficiarioParaEliminar:null,
            isOpenDialogConfirmarEliminar:false
        });
    }
    eliminarBeneficiarioConfirmado = (id_beneficiario) => {
        SendFormData({id_beneficiario:id_beneficiario}, 'index.php/beneficiario/eliminaBeneficiario')
        .then((resp) => {
            this.handleToast(resp.status,resp.message);
            if (resp.status === 'ok'){
                let d = this.state.data.filter(beneficiario => { return id_beneficiario !== beneficiario.id })
                this.setState({data:d});
                // console.log(this.state.data);
            }
        });
    };
     /* agrega beneficiario  */
    agregarBeneficiario = (beneficiario) => {
        let d = this.state.data || [];
        d.push(beneficiario)
        this.setState({data:d});
    };
    componentDidMount(){
        this.tokenAccess = variables.auth('token');
        fetch(variables.apiurl+'index.php/beneficiario/usuarioBeneficiarios',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        isLoaded:false,
                        data : result.result.data
                    });
                    if (result.result.length===0){
                      this.setState({popupFormDataIsOpen:true})
                    }
                }else{
                    this.setState({
                        isLoaded:false,
                        error: result.message
                    });
                    if (result.action==='login'){
                          window.location.href = '/login';
                    }
                }
            },

            // Handle error
            (error) => {
                this.setState({
                    error
                })
            },
        )
        // document.body.className = "";
        document.body.classList.add('dashboard', 'logged');
    }


   render() {
        const {isLoaded, data} = this.state;
        return(
<Translation ns={['beneficiarios']}>
{
  (t) =>
        <div>
            {isLoaded && <div className="loading"><div className="spinner-border text-muted"></div></div>}

            <CustomToast msg={this.state.toast.msg} show={this.state.toast.show} tclass={this.state.toast.tclass}/>
            <div>
                <div className="beneficiaries_title_wrap">
                    <h3>{t('Mis Beneficiarios')}</h3>
                    <div className="text-right">
                         {/* popup nuevo beneficiario */}
                        <BeneficiarioFormDataPopup
                            isOpen={this.state.popupFormDataIsOpen}
                            nuevoBeneficiario={true}
                            tokenAccess = {this.tokenAccess}
                            guardarBeneficiario={(beneficiario) => (this.agregarBeneficiario(beneficiario))}
                            handleToast={this.handleToast}
                            buttonClassName="transparent_btn"
                            buttonContent={t('Nuevo Beneficiario')}
                        />
                    </div>
                </div>

                {/* si OK sin datos*/}
                {!isLoaded && (!data || !data.length) &&
                    <div className="beneficiaries_items_wrapper">
                            <small className="text-muted font-italic">{t('admin.Aún no existen beneficiarios registrados')}</small>
                    </div>
                }

            </div>

            {/* si OK con datos*/}
            { !isLoaded &&  data &&
            <div className="beneficiaries_items_wrapper">

                {/* dialog comfirmación para eliminar */}
                <AlertDialog
                    isOpen={this.state.isOpenDialogConfirmarEliminar}
                    handleResponse={(r)=>(this.handleResponseConfirmaEliminar(r))}
                    message={t('admin.¿Está seguro de querer eliminar este beneficiario?')}
                />
              {/*  <div className="col-12 text-muted small mb-3">
                  <div><a className="text-primary p-1 "><MdModeEdit/></a> {t('admin.explicacion edit')}</div>
                  <div><a className="text-danger p-1"><MdDeleteForever/></a> {t('admin.explicacion delete')}</div>
                </div>*/}

                {   data.map((beneficiario, index) => {
                    return <div key={index} id={`beneficiario-${beneficiario.id}`}  className="">
                        <div className="row border m-0">
                            <div className="col-10 beneficiaries_item">
                                <div className="beneficiaries_items_title">
                                    <div className="beneficiaries_items_name">{beneficiario.nombre_beneficiario}</div>
                                
                                </div>
                                <div className="transaction_info">
                                    <div className="beneficiaries_item_transaction_info_wrap">
                                    <div className="beneficiaries_item_transaction_info">{beneficiario.tipo_identificacion} {beneficiario.nro_identificacion}</div>
                                    {beneficiario.telefono && beneficiario.telefono.length > 0 && <div className="beneficiaries_item_transaction_info icon-phone"><span>{beneficiario.telefono}</span></div>}
                                    {beneficiario.correo  && beneficiario.correo.length > 0 &&  <div className="beneficiaries_item_transaction_info icon-email"><span>{beneficiario.correo}</span></div>}
                                    </div>
                                    {/*<a href="#" onClick={() => {
                                    this.eliminarBeneficiario(beneficiario.id);
                                    }}
                                className="delete_btn onClick"></a>*/}

                                </div>
                            </div>
                            <div className="col-2 beneficiaries_item position-relative bg-light" style={{'padding': '20px'}}>
                                <div className="beneficiaries_items_edit_btn edit_btn">
                                    <Link to={linkToEdit+beneficiario.id}></Link>
                                </div>
                                <div className="beneficiaries_items_edit_btn delete_btn mt-3" onClick={() => {
                                    this.eliminarBeneficiario(beneficiario.id);
                                    }}>
                                </div>
                            </div>

                        </div>    
                    </div> })
                }

            </div>
            }
        </div>
}
</Translation>
        );


    }
}

export default BeneficiariosAdmin;
