import Auth from './Auth';
import React from 'react';
import { Routes, Route } from 'react-router-dom';


function PrivateRoute ({component: Component, authed, ...rest}) {
      return (
        <Routes>
        <Route
          {...rest}
          render={(props) => Auth.getAuth()
            ? <Component {...rest} {...props} />
            : navigate('/Login')}
        />
        </Routes>
      )
    }

export default PrivateRoute;
