import React from "react"
const publicURL = process.env.REACT_APP_URL;

const PageNotFound = () => (
//Fragment
  <>
        {/* Mensaje cuando llegue a página incorrecta */}
      <div className="text-center m-5">
          <img src={publicURL+'assets/img/404.png'} alt={'404'} title={'404'} width={'40%'}/>
      </div>
  </>
)
export default PageNotFound
