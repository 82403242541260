import React from 'react';
import BeneficiarioFormCuenta from "./BeneficiarioFormCuenta";
import getLanguage from '../../language/getLanguage';
const lang = getLanguage();
const apiURL = process.env.REACT_APP_API_URL;

class BeneficiarioNuevaCuenta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded:true,
            error:null,
            data: null,
            schemaForm : props.schemaForm,
            bancos:[]
        };
        this.tokenAccess = props.tokenAccess;
        this.handleToast = props.handleToast;
        this.handleToast = (props.handleToast) ? props.handleToast.bind(this) : null;
        this.guardarCuenta = props.guardarCuenta;
        this.id_beneficiario = props.id_beneficiario;
        this.id_moneda_destino = props.id_moneda_destino || null;
    }

    guardarCuentaBeneficiarioBridge(b) {
        //console.log(b);
        this.guardarCuenta(b);
    }

    componentDidMount(){

        let dataPost = {id_beneficiario: this.id_beneficiario, id_moneda_destino:this.id_moneda_destino};
        fetch(apiURL+'index.php/beneficiario/obtenerCuentaNuevaForm',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess, data:dataPost, lang:lang})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => { //console.log('Beneficiario nueva cuenta',result);
                if (result.status ==='ok'){
                    this.setState({
                        data : result.result[0],
                        isLoaded:false
                    });
                }else{
                    this.setState({
                        error: result.message
                    });
                    if (result.action==='login'){
                        window.location.href = '/Login';
                    }
                }
            },

            // Handle error
            (error) => {
                this.setState({
                    error
                })
            },
        )
    }
    render() {
        const {isLoaded,data} = this.state;
        if(isLoaded || !data) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
                <BeneficiarioFormCuenta
                      tokenAccess = {this.tokenAccess}
                      data = {this.state.data}
                      handleToast={() => (this.handleToast())}
                      handleClose={this.handleClose}
                      guardarCuenta={(b) => (this.guardarCuentaBeneficiarioBridge(b))}
                      schemaForm={this.state.schemaForm}
                      formData={{}}
                  />
            );
        }
    }
}

export default BeneficiarioNuevaCuenta;
