import React from 'react';
import Popup from "reactjs-popup";

import DatosPersonalesForm from './DatosPersonalesForm';

class DatosPersonalesFormPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen,
            intro:props.intro
        };
        this.tokenAccess = props.tokenAccess;
        this.guardarRegistroBridge = props.guardarRegistro;
        this.requiredFields = props.requiredFields;
        this.onClose = props.onClose || null;
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true });
    }
    closeModal() {
        this.setState({ isOpen: false });
        if (this.onClose) this.onClose();
    }
    guardarRegistroBridge(b) {
        this.closeModal();
        this.guardarRegistro(b);
    }
    /*Whenever a class component is updated*/
    componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({isOpen: this.props.isOpen});
    }
    if (prevProps.intro !== this.props.intro) {
      this.setState({intro: this.props.intro});
    }

  }

    render() {
        return(<div>
            <Popup modal
              open={this.state.isOpen} onClose={this.closeModal}>
              {close => (
                <div>
                  <a className="close" onClick={close}>&times;</a>
                    {this.state.intro && <h5 className="color-naranja pr-md-5">{this.state.intro}</h5>}
                    <DatosPersonalesForm
                        tokenAccess={this.tokenAccess}
                        requiredFields={this.requiredFields}
                        guardarRegistro={(r) => (this.guardarRegistroBridge(r))}
                    />
                </div>
              )}
            </Popup>
        </div>);
    }
}

export default DatosPersonalesFormPopup;
