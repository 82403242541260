import React from 'react';
import PrivateArea from '../cliente/PrivateArea';
import DatosPersonalesForm from './DatosPersonalesForm';

import { Translation } from 'react-i18next';

class Profile extends React.Component {
    constructor(props){
        super(props);
    }
    componentDidMount(){
        // document.body.className = "";
        document.body.classList.add('profile', 'logged');
    }
    render() {
        return(
<Translation ns={['translation']}>
{
  (t) =><PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="profile_tab" activeLink="profile" servicioRemesaActivo={this.servicioRemesaActivo}>
          <div className="profile_wrap">
            <h3>{t('Datos Personales')}</h3>
            <DatosPersonalesForm/>
          </div>
        </PrivateArea>
}
</Translation>
        );

    }
}
export default Profile;
