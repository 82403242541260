import React, { Component } from 'react';
import PrivateArea from '../cliente/PrivateArea';
import { Translation } from 'react-i18next';
import logout from "../login/logout";
import variables from "../../variables/env.js";

class TrackingPaqueteria extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            codigoEnvio : '',
            tracking : null,
            errorDefault: null
        };
        this.tokenAccess = variables.auth('token');
        this._handleChange = this._handleChange.bind(this);
    }

    /** Recuperar el valor del input  **/
    _handleChange(e) {
        this.setState({
            codigoEnvio: e.target.value,
            error : null
        });
    }

    /** Enviar data al server **/
    sendData = () => {
        /*** Validamos que el input no se encuentre vacio ***/
        if (`${this.state.codigoEnvio}`.length > 0){
            const data = {codigoEnvio: this.state.codigoEnvio}
            this.setState({error : null,tracking:null});

            fetch(variables.apiurl +'index.php/paqueteria/actionTrakingPaqueteria',{
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token:this.tokenAccess,
                    lang:variables.lang,
                    data:data
                })
            })
                .then( response => response.json())
                .then((result) => {
                        if (result.status === 'ok'){
                            this.setState({
                                tracking : result.result,
                                error:null
                            });
                        }else{
                            this.setState({
                                error: result.message
                            });
                        }
                    },
                );
        }else{
            this.setState({errorDefault : true});
        }
    }

    /** Enviar data al serverbotón volver**/
    resetData = () => {
      this.setState({error : null,tracking:null});
    }

    render() {
        const {error,errorDefault, codigoEnvio,tracking} = this.state;

        return(<Translation ns={['translation']}>
                {(t) =>
                    <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="payment_tab" activeLink="TrakingPaqueteria">
                        <div className="row justify-content-center pt-4">
                            <div className="col-lg-8 col-xs-12 mb-4 dashboard_tab_item">
                                <div className="card rounded-top ">
                                    {!tracking &&
                                    <div className="card-body">
                                        <h4 className="bold mb-4">{t('Localiza tu paquete')}</h4>
                                        <label htmlFor="canjeCodigoCupon">{t('Introduce el código de envío')}:</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control font-weight-600" id="codigoTrankingPaqueteria"  aria-describedby="codigoTrankingPaqueteriaHelp" onChange={this._handleChange} required="required"/>
                                            <div className="input-group-append">
                                                <button type="button" className="transparent_btn ml-2 border-1" onClick={this.sendData}>{t('Continuar')}</button>
                                            </div>
                                            {error && <div className="col-12"><div className="alert alert-danger mt-2" role="alert"> <i className="fa fa-times" aria-hidden="true"></i> {error} </div> </div>}
                                            {!error && errorDefault && <div className="col-12"><div className="alert alert-danger mt-2" role="alert"> <i className="fa fa-times" aria-hidden="true"></i> {t('Introduce el código de envío')} </div> </div>}

                                        </div>
                                    </div>
                                  /* fin form introduce codigo de envio */}

                                  {tracking && <div className="card">
                                      <div className="card-body p-0">
                                          <h4 className="card-title">{t('Tracking de envío')} <span className="primary-color">{codigoEnvio}</span></h4>
                                            <ul className="timeline mt-4 mb-4">
                                            {tracking.map((t, index) => {
                                              return <li key={index} className="event" data-date={t.fecha}>
                                                  <p><img src={variables.appurl+"/assets/img/git/"+t.nombre_git} alt="git" width={180}/></p>
                                              </li>
                                            })}
                                            </ul>
                                            <div className="text-right">
                                              <button type="button" className="transparent_btn ml-2 border-1" onClick={this.resetData}>{t('Volver')}</button>
                                            </div>
                                      </div>
                                  </div>
                                  /* fin traking */}
                                </div>
                            </div>
                        </div>
                    </PrivateArea>
                }
            </Translation>
        );

    }
}
export default TrackingPaqueteria;
