import React from 'react';
import ContactForm from "./ContactForm";
import useGetHTMLFetch from "../../server/useGetHTMLFetch";

export default function Cesta(props) {
    const content = useGetHTMLFetch('index.php/contenidos/cesta');

    return(
         <main id="wrapper" className="container mt-5 mb-5 p-4 bg-white" role="main">
            <div dangerouslySetInnerHTML={{__html: content}} />
            <ContactForm/>
        </main>
     );
}
