import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BeneficiarioNuevaCuenta from "./BeneficiarioNuevaCuenta";

class BeneficiariosFormCuentaPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen || false,
            editCuenta:props.editCuenta,
            nuevaCuenta:props.nuevaCuenta,
            buttonClassName:props.buttonClassName,
            buttonContent:props.buttonContent,
            schemaForm : props.schemaForm
        };
        this.tokenAccess = props.tokenAccess;
        this.id_beneficiario = props.id_beneficiario;
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleToast = props.handleToast;
        this.guardarCuenta = props.guardarCuenta;
      }
      openModal() {
        this.setState({ isOpen: true });
      }
      closeModal() {
        this.setState({ isOpen: false });
      }
      guardarCuentaBridge(b) {
          // console.log(b);
        this.guardarCuenta(b);
        this.closeModal();
      }
    /*Whenever a class component is updated*/
    componentDidUpdate(prevProps) {
      if (prevProps.isOpen !== this.props.isOpen) {
        this.setState({isOpen: this.props.isOpen});
      }
    }
    render() {
      return(
        <div>
            <button className={this.state.buttonClassName || 'back_btn transparent_btn transparent_btn_back'} onClick={this.openModal}>
              { this.state.nuevaCuenta && <span>{this.state.buttonContent}</span> }
              { this.state.editCuenta && <span>{this.state.buttonContent}</span> }
            </button>
            <Modal className="modal-dialog modal-dialog-centered modal-xl"
              size="lg"
              show={this.state.isOpen}
              onHide={this.closeModal}
              aria-labelledby="beneficiarioFormCuenta"
            >
            <Modal.Header closeButton>
            { this.state.nuevaCuenta &&<Modal.Title id="beneficiarioFormCuenta">{this.state.buttonContent}  </Modal.Title> }
            { this.state.editCuenta && <Modal.Title id="beneficiarioFormCuenta">{this.state.buttonContent}</Modal.Title> }

            </Modal.Header>
            <Modal.Body>

            <div className="form">
              <div className="form-row">
              <div className="col-12">
                { this.state.nuevaCuenta &&
                    <BeneficiarioNuevaCuenta
                        tokenAccess = {this.tokenAccess}
                        id_beneficiario = {this.id_beneficiario}
                        handleClose={() => (this.closeModal())}
                        handleToast={() => (this.handleToast())}
                        guardarCuenta={(b) => (this.guardarCuentaBridge(b))}
                        schemaForm={this.state.schemaForm}
                    />
                  }

                  </div>
                  </div>
                </div>

                </Modal.Body>
              </Modal>
        </div>
      );
    }
}

export default BeneficiariosFormCuentaPopup;
