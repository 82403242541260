import React, { useState, useEffect } from 'react';

import {NavLink, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import variables from "../../variables/env.js";
import getCountCesta from "../../components/cestaCompra/Cesta";
import CustomModal from "../common/CustomModal";

function Header(props) {
  const { t, i18n } = useTranslation(['translation', 'menu']);
  const [appParams, setAppParams] = useState(null);
  const [notificacion, setNotificacion] = useState(null);
  const [modalMsg, setModalMsg] = useState(null);
  
  const activemnu = window.location.pathname + window.location.search;
  const location = useLocation();
  const showBtnRegistro = (variables.mienv === 'wws' && location.pathname === '/login');
  const tokenAccess = variables.auth('token');
  const lang = variables.lang;
  let appInfo = {};
  try {
    appInfo = JSON.parse(process.env.REACT_APP_COMPANYINFO);
  } catch (e) {
    console.error(e);
  }

  useEffect(() => {
    getNotificacionesWEB();
    // Fetch Function
    if (!appParams){
      fetch('./params.json').then(
          function (res) {
            return res.json();
          },
      ).then(function (data) {
        setAppParams(data);
      }).catch(
          function (err) {
          },
      );
    }
  }, [location]);

    const getNotificacionesWEB = () => {
      fetch(variables.apiurl + 'index.php/notificaciones/getNotificacionWEB', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token:tokenAccess,
          lang:lang
        })
      }).then(response => response.json())
          .then(
              (result) => {
                if (result.status === 'ok' && result.result.length > 0) {
                  setNotificacion(result.result);
                  if (!variables.getNotificacionLeida()){
                      setModalMsg(result.result[0].texto);
                  }
                }
              }
          );
  }


  const toggleDropdown = (e) => {
    var elem = e.target.closest('#navbarNav');
    elem.classList.contains('show') ? elem.classList.remove('show') : elem.classList.add('show');
  };

  return (<div>
    {window.scrollTo(0, 0)}
    <header id="header" className="header">
      <div className="top_header_block">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className="top_header_block_wrap">
                {appInfo.tel && appInfo.whatsapp &&
                <div className="top_header_block_contacts">

                  <a href={'/tel='+ appInfo.tel} target="_blank" className="ml-0">
                    <img src={variables.appurl + 'assets/img/phone-call.svg'} alt="tel" width="16" height="16"/> {appInfo.tel}
                  </a>

                  <a href={'https://api.whatsapp.com/send/?phone='+ appInfo.whatsapp + '&text=Estoy+interesado+en+su+servicio.'} target="_blank" className="mt-5 ml-5">
                    <img src={variables.appurl + 'assets/img/whatsapp-call.svg'} alt="whatsapp" width="16" height="16"/> {appInfo.tel}
                  </a>
                </div>
               }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg">
              <NavLink exact="true" className="navbar-brand custom-logo-link" to="/" >
                  <img src={variables.appurl + 'assets/img/'+variables.mienv+'/logo.png'} alt={variables.appurl}/>
              </NavLink>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                  </span>
              </button>
              <div id="navbarNav" className="collapse navbar-collapse justify-content-end">
                <ul id="menu-menu-1" className="menu-menu-1">

                  <li className="menu-item">
                    <NavLink exact="true"  className={(activemnu === '/') ? 'active':'' } to="/" onClick={toggleDropdown}>{t('Inicio', { ns: 'menu' })}</NavLink>
                  </li>
                  {/* Servicios */}
                  
                  <li className="nav-item dropdown">
                    <NavLink exact="true"  className={(activemnu === '/nuestros-servicios') ? 'active':'' } to="/nuestros-servicios" onClick={toggleDropdown}>{t('Servicios', { ns: 'menu' })}</NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink exact="true"  className={(activemnu === '/como-funciona') ? 'active':'' } to="/como-funciona" onClick={toggleDropdown}>{t('Como Funciona', { ns: 'menu' })}</NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink exact="true"  className={(activemnu === '/preguntas-frecuentes') ? 'active':'' } to="/preguntas-frecuentes" onClick={toggleDropdown}> {t('Preguntas Frecuentes', { ns: 'menu' })}</NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink exact="true"  className={(activemnu === '/contacto') ? 'active':'' } to="/contacto" onClick={toggleDropdown}>{t('Contacto', { ns: 'menu' })}</NavLink>
                  </li>
                </ul>
                {!props.isAuth &&
                <div className="login_wrap">
                  {!showBtnRegistro &&
                  <NavLink exact="true"  to="/registro" className="transparent_btn" onClick={toggleDropdown}>{t('Registro', { ns: 'menu' })}</NavLink>
                  }
                  <NavLink exact="true"  to="/login" className="accent_btn" onClick={toggleDropdown}>{t('Login', { ns: 'menu' })}</NavLink>
                </div>
                }
                {props.isAuth &&
                  <div className="user_info_wrap">
                    <div className="user_info">
                        <div className="user_avatar">
                          <img src={variables.appurl + 'assets/img/avatar-unisex.png'} alt={variables.mienv}/>
                        </div>
                      <div className="user_name">
                        <div className="btn-group">
                          <button type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                  aria-expanded="false">
                            {variables.auth('username')}
                          </button>
                          {/*usersaldo && usersaldo !== 0 && <span className="header-saldo small"> ({usersaldo})</span>*/}
                          <div className="dropdown-menu dropdown-menu-right">
                            <NavLink exact="true"  className="dropdown-item" to="/dashboard" onClick={toggleDropdown}>{t('Cuenta', { ns: 'menu' })}</NavLink>
                            {appParams && Number(appParams.cesta) === 1 && <NavLink exact="true"  className="dropdown-item" to="/cesta-compra" onClick={toggleDropdown}>{t('Cesta', { ns: 'menu' })}</NavLink> }
                            <NavLink exact="true"  className="dropdown-item" to="/logout" onClick={toggleDropdown}>{t('Cerrar Sesion', { ns: 'menu' })}</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {appParams && Number(appParams.cesta) === 1 &&
                  <NavLink exact="true"  className="cart_menu ml-4 text-center d-block" to="/cesta-compra" onClick={toggleDropdown}>
                    <img src={variables.appurl + 'assets/img/icono-cesta.svg'}  alt={t('Cesta', { ns: 'menu' })+ ' ' +variables.appname} title={t('Cesta', { ns: 'menu' })+ ' ' + variables.appname} className="icono-cesta"/>
                    <span id="cart_menu_num" data-action="cart-can" className="badge rounded-circle">{getCountCesta()}</span>
                  </NavLink>
                }  
              </div>
            </nav>
          </div>
        </div>
        {/*notificacion && notificacion.length > 0 && <div className="row">
          <div className="col-12">
            <div className="alert alert-danger text-center p-1" role="alert">
              {notificacion.map((n, index) => {
                return <span key={index}>{n.texto}</span>
              })}
            </div>
          </div>
            </div>*/}
         <CustomModal
             show={modalMsg !== null}
             hideModal={() => setModalMsg(null)}
             msg={modalMsg} sizeModal={'xl'}
             target="notificacion"/>
      </div>
    </header>
  </div>);
}
export default Header;
