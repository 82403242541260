import React from 'react';
import useGetHTMLFetch from "../../server/useGetHTMLFetch";

export default function EnvioAereoPaqueteria(props) {
    const content = useGetHTMLFetch('index.php/contenidos/envioAereoPaqueteria');

    return(
        <div className="hiw">
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
     );
}

