
import React from 'react';
import { Translation } from 'react-i18next';
//(¿Quieres contratar un seguro adicional para tu envio?
class Seguro extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            initdata:null, 
            sino:null,
            respuestavalor: '', 
        }
        this._isMounted = false;
        //stepzilla
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.isValidated = this.isValidated.bind(this);
        
        //props functions
        this.idTipoPaqueteria = props.idTipoPaqueteria; 
        this.fetchApi = props.fetchApi.bind(this); //bind from props 
        this.handleToast = props.handleToast.bind(this); //bind from props 
        this.setValuesSeguro = props.setValuesSeguro.bind(this); //bind from props 
        this.getValuesSeguro = props.getValuesSeguro.bind(this); //bind from props 
    }
    componentDidMount(){
        const _self = this;
        this.fetchApi({
            endpoind:'index.php/paqueteria/seguroForm',
            body:{ id: this.idTipoPaqueteria }
          }, function(result){
                if (result && result.status ==='ok' && result.result){
                    _self.setState({isLoaded: false, initdata: result.result});
                }else{
                    _self.setState({isLoaded: true, error: result.message})
                }
                 //obtenemos valores ya informados
                 const values = _self.getValuesSeguro();
                 console.log('values',values);
                 _self.setState({respuestavalor: values, sino: (values)?'si':'no' });
                 _self._isMounted = true;
            },
        )
    }
    componentWillUnmount() {
        this._isMounted = false;
    }//componentWillUnmount

    isValidated() {
        let isDataValid = this._validateData();
        if (!isDataValid){
            let mensaje = (this.state.initdata.preguntavalor) ? `${this.state.initdata.preguntavalor} es requeridos` : 'Todos los valores son requeridos';
            if (this.state.initdata.preguntavalor)this.handleToast(mensaje,'bg-warning');
        }else{
            this.setValuesSeguro(this.state.respuestavalor);
        }
        return isDataValid;
    }
    _validateData() {
        //todos los campos son requeridos
        let valid = true;
        if (this.state.sino === 'si' && !this.state.respuestavalor) {  valid = false;  }
        return valid;
    }

   
    render() {
        const {error, isLoaded, initdata,sino,respuestavalor} = this.state;
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
       
            return(
                <Translation ns={['paqueteria']}>{(t) =>
                <>
                    <div className="step_content mt-3">
                    {initdata &&
                    <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                         {/*BANNER*/}
                         <div className={"d-flex align-items-end banner-header dimensiones" }>
                            <div className="title">{t(initdata.concepto)} <span>{t(initdata.nombre)}</span></div> 
                        </div>    
                        <div className="first_step_content_item_wrap d-block p-3">

                            
                            {/**pregunta SINO */}
                            <div className='row'> 
                                <div className='col-12 mt-5'><h6>{t(initdata.preguntasino)}</h6></div>
                                <div className='col-6'>
                                    <span 
                                        id="opcionsi"
                                        onClick={(e) => this.setState({sino:'si'})}
                                        className={sino === 'si' ? 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3 active' : 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3'}
                                        > {t('Si')}
                                    </span>
                                    <span     
                                        id="opcionno"
                                        onClick={(e) => this.setState({sino:'no'})}
                                        className={sino === 'no' ? 'btn-sino btn  btn-sm mr-2 mt-2 pl-3 pr-3 active' : 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3'}
                                        > {t('No')}
                                    </span> 
                                </div>
                            
                            </div>
                            {/**pregunta SI */}
                            {sino == 'si' && initdata.preguntavalor && <div className='row mt-5'> 
                                    <div className='col-12'>
                                        <label className='label'>{t(initdata.preguntavalor )}<span className="required"> (*)</span></label>
                                        <input type="number" id="respuestavalor" className="form-control"
                                            onChange={(e) => this.setState({respuestavalor:e.target.value})}
                                            value={respuestavalor}
                                            autoFocus
                                            required
                                        />
                                    </div>
                            </div>}

                            {initdata.descripcion && <div className='mt-5 p-5 bg-light mb-5'>
                                <div className='row'>
                                    {sino == 'si' && <div className='col-12 mb-3'><p dangerouslySetInnerHTML={{__html: initdata.info_si}} /></div>}
                                    {sino == 'no' && <div className='col-12 mb-3'><p dangerouslySetInnerHTML={{__html: initdata.info_no}} /></div>}
                                    <div className='col-12'><p className="text-muted" dangerouslySetInnerHTML={{__html: initdata.descripcion}} /></div>
                                </div>
                            </div>}   
                        </div>
                    </div>
                    }
                    </div>    
                </>
                }
                </Translation>
            );
        }  
  }
}

export default Seguro;
