import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Translation } from 'react-i18next';
import variables from "../../variables/env.js";
import CustomModal from "../common/CustomModal";

class MetodosPago extends Component {
    constructor(props){
        super(props);
        this.state = {
            data:null,
            value:null,
            cantidarEnviar : props.cantidarEnviar,
            tipoPago : props.tipoPago || 'cesta',
            divClassName: (props.tipoPago === 'remesa' || props.tipoPago === 'paqueteria') ? 'step_content_item third_step_content_item step_content_item_active' : 'table_row_merge',
            metodoSeleccionado: props.getMetodoPagoSeleccionado() || null,
            modalData:[]
        }
        this.metodosPagosDisponibles = variables.auth('metodosPagosDisponibles');
        this.metodosPagosApp = variables.auth('metodosPagosApp');
        this.tokenAccess = props.tokenAccess;
        this.loggedIn = props.loggedIn;
        this.getCantidadEnviar = props.getCantidadEnviar.bind(this);
        this.setMetodoPago = props.setMetodoPago.bind(this);
        this.getMetodoPagoSeleccionado = props.getMetodoPagoSeleccionado.bind(this);

    }
    /*** AL INICIAR EL COMPONENTE ***/
    componentDidMount(){
        fetch(variables.apiurl+'index.php/'+this.state.tipoPago+'/metodosPago',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token:this.tokenAccess
            })
        }).then( response => response.json())
            .then(
                // handle the result
                (result) => {
                    if (result.status ==='ok'){
                        let key = 0;
                        //disable opcion saldo si no tiene suficiente o no tokenAccess
                        this.validarSaldo(result.result);
                        if (result.result.metodos.length > 0){
                            const data = result.result.metodos.map(function(metodo,index) {
                                if (!metodo.disable){
                                    key = index;
                                }
                            });
                            const rm0 = (!result.result.metodos[key].disable || result.result.metodos[key].disable === 'false') ? result.result.metodos[key] : (result.result.metodos.length > 1) ? result.result.metodos[key] : false;
                            if (rm0){
                                const metodoSeleccionado = {id:rm0.id,nombre:rm0.nombre,metodo:rm0.metodo};
                                const otros = (rm0.otros.length > 0) ? rm0.otros: false;
                                this.setMetodoPagoSeleccionado(metodoSeleccionado, otros);
                            }    
                        }else{
                          this.setMetodoPago(null);
                        }
                    }
                },
                // Handle error
                (error) => {},
            )
    }
    /*** Validar si el cliente tiene saldo ***/
    validarSaldo = (resultFetch) => {
        const cantidarEnviar = this.getCantidadEnviar();
        let indexSaldoItem = -1;
        let key = 0;
        if (resultFetch.metodos.length > 0){
          const data = resultFetch.metodos.map(function(m,index) {
              m.label = ( m.metodo === 'saldo') ? `${m.nombre} (${resultFetch.saldo})`: m.nombre;
              m.disable = ( m.metodo === 'saldo') ? (Number(resultFetch.saldo) > 0 && Number(resultFetch.saldo) >= Number(cantidarEnviar)) ? false : true : false;
              if ( m.metodo === 'saldo') indexSaldoItem = index;
              if (!m.disable){
                  key = index;
              }
              return m;
          });
          this.setState({
              data:data,
              value: (data[key].id === '30') ? (data[key].disable) ? '' : data[key].id : data[key].id,
          });
        }
    }
    
    handleOnClick = event => {
        let target = event.target;
        if (!target.dataset.value) target = target.closest('[data-value]');
        if (target.disable){ return false; }
        const value = target.dataset.value;
        const nodeList = document.querySelectorAll('[data-value]:not(#metodo-'+value+')');
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].classList.remove('metodoSeleccionado');
        }
        target.classList.add('metodoSeleccionado');

        this.setState({value:value});
        const f = this.state.data.filter(m => Number(m.id) === Number(value));
        const metodoSeleccionado = {id:value,nombre:f[0].nombre,metodo:f[0].metodo};
        const otros = (f[0].otros.length > 0) ? f[0].otros: false;
        this.setMetodoPagoSeleccionado(metodoSeleccionado, otros);
    }
    setMetodoPagoSeleccionado = (metodoSeleccionado, otros) => {
        this.setState({ metodoSeleccionado: metodoSeleccionado });
        if (otros) {
            if (otros.length > 1){
                this.handleModal(true,'Indicar algún método de pago', otros); //cuando en el modal se seleccione, hacer el this.setMetodoPago con this.state.metodoSeleccionado + el clic
            }else{
                metodoSeleccionado.otro = otros[0];
                this.setMetodoPago(metodoSeleccionado); //setea en la funcion inicial
            }
        }else{
            this.setMetodoPago(metodoSeleccionado); //setea en la funcion inicial
        }
    }

    handleModal = (status, msg, dataRadio) =>{
        this.setState({
            modalShow:status,
            modalMsg:msg,
            modalData:dataRadio
        });
    }
    seleccionarOtros = event => {
        let metodoSeleccionado = this.state.metodoSeleccionado;
        if (event.target.value){
            const mfound = this.state.data.find(d => d.id === metodoSeleccionado.id);
            const $label = event.target.closest('label');
            const lastSpan = $label.lastChild;
            metodoSeleccionado.nombre = mfound.label + ' / ' + lastSpan.innerHTML;
            metodoSeleccionado.otro = event.target.value;
            this.setState({ metodoSeleccionado: metodoSeleccionado });
            this.setMetodoPago(metodoSeleccionado); //setea en la funcion inicial
        }
    }
    /*------------------------------------------------*/
    render() {
        const {data,divClassName,metodoSeleccionado} = this.state;
        document.querySelector('#next-button').style.display = 'block';
        return(
            <>
            <Translation ns={['metodo-pago']}>
                {
                    (t) =><>
                      { data && data.length > 0 &&
                        <div className={' p-0 mt-3 ' + divClassName}>
                          <div className="dashboard_tab_item pb-0 bg-white">
                              <h4 style={{color:'#000000'}}> {t('Selecciona tu método de pago')} </h4>

                              <div className="row">
                                { data.map((metodo, index) => {
                                    let checked = (metodoSeleccionado && metodoSeleccionado.nombre && metodo.id === metodoSeleccionado.id) ? true : false;
                                    let disable = metodo.disable;
                                    let className = "col-lg-4 col-md-6 cursor-pointer mb-4";
                                    if (disable) className += " color-muted opacity5";
                                    if (checked) className += " metodoSeleccionado";
                                    
                                    return  <div rol="button" key={index} id={`metodo-${metodo.id}`} className={className } data-value ={metodo.id} onClick={this.handleOnClick}>
                                        <div className={checked ? "bg-light box-banderas border text-center pb-2" : "box-banderas border text-center pb-2"}>
                                            <img style = {{marginTop:"0px", width:"200px", height:"200px"}} src={variables.appurl + 'assets/img/' + variables.mienv + '/metodos-pago/'+ metodo.id + '.png'} 
                                                alt={t(metodo.label)} title={t(metodo.label)} />
                                                <div>
                                                    <p className="font-weight-bold text-uppercase" style={{color:'#000000'}}>{metodo.label}</p>
                                                    {checked && <i style={{position: 'absolute', top:'1rem', right:'2rem'}} className="fa fa-check-circle text-primary fa-2x" aria-hidden="true"></i> }
                                                    <p className="small text-center text-muted">&nbsp;{metodo.etiqueta}&nbsp;</p>
                                                </div>
                                        </div>
                                    </div>
                                })}
                               </div>
                          </div>
                        </div>
                      }
                    </>
                }
            </Translation>
            <CustomModal show={this.state.modalShow}
                         hideModal={() => this.setState({modalShow : false})}
                         msg={this.state.modalMsg} target="metodos-pagos-adicionales"
                         dataRadio={this.state.modalData}
                         dataRatioOnchange ={this.seleccionarOtros}
            />
            </>
        );
    }
}

export default MetodosPago;
