import React from 'react';
import withRouter from '../../withRouter';
import { Translation } from "react-i18next";
import variables from "../../variables/env.js";

import Input from "@material-ui/core/Input";
import ImporteCuponesDigitales from "./ImporteCuponesDigitales";
import CustomToast from "../common/CustomToast";
import CustomModal from "../common/CustomModal";

//console.log(variables.lang);

class CuponesDigitales extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            importe:'',importeManual:'',
            toastMsg:null,toastShow:null,toastClass:null,
            modalMsg:null,modalShow:null,
            tarifaEuro:[],
            error : null,
            isLoaded : true,
            data:[],
            data0:[],
            active: false,
            paso: null,
            tipoServicio : null,
            cuponSeleccionado : null,
            cantidad: 1,
            enCesta: null,
            metododepago:null,
            pedirBeneficiario:false, beneficiario: {telefono:''},
            paso0 : false, styles0:null, seleccion0:null,
            cantidadRecarga : null,
            tasaActual: null,
            importeComision: null,
            imagenURL:null,
            nombreServicio:''
        };
        this.paramTipo = this.props.params.tipo;
        this.elegirCuponDigital = this.elegirCuponDigital.bind(this);

    }
    scrollUp = () =>{
      window.scrollTo(0, 0);
    }
    handleToast = (status, msg) =>{
        if (msg){
          this.setState({
              toastMsg:msg,
              toastShow:true,
              toastClass:(status==='ok')?'bg-success':'bg-danger'
          });
        }
    }
    hideParentToast = () => {
        this.setState({toastShow:false});
    }
    handleModal = (status, msg) =>{
          this.setState({
              modalMsg:msg,
              modalShow:status,
          });
    }
    componentDidMount(){
        if (!this.paramTipo){ window.location.href = '/nuestros-servicios';
        }else{
          this.loggedIn = variables.auth();
          this.tokenAccess = variables.auth('token');
          const tipoServicio = this.getTipoServicio();
          this.handleCestaCookie(); //obtenemos cestaCookieValue si lo hay
          let isMounted = true;               // note mutable flag
          fetch(variables.apiurl+'index.php/servicios/cuponesDigitales',{
              method: 'post',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  lang:variables.lang,
                  token : this.tokenAccess,
                  data:{
                      tipo_servicio : tipoServicio,
                  }
              })
          }).then( response => response.json())
            .then(
                (result) => {
                    if (result.status ==='ok'){
                        if (isMounted)  {
                          this.setState({ isLoaded:false});
                          if (this.state.paso0){
                            this.setState({data0 : result.result, paso : 0});
                          }else{
                            this.setState({data : result.result, paso : 1});
                          }
                        }
                    }else{
                        if (isMounted)  this.setState({isLoaded:false,  error: result.message });
                    }
                    isMounted = false;
                    this.scrollUp();
                },
            )
          }
    }
    /**************** Obtener el tipo de servicios ******/
    getTipoServicio = () => {
        let tipoServicio = null;
        const baseImagenURL = variables.appurl+'/assets/img/';
        let imagenURL = {
          base: baseImagenURL,
          cupon: baseImagenURL + '/cupones/'
        }
        switch (this.paramTipo) {
            case 'contenidos-digitales':
              tipoServicio = 7;
              this.setState({ nombreServicio: 'Cupón Digital'});
              break;
            case 'recargas-telefonicas':
              tipoServicio = 6;
              imagenURL.paso0 = imagenURL.cupon + 'banderas/';
              this.setState({ pedirBeneficiario: true, paso0 : true , nombreServicio: 'Recarga Telefónica'});
              break;
            case 'medio-pagos':
                tipoServicio = 8;
                this.setState({ nombreServicio: 'Medio de Pago'});
                break;
            case 'tarjeta-regalo':
                tipoServicio = 9;
                imagenURL.cupon += 'gif-card/';
                this.setState({ nombreServicio: 'Tarjeta Regalo'});
                break;
            default:
              window.location.href = '/nuestros-servicios';
        }
        this.setState({ tipoServicio: tipoServicio, imagenURL: imagenURL});

       return tipoServicio;
    }

    /**************** Control de step **********/
    pasoAnterior = () => {
        const paso = Number(this.state.paso);
        this.setState({paso:paso - 1});
        this.scrollUp();
    }
    /**************** Paso elegir Paises **********/
    elegirPaso0 = (event, data, key) => {
        const seleccion = data.cd;
        this.setState({
              data : seleccion.data,
              seleccion0 : seleccion,
              styles0 : {
                backgroundImage : 'url('+variables.appurl + '/assets/img/' + seleccion.logo + ')'
              },
              paso: 1
        });
        this.scrollUp();
    }
    /**************** Paso elegir Cupones **********/
    elegirCuponDigital = (event, data, key) => {
        const cuponDigital = data.cd;
        this.setState({ active: key,
              cuponSeleccionado:cuponDigital,
              paso: 2,
              importe: null,
              siglasMoneda : null,
              metododepago: null
        });
        this.scrollUp();
    }
    /**************** Paso elegir Importe (ImporteCuponesDigitales.js) **********/
    setImporte = (data) => {
        //console.log('setImporte', data);
        this.setState({
            importeManual:Number(data.importeManual) || null,
            importe:Number(data.importe),
            siglasMoneda:data.moneda_origen,
            siglasMonedaDestino:data.monedas_destino,
            idMonedaDestino:data.id_monedas_destino,
            idMonedaOrigen:data.id_moneda_origen,
            cantidadRecarga: data.cantida_recargar,
            tasaActual: data.tasa_actual,
            importeAPagarRecargaTelefonica: data.importeAPagarRecargaTelefonica,
            tipoServicio: data.tipo_servicio,
            importeComision: data.importe_comision
        });
    }
    /**************** Paso elegir Importe (ImporteCuponesDigitales.js) **********/
    setCantidad = (cantidad) => {
        this.setState({cantidad: cantidad});
    }
    /************ Datos del beneficiario **********/
    handleChangeBeneficiario = e => {
      const value = (e.target.name === 'telefono') ? event.target.value.replace(/\D/g, '') : event.target.value; //solo numeros
      let dataBeneficiario = this.state.beneficiario;
      dataBeneficiario[e.target.name] =  value;
      this.setState({beneficiario : dataBeneficiario});
    };
    /************ Se ejecuta al guardar cesta y al iniciar el componente **********/
    handleCestaCookie = (cookieValue) => {
      variables.setIdCesta(cookieValue);
    }
    /************** validación previa al hacer click en el botón "agregar a la cesta" ********************/
    onClickCesta = () =>{
      if (this.state.pedirBeneficiario  && (this.state.beneficiario === {} ||  !this.state.beneficiario.telefono)){
          let msg = 'Por favor informa el número Telefónico.';
          this.handleToast('ko', msg);
      }else{
        const r = this.validaImporteManual();
        if (r=== true){
          this.sendDataCesta();
        }else{
          this.setState({importe:''});
        }
      }
    }
    validaImporteManual = () => {
      const c = this.state.cuponSeleccionado;
      const v = Number(this.state.importeManual);
      const min = Number(c.importe_minimo);
      const max = Number(c.importe_maximo);
      const interval = Number(c.importe_multiplo);
      let r = false;
      if (v && min && max && interval){
        /* validamos */
        if (v < min ){
          this.handleToast('ko', 'Importe mínimo permitido'  +' '+ min);
          r = false;
        }else if (v > max ){
          this.handleToast('ko', 'Importe máximo permitido'  +' '+ max);
          r = false;
        }else{
          /* check interval */
          const importeConDecimales = Number.isInteger(v);
          const intervalConDecimales = Number.isInteger(Number(interval));
          if (!importeConDecimales && !intervalConDecimales) {
            const decimalesImporte = v.toString().split('.')[1];
            const decimalesInterval = interval.toString().split('.')[1];
            if (decimalesImporte.length > decimalesInterval.length){
              this.handleToast('ko', 'Importe no permitido');
              r = false;
            }else{
              r = true;
            }
          }else{
            r = true;
          }
        }
      }else{
        r = true;
      }

      return r;

    }

    /***********  Enviamos Data al servidor  ***************/
    sendDataCesta = () => {
        this.setState({isLoaded: true});
        let data = {
            idProducto: this.state.cuponSeleccionado.id,
            importeProducto: this.state.importe,
            siglasMonedaOrigen : this.state.siglasMoneda,
            siglasMonedaDestino : this.state.siglasMonedaDestino,
            idMonedaOrigen : this.state.idMonedaOrigen,
            idMonedaDestino : this.state.idMonedaDestino,
            nombreProducto : this.state.cuponSeleccionado.nombre,
            cantidadProducto : this.state.cantidad,
            tipoServicio : this.state.tipoServicio,
            beneficiario : this.state.beneficiario,
            idCesta : variables.getIdCesta(),
            tasaActual : this.state.tasaActual,
            importeComision: this.state.importeComision,
        };
        if (Number(this.state.importeManual) > 0) {
            data.importeProducto = this.state.importeManual;
        }
        if (Number(this.state.tipoServicio) === 6) {
            data.importeProducto = this.state.importeAPagarRecargaTelefonica;
        }
       // console.log('sendDataCesta '+this.state.tipoServicio,data);
        fetch(variables.apiurl +'index.php/cesta/actionGuardaCesta',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token:this.tokenAccess,
                data:data
            })
        })
            .then( response => response.json())
            .then(
                // handle the result
                (result) => {
                    //this.cestaCookie = result.result.idCestaActiva;
                    this.handleCestaCookie(result.result.idCestaActiva);
                    if (result.status === 'ko') {
                        this.handleToast(result.status, result.message);
                    }
                    this.handleModal(true,result.message);

                    this.setState({isLoaded: false});
                    variables.setCountCesta(result.result.count);
                },
                // Handle error
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                },
            );

    }

    /*-----RENDER-----*/
    render() {
        const {isLoaded, error, tipoServicio, paso0, data0, seleccion0, styles0, data, paso, cuponSeleccionado, cantidad, importe, cantidadRecarga, imagenURL,nombreServicio} = this.state;
        return(
            <>
            <CustomToast msg={this.state.toastMsg} show={this.state.toastShow} tclass={this.state.toastClass} hideParentToast={() => (this.hideParentToast())}/>
            <CustomModal show={this.state.modalShow} hideModal={() => this.setState({modalShow : false})} msg={this.state.modalMsg} target="cesta"/>
            <Translation ns={['cupones-digitales']}>
                {(t) =>
                        <>
                            <div className="container mb-5 container-height-low">
                                {isLoaded && <div className="loading CuponesDigitales">Loading</div>}
                                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                                <div className="row mt-4 mb-2">
                                    {/* titulos */}
                                    <div className="col-12 mb-4">
                                            <div className="order-md-2 order-1 text-center">
                                                { tipoServicio === 7 && paso === 1 && <h4>{t('Elige tu Cupón Digital')}</h4>}
                                                { tipoServicio === 8 && paso === 1 && <h4>{t('Elige un Medio de Pago')}</h4>}
                                                { tipoServicio === 9 && paso === 1 && <h4>{t('Elige tu Tarjeta Regalo')}</h4>}

                                                { tipoServicio === 6 && <>
                                                    {paso === 0 && <>
                                                        <h4>{t('Elige el país para hacer tus recargas telefónicas')}</h4>
                                                        <p className="text-secondary">
                                                            {t('Desde estos países puedes elegir la opción que desees y tendrás tu recarga móvil', { ns: 'cupones-digitales' })}
                                                        </p>
                                                    </>}
                                                    {paso === 1 && <>
                                                        <h4>Recargas {seleccion0.nombre}</h4>
                                                        <p className="text-secondary">
                                                            {t('Puedes elegir la opción que desees y tendrás tu recarga móvil casi al instante', { ns: 'cupones-digitales' })}
                                                        </p>
                                                    </>}
                                                </>}

                                            </div>
                                    </div>

                                        {(paso!==2 && ((!paso0 && paso === 1) || (paso0 && paso === 0))) &&
                                            <a className="btn btn-secondary btn-md btn-back"
                                               href="/nuestros-servicios"><i className="fa fa-arrow-left"
                                                                             aria-hidden="true"></i> {t('Volver', { ns: 'cupones-digitales' })}
                                            </a>
                                        }
                                        {(paso !==2 && ((!paso0 && paso > 1) || (paso0 && paso > 0))) &&
                                            <button type={"button"} onClick={this.pasoAnterior} className='btn btn-secondary btn-md btn-back'>
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i> {t('Volver', { ns: 'cupones-digitales' })}</button>
                                        }

                                </div>
                                {isLoaded && <div className="loading CuponesDigitales">Loading</div>}
                                {paso === 0 &&
                                <div className="row">
                                        { data0.map((cd, index) => {
                                            return  <div key={index} id={`paso0-${cd.id}`} className="col-lg-4 col-md-6 cursor-pointer mb-4">
                                                <div className="box-banderas d-flex">
                                                    {cd.logo && <img  src={imagenURL.paso0 + cd.logo}
                                                         alt={cd.nombre}/>}
                                                    <figcaption className="info-wrap">
                                                        { tipoServicio === 6 && <span className="small text-secondary">{t('Redes móviles', { ns: 'cupones-digitales' })}</span> }
                                                        <p className="font-weight-bold mt-2 d-block">{cd.nombre}</p>
                                                        { tipoServicio === 6 && <span className="mt-3 small text-secondary d-block pr-3">{t('Envío instantáneo Canjeable en', { ns: 'cupones-digitales' })} {cd.nombre}</span> }
                                                        <button type="button" className="btn btn-outline-default btn-sm d-block mt-2 mb-3 pl-3 pr-3"
                                                          data-id-cupones-digitales={cd.id}
                                                          onClick={(e) => this.elegirPaso0(e, {cd}, index)}
                                                          key={index}>
                                                          { tipoServicio === 6 && <>{t('Recargar', { ns: 'cupones-digitales' })}</>}</button>
                                                    </figcaption>
                                                </div>
                                            </div>
                                        })}
                                    {tipoServicio === 6 &&
                                        <div className="col-12">
                                            <a className="btn btn-secondary btn-md" href="/nuestros-servicios"><i className="fa fa-arrow-left" aria-hidden="true"></i>{t('volver a servicios', { ns: 'cupones-digitales' })}</a>
                                        </div>
                                    }
                                </div>
                                }
                                {paso === 1 &&
                                <div className="row">
                                        { data.map((cd, index) => {
                                            return  <div key={index} id={`cupones-digitales-${cd.id}`} className="col-lg-3 col-md-6 cursor-pointer mb-4">
                                                { tipoServicio === 6 &&
                                                <div className="card card-product-grid pt-4 p-2 text-center b-2">
                                                    {/*<div className="roundedicon-banderas" style={styles0}></div>*/}
                                                    {cd.logo && <img className="border-radius-10"  src={imagenURL.cupon + cd.logo} alt={cd.nombre}/>}
                                                    <figcaption className="info-wrap mt-3 mb-3">
                                                        <h5 className="font-weight-bold mb-0">{cd.nombre}</h5>
                                                        <span className="font-weight-bold text-small">{t('Prepago', { ns: 'cupones-digitales' })}</span>
                                                        <span className="small text-secondary d-block">{t('Canjeable en España', { ns: 'cupones-digitales' })}</span>
                                                        <button type="button" className="btn btn-outline-default btn-sm mt-2"
                                                                data-id-cupones-digitales={cd.id}
                                                                onClick={(e) => this.elegirCuponDigital(e, {cd}, index)}
                                                                key={index}>{t('Recargar', { ns: 'cupones-digitales' })}</button>
                                                    </figcaption>
                                                </div>
                                                }
                                                { tipoServicio !== 6 &&
                                                <div className="box-banderas d-flex">
                                                  <div className="roundedicon-banderas" style={styles0}></div>
                                                    {cd.logo && <img className="border-radius-10"  src={imagenURL.cupon + cd.logo}
                                                         alt={cd.nombre}/>}
                                                    <figcaption className="info-wrap">
                                                        { tipoServicio === 7 && <span className="small text-secondary">{t('Contenido Digital', { ns: 'cupones-digitales' })} </span> }
                                                        <p className="font-weight-bold mt-2 d-block">{cd.nombre}</p>
                                                        <span className="mt-3 small text-secondary d-block pr-3">{t('Envío instantáneo Canjeable en España', { ns: 'cupones-digitales' })} </span>
                                                        <button type="button" className="btn btn-outline-default btn-sm d-block mt-2 mb-3 pl-3 pr-3"
                                                          data-id-cupones-digitales={cd.id}
                                                          onClick={(e) => this.elegirCuponDigital(e, {cd}, index)}
                                                          key={index}>{t('Comprar', { ns: 'cupones-digitales' })}</button>
                                                    </figcaption>
                                                    <div className="mt-4 mb-4"></div>
                                                </div>
                                                }
                                            </div>
                                        })}
                                    { tipoServicio === 6 &&
                                        <div className="col-12">
                                            <button type={"button"} onClick={this.pasoAnterior} className='btn btn-secondary btn-md'><i className="fa fa-arrow-left" aria-hidden="true"></i> {t('Volver', { ns: 'cupones-digitales' })}</button>
                                        </div>
                                    }
                                    {paso === 1 && tipoServicio !== 6 && data.length > 6 &&
                                        <div className="col-12">
                                            <a className="btn btn-secondary btn-md" href="/nuestros-servicios"><i className="fa fa-arrow-left" aria-hidden="true"></i> {t('Volver', { ns: 'cupones-digitales' })}</a>
                                        </div>
                                    }
                                </div>
                                }
                                {paso === 2 &&
                                <>
                                    {cuponSeleccionado &&
                                            <div className="row">
                                                <div className="col-lg-6 col-md-4 col-12">
                                                  <div className="text-left">
                                                      <h2 className="mb-0">
                                                        {t(nombreServicio)}: <span className="color-default text-uppercase">{cuponSeleccionado.nombre} </span>
                                                      </h2>
                                                      <span className="text-secondary text-small">{t('Envío inmediato del código por e-mail', { ns: 'cupones-digitales' })} <img src={imagenURL.base+'icono-check.png'} alt={variables.sitename}/></span>
                                                      <p><img src={imagenURL.base + 'estrella-calificacion.png'} alt={'star'} width={'120px'}/> <span className={"text-secondary text-small mt-1"}>5/5</span></p>
                                                  </div>
                                                    <div className="mt-2">
                                                        {tipoServicio !== 6 &&
                                                            <img src={imagenURL.cupon + cuponSeleccionado.logo} alt={cuponSeleccionado.nombre}/>
                                                        }
                                                        {tipoServicio === 6 &&
                                                            <img src={imagenURL.cupon + cuponSeleccionado.imagen} alt={cuponSeleccionado.nombre}/>
                                                        }
                                                    </div>
                                                    <div className="mt-2">
                                                          <div className="m-2 text-left mx-auto">
                                                              <img src={imagenURL.base + 'icono-check.png'} alt={variables.sitename}/>{t('Envío al e-mail', { ns: 'cupones-digitales' })}<br/>
                                                              <span className="text-secondary text-pequeno">{t('Se envía el código al correo con el cual se realiza la compra', { ns: 'cupones-digitales' })}</span>
                                                          </div>
                                                        <div  className="text-left">
                                                            <span className="text-secondary text-pequeno">{t('validez', { ns: 'cupones-digitales' })}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-8">
                                                    <div>
                                                      {/* IMPORTES */}
                                                        <ImporteCuponesDigitales
                                                            dataCupon={cuponSeleccionado}
                                                            setImporte = {this.setImporte}
                                                            setCantidad = {this.setCantidad}
                                                        />

                                                        {/*benefificario*/}
                                                        {this.state.pedirBeneficiario && <>
                                                          <h6 className="mt-3 font-weight-bold">{t('Indicar Número Telefónico', { ns: 'cupones-digitales' })}</h6>
                                                          <div className="row m-0">
                                                            <div className="col-lg-6 mt-2 pl-0">
                                                              <Input
                                                                  type="text"
                                                                  className="form-control"
                                                                  id="inputTelef"
                                                                  name="telefono"
                                                                  placeholder={t('Indicar Número Telefónico', { ns: 'cupones-digitales' })}
                                                                  onChange={this.handleChangeBeneficiario}
                                                                  value={this.state.beneficiario.telefono}
                                                                  />
                                                              <small className="form-text text-muted">{t('Indicar un Número Telefónico válido', { ns: 'cupones-digitales' })}</small>
                                                            </div>
                                                          </div>

                                                        </>}

                                                        <div className="mt-4  pl-0">
                                                            {cuponSeleccionado.descripcion && <div>
                                                                <h6 className="font-weight-bold">{t('Descripción', { ns: 'cupones-digitales' })} </h6>
                                                                <p className="mt-3 text-secondary">{cuponSeleccionado.descripcion}</p>
                                                            </div>}

                                                            <div className="row p-2 mt-5 mb-5">
                                                                <div className="col mt-2">
                                                                    <button type={"button"} onClick={this.pasoAnterior} className='btn btn-secondary btn-md'>
                                                                        <i className="fa fa-arrow-left" aria-hidden="true"></i> {t('Seleccionar otro cupón', { ns: 'cupones-digitales' })}</button>
                                                                </div>
                                                                {importe > 0 && <div className="col mt-2">
                                                                <button type={"button"}  onClick={this.onClickCesta} className='btn btn-outline-default btn-md pull-right'>{t('Añadir a la Cesta', { ns: 'cupones-digitales' })}<i
                                                                    className="fa fa-cart-arrow-down"
                                                                    aria-hidden="true"></i></button>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {cuponSeleccionado.instrucciones && <div className="col-lg-6 col-md-8">
                                                  <div className="text-secondary mt-4 pl-md-34 text-pequeno" dangerouslySetInnerHTML={{ __html: cuponSeleccionado.instrucciones }}></div>
                                                </div>}
                                            </div>
                                        }
                                </>
                                }
                                {paso === 3 &&
                                <div className="row d-flex justify-content-center">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-2">
                                        <h4 className="text-center">{this.state.mensaje}</h4>
                                        <p className="text-center">{t('Gracias por confiar en nuestros servicios.')}</p>
                                        {!this.state.isLoaded &&
                                        <div className="text-center">
                                            <button type='button' className='btn btn-outline-default mt-5' onClick={this.iniciarNuevo}> {t('Importe a recargar', { ns: 'cupones-digitales' })} {t('Iniciar Nueva Compra')}</button>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }

                                {/*paso === 4 &&
                                <div className="row d-flex justify-content-center">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-2">
                                        <h4 className="text-center z-index-1000">{t('Importe a recargar', { ns: 'cupones-digitales' })} En seguida será dirigido a la plataforma de pago seguro, por favor no cierre esta ventana ni salga de la aplicación.
                                            {this.state.isLoaded && <div className="loader" id="loader">Loading...</div>}
                                        </h4>
                                    </div>
                                </div>
                              */  }
                            </div>
                        </>
                }
            </Translation>
              </>
        );
    }
}
export default withRouter(CuponesDigitales);
