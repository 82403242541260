import React from "react";
import 'moment/locale/es';

import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LanguageSelector from '../../language/LanguageSelector';
import variables from "../../variables/env";

function Footer() {
    const footerYear = new Date().getFullYear();
    const publicURL = process.env.REACT_APP_URL;
    const miENV = process.env.REACT_APP_MIENV;
    const { t, i18n } = useTranslation(['translation', 'menu']);
    var appInfo = {};
    try {
      appInfo = JSON.parse(process.env.REACT_APP_COMPANYINFO);
    } catch (e) {
      console.error(e);
    }
  return (
      <footer>
          <div className='container'>
              <div className='row text-white'>
                  <div className='col-lg-3 col-md-3 col-sm-12 col-12 col-12'>
                      <div className="footer_logo">
                        <NavLink exact="true"  to="/">
                            <img src={publicURL + 'assets/img/'+miENV+'/logo-white.png'} alt={publicURL}/>
                        </NavLink>
                      </div>
                      <div className="footer_social">
                          {appInfo.facebook &&
                          <a target="_blank" href={appInfo.facebook}>
                              <img src={publicURL + 'assets/img/facebook.svg'} alt="facebook"/>
                          </a>}
                          {appInfo.instagram &&
                          <a target="_blank" href={appInfo.instagram}>
                              <img src={publicURL + 'assets/img/instagram.svg'} alt="instagram"/>
                          </a>}
                          {appInfo.twitter &&
                          <a target="_blank" href={appInfo.twitter}>
                              <img src={publicURL + 'assets/img/twitter.svg'} alt="twitter"/>
                          </a>}
                        
                          {appInfo.telegram &&
                          <a href={appInfo.telegram}>
                              <img src={publicURL + 'assets/img/telegram.svg'} alt="telegram"/>
                          </a>}
                      </div>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-4 col-12 col-12 offset-lg-1">
                      <div className="footer_nav">
                          <a href="#"> {t('Nombre', { ns: 'menu' })}</a>
                          <ul>
                              <li>
                                <NavLink exact="true"  to="/">
                                      {t('Inicio', { ns: 'menu' })}
                                  </NavLink>
                              </li>
                              {miENV !== 'wws' &&
                              <li>
                                  <NavLink exact="true"  to="/nuestros-servicios">
                                      {t('Servicios', { ns: 'menu' })}
                                  </NavLink>
                              </li>}
                              <li>
                                  <NavLink exact="true"  to="/contacto">
                                      {t('Contacto', { ns: 'menu' })}
                                  </NavLink>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-4 col-12 col-12 offset-lg-1">
                      <div className="footer_nav">
                          <a href="#"> {t('Ayuda', { ns: 'menu' })} </a>
                          <ul>
                              <li>
                                  <NavLink exact="true"  to="/como-funciona">
                                      {t('Como Funciona', { ns: 'menu' })}
                                  </NavLink>
                              </li>
                              <li>
                                  <NavLink exact="true"  to="/preguntas-frecuentes">
                                      {t('Preguntas Frecuentes', { ns: 'menu' })}
                                  </NavLink>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-4 col-12 col-12 offset-lg-1">
                      <div className="footer_nav">
                          <LanguageSelector/>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12">
                      <div className="footer_copiright text-center">
                          <p>
                              © 2018-{footerYear} {t('Dominio', { ns: 'menu' })} {t('copyright', { ns: 'translation' })}
                          </p>
                          <nav className="small">
                              <NavLink exact="true"  className="text-light" to="/terminosycondiciones">
                                  {t('Terminos Condiciones', { ns: 'menu' })}
                              </NavLink>&nbsp;|&nbsp;
                              <NavLink exact="true"  className="text-light" to="/politicasdeprivacidad">
                                  {t('Políticas de Privacidad', { ns: 'menu' })}
                              </NavLink>&nbsp;|&nbsp;
                              <NavLink exact="true"  className="text-light" to="/avisolegal">
                                  {t('Aviso Legal', { ns: 'menu' })}
                              </NavLink>
                          </nav>
                          <div className="footer_cards">
                              <img src={publicURL + 'assets/img/visa.svg'} alt={miENV}/>
                              <img src={publicURL + 'assets/img/mastercard.svg'} alt={miENV}/>
                              <img src={publicURL + 'assets/img/logo-pci.png'} alt={miENV}/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/* whatsapp Button */}
          <a href={'https://api.whatsapp.com/send?phone='+appInfo.whatsapp} className="btn-float" target="_blank">
            <i className="fa fa-whatsapp my-float"></i>
          </a>
      </footer>
  );
}

export default Footer;
