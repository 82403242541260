/*
Gestión de datos y cuentas
*/
import React from 'react';
import withRouter from '../../withRouter';
import { Link } from 'react-router-dom';
import PrivateArea from '../cliente/PrivateArea';
import SendFormData from "../../server/sendFormData";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../assets/css/form.css';

import BeneficiarioFormData from './BeneficiarioFormData';
import BeneficiarioFormCuenta from './BeneficiarioFormCuenta';
import BeneficiarioFormCuentaPopup from './BeneficiarioFormCuentaPopup';

import CustomToast from '../common/CustomToast';

import AlertDialog from '../common/AlertDialog';
import { Translation } from 'react-i18next';

import variables from "../../variables/env.js";

class BeneficiarioManage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            data: [],
            dataCuentas: [],
            showModalNuevaCuenta:false,
            toast: { msg:null, show:false, tclass:null },
            idCuentaParaEliminar:null,isOpenDialogConfirmarEliminarCuenta:false,
            popupNuevaCuentaIsOpen:false
        };
        this.id_beneficiario = this.props.params.id;
        this.tokenAccess = variables.auth('token');
        this.servicioRemesaActivo = 1;
    }
    handleToast = (status, msg) =>{
        if (msg){
            this.setState({
                toast: { msg:msg, show:true, tclass:(status==='ok')?'success':'danger' }
            });
        }
    }
    guardarBeneficiario = (result) => {
        let d = this.state.data;
        Object.keys(d.result.schema.properties).map((key) => {
            if (result[key]) {
                d.result.schema.properties[key].default = result[key];
            }
            return key;
        });
        this.setState({data:d});

    }
    onSubmitCuenta = ({formData}, e) => {
        SendFormData(formData, 'index.php/beneficiario/guardaCuentaBancaria')
        .then((resp) => {
            this.handleToast(resp.status,resp.message);
        });
    }
    openModalNuevaCuenta = ({formData}, e) => {
        this.setState({showModalNuevaCuenta:true});
    }
    closeModalNuevaCuenta = () => {
        this.setState({showModalNuevaCuenta:false});
    }
    guardarNuevaCuenta = (resp) => {
       console.log('guardarNuevaCuenta',resp);
       console.log('guardarNuevaCuenta',this.state.dataCuentas);
        let d = this.state.dataCuentas;
        d.result.push(resp);
        this.setState({dataCuentas:d});

    }
    guardarCuenta = (resp) => {
        let d = this.state.dataCuentas;
        Object.keys(resp).map((k) => {
            d.result.map((c) => {
                if (c.schema.properties.id_cuenta.default === resp.id_cuenta && c.schema.properties[k]){
                    c.schema.properties[k].default = resp[k]
                }
                return c;
            });
            return k;
        });
        this.setState({dataCuentas:d});

    }
    /* elimina cuenta con confirmacion previa */
    eliminarCuenta = (id_cuenta) => {
        this.setState({
            idCuentaParaEliminar:id_cuenta,
            isOpenDialogConfirmarEliminarCuenta:true
        });
    }
    handleResponseConfirmaEliminar = (r) => {
        if (r) { this.eliminarCuentaConfirmada(this.state.idCuentaParaEliminar); }
        this.setState({
            idCuentaParaEliminar:null,
            isOpenDialogConfirmarEliminarCuenta:false
        });
    }
    eliminarCuentaConfirmada = (id_cuenta) => {
        SendFormData({id_cuenta:id_cuenta}, 'index.php/beneficiario/eliminaCuentaBeneficiario')
        .then((resp) => {
            this.handleToast(resp.status,resp.message);
            if (resp.status === 'ok'){
                let d = this.state.dataCuentas;
                d.result =  d.result.filter(cuenta => { return Number(id_cuenta) !== Number(cuenta.schema.properties.id_cuenta.default) });
                this.setState({dataCuentas:d});
            }
        });
    };

    componentDidMount(){
        let dataPost = {id_beneficiario: this.id_beneficiario};
        fetch(variables.apiurl+'index.php/beneficiario/obtenerDatosPersonalesForm',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess, data:dataPost, lang:variables.lang})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        data : result
                    });
                    this.obtenerCuentasDestino(variables.apiurl,dataPost)
                }else{
                    this.setState({
                        error: result.message
                    });
                    if (result.action==='login'){
                          window.location.href = '/login';
                    }
                }
            },
            // Handle error
            (error) => {
                this.setState({
                    error
                })
            },
        )
    }
    obtenerCuentasDestino(apiURL,dataPost){
      fetch(apiURL+'index.php/beneficiario/obtenerCuentasDestinoForm',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: this.tokenAccess, data:dataPost})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){ //console.log(result.result);
                    this.setState({
                        dataCuentas : result
                    });
                     //en caso de no tener cuentas, abre el popup con el formulario directamente
                    if (result.result.length === 0){
                        this.setState({popupNuevaCuentaIsOpen:true});
                    }
                }
            },
            (error) => {
                this.setState({
                    error
                })
            },
        )
    }

    render() {
        const {data,dataCuentas} = this.state;
        return (
<Translation ns={['beneficiarios']}>
{
  (t) =><PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper beneficiaries_wrap" tabClass="beneficiaries-tab" activeLink="beneficiarios" servicioRemesaActivo={this.servicioRemesaActivo}>
            <CustomToast msg={this.state.toast.msg} show={this.state.toast.show} tclass={this.state.toast.tclass}/>

            <div className="beneficiaries_title_wrap">
              <div className="dashboard_tab_item_title w-100 mb-0">
                  <h3>{t('Mis Beneficiarios')}</h3>
                  <Link to="/Beneficiarios">{t('ver todo')}</Link>
              </div>
            </div>

            <div className="beneficiaries_title_wrap mt-3 pl-0 pr-0">

              {!data && <div className="loading"><div className="spinner-border text-muted"></div></div>}
              {/* si KO */}
                {data && data.status==='ko' &&
                  <div className="col-12">
                      <div className="card card-inverse">
                        <div className="card-block bg-danger p-3">
                            {<h6>data.message</h6>}
                        </div>
                    </div>
                </div>
                }
                {/* si OK */}
                { data && data.status==='ok' && data.result.schema && <div className="col-12">
                <Tabs>
                  <TabList>
                    <Tab className="transparent_btn react-tab">{t('Datos Personales')}</Tab>
                    <Tab className="transparent_btn react-tab
                    ">{t('Cuentas Bancarias')}</Tab>
                  </TabList>

                  <TabPanel>
                    {/* BeneficiarioFormData */}
                    <BeneficiarioFormData
                      tokenAccess = {this.tokenAccess}
                      data = {data}
                      handleToast={(status, msg) => (this.handleToast(status, msg))}
                      guardarBeneficiario={(result) => (this.guardarBeneficiario(result))}
                      schemaForm={true}
                    />
                  </TabPanel>
                  <TabPanel>
                      <div className="col-12 text-right pb-2">

                          {/* nueva cuenta form */}
                              <BeneficiarioFormCuentaPopup
                                  tokenAccess = {this.tokenAccess}
                                  buttonClassName="btn-sm back_btn transparent_btn transparent_btn_back"
                                  buttonContent={t('Nueva Cuenta')}
                                  nuevaCuenta={true}
                                  guardarCuenta={(c) => (this.guardarNuevaCuenta(c))}
                                  id_beneficiario ={(this.id_beneficiario)}
                                  handleToast={(status, msg) => (this.handleToast(status, msg))}
                                  isOpen={this.state.popupNuevaCuentaIsOpen}
                                  schemaForm={true}
                              />
                        </div>
                        {/* dialog comfirmación para eliminar */}
                        <AlertDialog
                            isOpen={this.state.isOpenDialogConfirmarEliminarCuenta}
                            handleResponse={(r)=>(this.handleResponseConfirmaEliminar(r))}
                            message={t('admin.¿Está seguro de querer eliminar esta cuenta?')}
                        />
                        {/* cuentas form lista */}
                        <div>
                            { dataCuentas && dataCuentas.status==='ok' && dataCuentas.result && dataCuentas.result.map((cuenta, index) => {
                                return  <div key={index} className="bg-light p-3">
                                <BeneficiarioFormCuenta
                                  tokenAccess = {this.tokenAccess}
                                  data = {cuenta}
                                  guardarCuenta={(c) => (this.guardarCuenta(c))}
                                  handleToast={(status, msg) => (this.handleToast(status, msg))}
                                  formData={{}}
                                  eliminarCuenta={(c) => (this.eliminarCuenta(c))}
                                />

                                {/*<a onClick={() => {
                                  this.eliminarCuenta(cuenta.schema.properties.id_cuenta.default);
                                }}
                                */}

                                </div>
                                })
                            }
                        </div>
                  </TabPanel>
                </Tabs>
                </div>

                }

            </div>

        </PrivateArea>
}
</Translation>
        );
  }
}

export default withRouter(BeneficiarioManage);
