import i18n from 'i18next';
const defaultLang = process.env.REACT_APP_LANG;
function getLanguage() {
  const l = i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    defaultLang
  return l;
};

export default getLanguage;
