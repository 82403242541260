import React, { Component } from 'react';
import {MdInfoOutline} from 'react-icons/md';
import { Translation } from 'react-i18next';
import variables from "../../variables/env";
import {NavLink} from "react-router-dom";

class RemesaConfirmacion extends Component {
    constructor(props){
        super(props);
        this.state = {
            data:null,
            error:null
        }
        this.tokenAccess = props.tokenAccess;
        this.getRemesaDataFinal = props.getRemesaDataFinal.bind(this);
        this.setDataFinalConfirmada = props.setDataFinalConfirmada.bind(this);

    }
    /*-- AL INICIAR EL COMPONENTE --*/
    componentDidMount(){
        const apiURL = process.env.REACT_APP_API_URL;
        const dataFinal = this.getRemesaDataFinal();
        fetch(apiURL+'index.php/remesa/confirmar',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token:this.tokenAccess,
                data:dataFinal
            })
        }).then( response => response.json())
            .then(
                // handle the result
                (result) => {
                    if (result.status ==='ok'){
                        //  console.dir(result.result);
                        this.setState({data:result.result});
                        this.setDataFinalConfirmada(result.result);
                        document.querySelector('#next-button').style.display = 'block';
                    }else{
                        this.setState({data:null});
                        this.setState({error:result.message});
                        document.querySelector('#next-button').style.display = 'none';
                    }
                },
                // Handle error
                (error) => {},
            )
    }
    /*------------------------------------------------*/
    render() {
        const {data, error} = this.state;
        if (error){
            return (
                <Translation ns={['remesas']}>{(t) =>
                <div className="container">
                <div className="profile_wrap">
                    <div className="result_header">
                        <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.appnamename} /></a>  </div>
                    </div>
                    <div className="result_body">
                        <p className="lead font-weight-bold"><i className="fa fa-exclamation text-danger font-weight-600" aria-hidden="true"></i>{t(error)}  </p>
                        <div className="orange_divider"></div>
                    </div>
                    <div className="result_footer ">
                        <div className="polygon_item"></div>
                    </div>
                </div>
            </div>
                }
                </Translation>);
        }else{
            return(
                <Translation ns={['remesas']}>
                    {
                        (t) =><div className="container">
                            <h4 className="pt-3">{t('Estás a punto de completar los pasos para enviar tu remesa')}</h4>
                            <p className="small"><MdInfoOutline className="text-warning"/> {t('Por favor confirma la información suministrada antes de continuar')} </p>

                            { data &&
                            <div className="remesa-confirmacion card mt-3 pt-3 pr-3 pl-3 pb-0">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="row border-bottom">
                                            <div className="col-lg-6">
                                                <h5>{t('Nombre del Beneficiario')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.beneficiario.nombre}</p>
                                            </div>
                                        </div>
                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Banco')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.beneficiario.banco}</p>
                                            </div>
                                        </div>
                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Tipo de Cuenta')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.beneficiario.tipo_de_cuenta}</p>
                                            </div>
                                        </div>
                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Nro. de Cuenta')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.beneficiario.n_cuenta}</p>
                                            </div>
                                        </div>
                                        {data.numero_oficina &&
                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Nro. de Oficina')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.transferencia.numero_oficina}</p>
                                            </div>
                                        </div>
                                        }
                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Cantidad a Enviar')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.transferencia.cantidad_enviar} {data.moneda_origen.moneda}</p>
                                            </div>
                                        </div>

                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Cantidad a Recibir')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.transferencia.cantidad_recibir} {data.moneda_destino.moneda}</p>
                                            </div>
                                        </div>
                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Tasa actual')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>1.00 {data.moneda_origen.moneda} =
                                                    {data.transferencia.tarifa_anterior && <del className="text-danger pl-1 pr-2">{data.transferencia.tarifa_anterior} {data.moneda_destino.moneda}</del>}
                                                    {data.transferencia.tarifa_actual} {data.moneda_destino.moneda}</p>
                                            </div>
                                        </div>
                                        <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Comisión')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.transferencia.comision} {data.moneda_origen.moneda}</p>
                                            </div>
                                        </div>
                                        {data.transferencia.codigo && <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('codigo label')}</h5>
                                            </div>

                                            {data.transferencia.codigoerror && <div className="col-lg-6 text-lg-right text-danger">
                                                <p>{data.transferencia.codigo}</p>
                                            </div>}

                                            {!data.transferencia.codigoerror && <div className="col-lg-6 text-lg-right text-success">
                                                <p>{data.transferencia.codigo}</p>
                                            </div>}

                                        </div>}
                                        {data.transferencia.porcentaje && <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Incremento especial a Tasa Actual')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.transferencia.porcentaje}</p>
                                            </div>
                                        </div>
                                        }
                                        {data.transferencia.metodo_pago && <div className="row border-bottom pt-3">
                                            <div className="col-lg-6">
                                                <h5>{t('Método de pago')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p>{data.transferencia.metodo_pago.nombre}</p>
                                            </div>
                                        </div>
                                        }
                                        <div className="row pt-4 pb-4">
                                            <div className="col-lg-6">
                                                <h5 className="h3 color-naranja">{t('Total a pagar')}</h5>
                                            </div>
                                            <div className="col-lg-6 text-lg-right">
                                                <p className="h3 font-weight-bold color-naranja">{data.transferencia.total} {data.moneda_origen.moneda}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>}
                </Translation>
            );
        }
    }
}
export default RemesaConfirmacion;
