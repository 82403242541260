import React from 'react';
import useGetHTMLFetch from "../../server/useGetHTMLFetch";

import { useTranslation } from 'react-i18next';

export default function Politicas(props) {
    const content = useGetHTMLFetch('index.php/contenidos/politicasdeprivacidad');
    const { t } = useTranslation(['translation', 'menu']);

    return(
         <>
            <div dangerouslySetInnerHTML={{__html: content}} />
        </>
     );
}

