import React from 'react'
import { useTranslation } from 'react-i18next'
import getLanguage from '../language/getLanguage';
const lang = getLanguage();
const LanguageSelector = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (event) => {
    window.localStorage.i18nextLng = event.target.value;
    i18n.changeLanguage(event.target.value);
    window.location.reload();
  }
  const languagesArray = ['es', 'en'];
  return (
    <div>
    <select id="changeLanguage"
        name="language"
        onChange={changeLanguage}
        value={lang}>
        {languagesArray.map((d, index) => {
           return  <option key={index} value={d}>{d}</option>
        })}
    </select>
    </div>
  )
}

export default LanguageSelector
