import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import PrivateArea from '../cliente/PrivateArea';
import { Translation } from 'react-i18next';
import variables from "../../variables/env.js";

class CanjearCupon extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            userSaldo : 0,
            codigoCupon : null,
            mensajeSucess : null
        };
        this.tokenAccess = variables.auth('token');
        this.servicioRemesaActivo = props.servicioRemesaActivo;
        this._handleChange = this._handleChange.bind(this);
    }

    componentDidMount(){
        fetch(variables.apiurl+'index.php/remesa/consultaSaldo',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
            .then(
                (result) => {
                    if (result.status ==='ok'){
                        this.setState({
                            userSaldo : (result.result) ? result.result.replace(".", ",") : 0
                        });
                    }
                }
            );
    }
    /** Recuperar el valor del input canjeCodigoCupon **/
    _handleChange(e) {
        this.setState({
            codigoCupon: e.target.value,
            error : null
        });
    }
    /** Enviar data **/
    enviarData = () =>{
        if (this.state.codigoCupon){
          const data = {
              valorCodigoCupon: this.state.codigoCupon
          }
          this.canjearCodigoCupon(data);
        }

    }
    /** Canjear código cupón **/
    canjearCodigoCupon(data){
        fetch(variables.apiurl +'index.php/servicios/actionCanjearCodigoCupon',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token:this.tokenAccess,
                data:data,
                lang:variables.lang
            })
        })
        .then( response => response.json())
        .then((result) => {
                if (result.status ==='ok'){
                    this.setState({
                        mensajeSucess : result.message,
                        userSaldo :result.result.saldo,
                        error:null
                    });
                    this.handleReset();
                }else{
                    this.setState({
                        error: result.message || 'error',
                        mensajeSucess : null
                    });
                }
            },
            // Handle error
            (error) => {
                console.log(error);
                document.getElementById('canjeCodigoCupon').value = '';
                this.setState({
                    codigoCupon: null,
                    error : null
                });

            },
        );
    }
    /** Limpiar campos de formularios **/
    handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        this.setState({ itemvalues: [{}]});
    };

    render() {
        const {error, isLoaded, userSaldo, mensajeSucess} = this.state;
        if(!isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(<Translation ns={['cupones-digitales']}>
                    {(t) =>
                        <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" activeLink="canjearCupon">
                            <div className="row content pt-4 m-0">
                                <div className="col-lg-6 col-xs-12 mb-4">
                                    <div className="card rounded-top">
                                        <div className="card-body">
                                            <h4 className="bold mb-4">{t('Canjea tu cheque regalo de') + ' ' + variables.appname}</h4>
                                            <label htmlFor="canjeCodigoCupon">{t('Introduce tu código de canje')}</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control font-weight-600" id="canjeCodigoCupon"  aria-describedby="canjeCodigoCuponHelp" onChange={this._handleChange} required="required"/>
                                                <div className="input-group-append">
                                                    <button type="button" className="transparent_btn ml-2 border-1" onClick={this.enviarData}>{t('Canjear')}</button>
                                                </div>
                                                {error && <div className="col-12">
                                                    <div className="alert alert-danger mt-3" role="alert"> <i className="fa fa-times" aria-hidden="true"></i> {error} </div>
                                                </div>
                                                }
                                                {mensajeSucess && <div className="col-12">
                                                    <div className="alert alert-success mt-3" role="alert"> <i className="fa fa-check" aria-hidden="true"></i> {mensajeSucess} </div>
                                                </div>
                                                }
                                            </div>
                                            {Number(userSaldo) > 0 && <h6 className="bold">{t('Saldo actual')}:  <span className="color-green">{userSaldo}</span></h6>}
                                            <small id="canjeCodigoCuponHelp">
                                              <Button className="btn btn-secondary btn-md" onClick={() => window.location.href = '/saldo-movimientos' }>
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i> {t('Ver historial del saldo')}
                                              </Button>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PrivateArea>
                    }
                </Translation>
            );
        }
    }
}
export default CanjearCupon;
