import React from 'react';
import useGetHTMLFetch from "../../server/useGetHTMLFetch";

export default function EnvioMaritimoPaqueteria(props) {
    const content = useGetHTMLFetch('index.php/contenidos/envioMaritimoPaqueteria');

    return(
        <div className="hiw">
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
    );
}

