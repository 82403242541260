import React from 'react';
import { Translation} from 'react-i18next';
import CustomToast from '../common/CustomToast';
import StepZilla from "react-stepzilla";
import "react-stepzilla/src/css/main.css";
import getLanguage from '../../language/getLanguage';
import {useTranslation} from 'react-i18next';
import variables from "../../variables/env.js";

//pasos
import DestinoOrigen from './pasos/DestinoOrigen';
import Dimensiones from './pasos/Dimensiones';
import Seguro from './pasos/Seguro';
import Impuestos from './pasos/Impuestos';
import Recogida from './pasos/Recogida';
import Entrega from './pasos/Entrega';
import Confirmacion from './pasos/Confirmacion';
import MetodosPago from './pasos/MetodosPago';
import Pago from './pasos/Pago';

class Pasos extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step:0,
            moneda:'EUR',
            vOrigenDestino:null,
            vDimensiones:[{ 
                alto:'', ancho:'',largo:'', peso:'', valor:0
            }],
            vSeguro:'',
            vImpuesto:'',
            vRecogida:'',
            vEntrega:'',
            vMetodoPago:'',
            dataFinalEnvio: {continuar : 1},
            toastMsg:false, toastShow:'',toastClass:''
        };
        this.idTipoPaqueteria = props.idTipoPaqueteria;
        this.pasosDesactivados = props.pasosDesactivados || []; //array de pasos desactivados desde la api
    }
    getData = () =>{
        return {
            origendestino: this.state.vOrigenDestino,
            dimensiones: this.state.vDimensiones,
            seguro: this.state.vSeguro,
            impuesto:this.state.vImpuesto,
            recogida:this.state.vRecogida,
            entrega:this.state.vEntrega
        };
    }

    getDataFinalEnvio = () =>{
        let d = this.state.dataFinalEnvio; //datos confirmados con total
        d.metodo_pago = this.state.vMetodoPago;
        d.id_tipo_paqueteria = this.idTipoPaqueteria;

        console.log('getDataFinalEnvio',d);
        return d;
    }

    getValuesConfirmacion = () =>{
        const _self = this;
        const dataConfirmacion = this.getData();
        this.fetchApi({
            endpoind:'index.php/paqueteria/confirmacion',
            body:{ id: this.idTipoPaqueteria, data: dataConfirmacion}
        },  function(result){
                if (result && result.status ==='ok' && result.result){
                  //  console.log(result.result);
                    _self.setState({dataFinalEnvio: result.result});
                }
            },
        )
    }
    

     /*-- al cambiar de paso --*/
    
    jumpToStep(step) {
        // console.log('al cambiar de paso', step, this.state.dataFinalEnvio.continuar)
        // if (Number(this.state.dataFinalEnvio.continuar) === 1){
        //     this.stepzillaInstance.jumpToStep(step);
        // }
    }

    /*-- mensajes --*/
    handleToast = (tmsg,tclass)=>{
        this.setState({toastMsg:tmsg,toastShow:true,toastClass:tclass});
        document.querySelector('#v-pills-dashboard-tab').scroll({top: 0,behavior: 'smooth'});
    }
    hideToast = ()=>{
        this.setState({toastShow:false});
    }
    /*-- llamadas a la api --*/
    fetchApi = (options, fnNext) =>{
        const lang = getLanguage();
        const params = {
            method: (options.method) ? options.method : 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
          };
          if (params.method === 'post'){
            if (!options.body){
                options.body = {lang: lang, token:variables.auth('token')};
            }
            if (!options.body.lang){ options.body.lang = lang; }
            if (!options.body.token){ options.body.token = variables.auth('token'); }
            params.body = JSON.stringify(options.body);
          }
          fetch(variables.apiurl + options.endpoind, params).then(
            (response) => response.json()
          ).then(
            (result) => {
                fnNext(result);
            },
            (error) => {
                fnNext();
            },
          );
    }
    /*--- RENDER ---*/
    render() {
        const {toastMsg, toastShow, toastClass, moneda} = this.state;
        const pasosTodos = [
            {name: 'Origen destino', component: <DestinoOrigen
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                setValuesOrigenDestino={(data) => this.setState({vOrigenDestino : data})}
                getValuesOrigenDestino={() => this.state.vOrigenDestino}
                moneda={moneda}
            />},
            {name: 'Paquetes', component: <Dimensiones
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                setValuesDimensiones={(data) => this.setState({vDimensiones : data})}
                getValuesDimensiones={() => this.state.vDimensiones}
                getValuesOrigenDestino ={()=>this.state.vOrigenDestino }
                moneda={moneda}
            />},
            {name: 'Seguro', component: <Seguro
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                setValuesSeguro={(data) => this.setState({vSeguro : data})}
                getValuesSeguro={() => this.state.vSeguro}
                moneda={moneda}
            />},
            {name: 'Impuestos', component: <Impuestos
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                setValuesImpuesto={(data) => this.setState({vImpuesto : data})}
                getValuesImpuesto={() => this.state.vImpuesto}
                moneda={moneda}
            />},
            {name: 'Recogida', component: <Recogida
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                setValuesRecogida={(data) => this.setState({vRecogida : data})}
                getValuesRecogida={() => this.state.vRecogida}
                moneda={moneda}
            />},
            {name: 'Entrega', component: <Entrega
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                setValuesEntrega={(data) => this.setState({vEntrega : data})}
                getValuesEntrega={() => this.state.vEntrega}
                moneda={moneda}
            />},
            {name: 'Confirmación', component: <Confirmacion
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                dataFinalEnvio={this.state.dataFinalEnvio}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                getValuesConfirmacion={() => this.getValuesConfirmacion()}
                moneda={moneda}
            />},
            {name: 'Método de pago', component: <MetodosPago
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                dataFinalEnvio={this.state.dataFinalEnvio} //total
                setValueMetodoPago = {(data) => this.setState({vMetodoPago : data})}
                moneda={moneda}
            />},
            {name: 'Pago', component: <Pago
                idTipoPaqueteria={this.idTipoPaqueteria}
                jumpToStep={this.jumpToStep}
                fetchApi={this.fetchApi}
                handleToast={(tmsg,tclass) => (this.handleToast(tmsg,tclass))}
                getDataFinalEnvio={() => this.getDataFinalEnvio() } //total
            />},
        ];
        const pasos = [];
        const _self = this;
        console.log('this.pasosDesactivados',this.pasosDesactivados);
        if (this.idTipoPaqueteria == 2){ //paso que no se muestra en maritimo
            this.pasosDesactivados.push('Impuestos');
        }
        pasosTodos.map((p) => {
            if (!_self.pasosDesactivados.includes(p.name)){
                pasos.push(p);
            }
            return p;
        });
        return(
            <Translation ns={['paqueteria']}>
            {(t) =><>
                <CustomToast msg={toastMsg} show={toastShow} tclass={toastClass} hideParentToast={() => (this.hideToast())}/>
                {this.state.dataFinalEnvio && !this.state.dataFinalEnvio.continuar && this.state.dataFinalEnvio.texto &&
                   <div className="alert btn_rojo1" role="alert">
                       <p className='text-center display-4'>{t(this.state.dataFinalEnvio.texto)}</p> 
                  </div>
                }
                <StepZilla
                    steps={pasos.map((p) => {
                        p.name = t(p.name)
                        return  p
                    })}
                    nextButtonText={t('Siguiente')} 
                    backButtonText={t('Atrás')}
                    preventEnterSubmission={true}
                    prevBtnOnLastStep={true}
                    />
                    
                </>}
            </Translation>
        );
        

    }

}
export default Pasos;
