
import React from 'react';
import { Translation } from 'react-i18next';
import MetodosPago from '../../pago/MetodosPago';
import variables from "../../../variables/env.js";

class PaqueteriaMetodosPago extends React.Component {
    constructor(props){
        super(props);
        console.log(props.dataFinalEnvio.total)
        this.state = {
            dataFinalEnvio:props.dataFinalEnvio,
            metodoPago:'',
        }    
        this._isMounted = false;
        //stepzilla
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.isValidated = this.isValidated.bind(this);
        //props functions
        this.idTipoPaqueteria = props.idTipoPaqueteria; 
        this.fetchApi = props.fetchApi.bind(this);  
        this.handleToast = props.handleToast.bind(this); 
        this.setValueMetodoPago = props.setValueMetodoPago.bind(this);
        this.moneda = props.moneda;
   }
   componentDidMount(){
        this._isMounted = true;
   }
   componentWillUnmount() {
       this._isMounted = false;
   }//componentWillUnmount

    isValidated() {
        let isDataValid = (this.state.metodoPago) ? true : false;
        if (!isDataValid){
            this.handleToast(`Debe indicar un método de pago`,'bg-warning');
        }
        return isDataValid;
    }

    setMetodoPago(data){
        console.log('setMetodoPago', data);
        this.setState({metodoPago:data}); 
        this.setValueMetodoPago(data); //props
    }

    render() {
        const classNameTipo = (this.idTipoPaqueteria == 1) ? 'aereo' : 'maritimo';
        return(
            <Translation ns={['traslation', 'paqueteria']}>
            {(t) =><>
                <div className="step_content mt-3">
                { this.state.dataFinalEnvio && this.state.dataFinalEnvio.total && 
                <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                        {/*BANNER*/}
                        <div className={"d-flex align-items-end banner-header " + classNameTipo }>
                        <div className="title">{t('Total a pagar')}<br/> <div className='font-weight-bold'>{this.state.dataFinalEnvio.totalformat} {this.moneda}</div></div> 
                    </div>
                     <div className="first_step_content_item_wrap d-block p-3">
                       
                           
                            <MetodosPago
                                tipoPago={'paqueteria'}
                                tokenAccess={variables.auth('token')}
                                loggedIn={1}
                                getCantidadEnviar={() => (this.state.dataFinalEnvio.total)}
                                setMetodoPago={(data) => (this.setMetodoPago(data))}
                            />  
                            
                      
                                     
                     </div>
                </div> }  
                </div>    
            </>
            }
            </Translation>
        );
  }
}

export default PaqueteriaMetodosPago;
