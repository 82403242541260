/*
Respuesta stripe
*/
import React from 'react';
import withRouter from '../../withRouter';
import { Translation } from 'react-i18next';
import PrivateArea from "../cliente/PrivateArea";
import variables from "../../variables/env.js";
import CardMedia from '@material-ui/core/DialogTitle';


class RespuestaPagoCryptocoin extends React.Component {
    constructor(props){
        super(props);
        this.attr = this.props.params.attr || '';
        this.gifpath = null;
        if (this.attr == 'ok') this.gifpath = variables.appurl +"assets/gif/pagook.gif";
        if (this.attr == 'ko') this.gifpath = variables.appurl +"assets/gif/pagoko.gif";
    }
    render() {
        return(
            <Translation ns={['pago']}>
                {
                    (t) =>
                    <>
                      { variables.auth() &&
                        <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="payment_tab">
                            <div className="profile_wrap">
                                <div className="result_header">
                                    <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.appname} /></a>  </div>
                                </div>
                                <div className="result_body text-center">
                                    {this.gifpath && <img src={this.gifpath} alt={variables.appname} /> }
                                    {!this.gifpath && <p className="lead font-weight-bold"> <i className="fa fa-info text-info" aria-hidden="true"></i> {t(this.attr)}</p>}
                                    
                                    <div className="orange_divider"></div>

                                </div>
                                <div className="result_footer ">
                                    <div className="polygon_item"></div>
                                </div>
                            </div>
                        </PrivateArea>
                      }
                      {!variables.auth() &&
                        <div className="container">
                        <div className="profile_wrap">
                            <div className="result_header">
                                <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.appnamename} /></a>  </div>
                            </div>
                            <div className="result_body text-center">
                                    {this.gifpath && <img src={this.gifpath} alt={variables.appname} /> }
                                    {!this.gifpath && <p className="lead font-weight-bold"> <i className="fa fa-info text-info" aria-hidden="true"></i> {t(this.attr)}</p>}
                                   
                                <div className="orange_divider"></div>
                            </div>
                            <div className="result_footer ">
                                <div className="polygon_item"></div>
                            </div>
                        </div>
                        </div>
                      }
                    </>
                }
            </Translation>
        );

    }
}

export default withRouter(RespuestaPagoCryptocoin);
