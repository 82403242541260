import React from 'react';
import ContactForm from "./ContactForm";
import useGetHTMLFetch from "../../server/useGetHTMLFetch";
import {useTranslation} from "react-i18next";

export default function Contacto(props) {
    const { t } = useTranslation(['contactoform']);
    const content = useGetHTMLFetch('index.php/contenidos/contacto');
    const addClassToBody = () => { document.body.classList.add('contacts'); }
    addClassToBody();

    return(
        <main className="contacts-main">
          <section className="contacts_section">
            <div className='container'>
              <div className='row align-items-stretch'>
                <div className='col-12 text-center'>
                  <h2>{t('titulo1')}</h2>
                  <h3 className="contact_form_title">{t('titulo3')}</h3>
                </div>
                <div className="ccol1 col-lg-8 col-12 text-center">
                    <div className="contact_form_wrap">
                      <ContactForm/>
                    </div>
                </div>
                <div className="ccol2 col-lg-4 col-12">
                    <div className='contact_info_wrap' dangerouslySetInnerHTML={{__html: content}} />
                </div>
              </div>
            </div>
          </section>
        </main>
     );
}
