
import React from 'react';
import SendFormData from "../../server/sendFormData";
import Form from "react-jsonschema-form";
import FormTools from '../common/formTools';
import { Translation } from 'react-i18next';
import variables from "../../variables/env.js";
import '../../assets/css/form.css';

const log = (type) => console.log.bind(console, type);

class BeneficiarioFormCuenta extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            data: props.data,
            schemaForm : props.schemaForm,
            formData : props.formData || {},
        };
        this.tokenAccess = props.tokenAccess;
        this.id_moneda_destino = props.id_moneda_destino || null;
        this.id_beneficiario = props.id_beneficiario || null;

        this.guardarCuenta = props.guardarCuenta || null;
        this.eliminarCuenta = props.eliminarCuenta || null;

        this.handleClose = props.handleClose || null;
        this.handleToast = (props.handleToast) ? props.handleToast : null;
  }

  customFieldTemplate = (props)=>{

      const {id, classNames, label, help, required, description, errors, children, hidden} = props;

      /*---------*/
      return (
        <Translation ns={['beneficiarios, registro']}>
        {
          (t) =>
          <div className={classNames}>
            {!hidden && label && <label htmlFor={id}>{t(label)}
            {required && <span className="required"> ({t('dato obligatorio')})</span>}
            {!required &&<span className="norequired"> ({t('opcional')})</span>}
            </label>}

                {description}
                {children}
                {help}
                {errors.props && errors.props.errors && errors.props.errors.length > 0 && errors.props.errors.map((k,index) => {
                    return  <div key={index} className="text-danger small">
                        {t(errors.props.errors[index])}
                    </div>
                  })
                }
          </div>
        }
        </Translation>

      );
  }

  handleChange  = ({formData}) => {
    let d = this.state.data, n_cuenta;
    Object.keys(formData).map((key) => {
      if (key ==='n_cuenta'){
        n_cuenta = (formData[key]) ? formData[key].replace(/\s/g, '').replace(/([0-9]{4})/g, '$1 ').trim():'';
        if (document.querySelector('#root_n_cuenta')) { document.querySelector('#root_n_cuenta').value = n_cuenta; }
        d.schema.properties['n_cuenta'].default = n_cuenta;
      }else{
        if (d.schema.properties[key]){
          d.schema.properties[key].default = formData[key];
        }
      }
      return key;
    });
    this.setState({data:d});
  }
  onSubmitCuenta = ({formData}, e) => {
        SendFormData(formData, 'index.php/beneficiario/guardaCuentaBancaria')
        .then((response) => {
            if (this.handleClose) { this.handleClose(); }
            if (response.status === 'ok'){
                if (this.handleToast) {  this.handleToast(response.status,response.message); }
                if (this.guardarCuenta) {
                  //enviamos registro en formato schema
                  if (this.state.schemaForm){
                    let d = this.state.data;
                    Object.keys(response.result).map((k) => {
                      if (d.schema.properties[k]){
                        d.schema.properties[k].default = response.result[k]
                      }
                      return k;
                    });
                    this.guardarCuenta(d);
                  }else{
                    this.guardarCuenta(response.result);
                  }

                }
            }
        });
  }
   /*Whenever a class component is updated*/
    componentDidUpdate(prevProps) {
      if (prevProps.isOpen !== this.props.isOpen) {
        this.setState({isOpen: this.props.isOpen});
      }
      if (prevProps.data !== this.props.data) {
        this.setState({data: this.props.data});
      }
    }

    render() {
        const {data} = this.state;
        if(!data) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
<Translation ns={['translation']}>
{
  (t) =><div>
                    {data && <Form className="frmCuentaBeneficiario col-12"
                      schema={data.schema}
                      uiSchema={data.uiSchema}
                      customFormats={data.customFormats}
                      showErrorList={false}
                      transformErrors={FormTools.transformErrors}
                      onError={log("errors")}
                      onChange={this.handleChange}
                      onSubmit={this.onSubmitCuenta}
                      formData = {this.state.formData}
                      FieldTemplate={this.customFieldTemplate}
                    >
                        <fieldset>
                          {this.handleClose && <button type="button" onClick={this.handleClose} className="btn-dark btn-close mr-2">{t('Cerrar popup')}</button>}
                          <button type="submit" className="mb-4">{t('Guardar cambios')}</button>
                          {this.eliminarCuenta && <a href="#" onClick={() => {
                            this.eliminarCuenta(data.schema.properties.id_cuenta.default);
                          }} className="delete_button onClick pt-4"><img src={variables.appurl + "assets/img/delete.svg"} alt={variables.appname} /></a>}
                        </fieldset>
                    </Form>
                    }
                </div>
        }
</Translation>
        );
        }

    }
}

export default BeneficiarioFormCuenta;

//                      liveValidate ={true}
