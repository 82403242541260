import React, { useState, useEffect } from 'react';
const jwt = require('jsonwebtoken');
// import storageHook from './StorageHook';
import * as process from 'process';
// const secretKey = process.env.REACT_APP_API_SEED;
const prefix = process.env.REACT_APP_MIENV;

const Auth = {
    isAuthenticated : false,
    getToken(){
        const token = window.sessionStorage.getItem(prefix+'jwt') || '';
        return (token && token!=='undefined') ? token : '';
    },

    authenticate(token, name, perfil) {
        if (typeof window !== 'undefined'){
          window.sessionStorage.setItem(prefix+'jwt', token);
          window.sessionStorage.setItem(prefix+'username', name);
          window.sessionStorage.setItem(prefix+'sra', perfil.servicioRemesaActivo || '');
          window.sessionStorage.setItem(prefix+'mpd', perfil.metodosPagosDisponibles || '');
          window.sessionStorage.setItem(prefix+'mpapp', perfil.metodosPagosApp);
        }
        this.isAuthenticated = true;
        return this.isAuthenticated;
    },
    signOut() {
        if (typeof window !== 'undefined'){
          window.sessionStorage.removeItem(prefix+'jwt');
          window.sessionStorage.removeItem(prefix+'username');
          window.sessionStorage.removeItem(prefix+'sra');
          window.sessionStorage.removeItem(prefix+'mpd');
          window.sessionStorage.removeItem(prefix+'mpapp');
        }
        this.isAuthenticated = false;
    },
    getAuth() {
        this.isAuthenticated = false;
        const token = this.getToken();
        if (token){
            const decodedToken= jwt.decode(token, {complete: true});
            const dateNow = new Date();
            const tokenExp = Number(decodedToken.payload.exp) * 1000; //JS is in milliseconds and PHP time is in seconds
            let isExpired = false;
            if( tokenExp < dateNow.getTime()){
              isExpired = true;
            }
            if (!decodedToken || isExpired) {
              this.signOut();
              this.isAuthenticated = false;
            }else{
                this.isAuthenticated = true;
            }
        }else{
          this.signOut();
          this.isAuthenticated = false;
        }
        //console.log('this.isAuthenticated',this.isAuthenticated);
        return this.isAuthenticated;
    },
    getName() {
        const username = window.sessionStorage.getItem(prefix+'username') || '';
        return (username && username!=='undefined') ? username : '';
    },
    getServicioRemesaActivo(){
        return window.sessionStorage.getItem(prefix+'sra');
    },
    getMetodosPagosDisponibles(){
        const mpd = window.sessionStorage.getItem(prefix+'mpd');
        return (mpd) ? mpd.split(',') : [];
    },
    getMetodosPagosApp(){
        const mpapp = window.sessionStorage.getItem(prefix+'mpapp');
        return (mpapp) ? JSON.parse(mpapp) : {};
    }



};
export default Auth;

//
//
// function useStorageState(sessionStorageKey, defaultValue) {
//   const initialValue = JSON.parse(sessionStorage.getItem(sessionStorageKey)) || defaultValue;
//   const [value, setValue] = useState(initialValue);
//
//   useEffect(() => {
//     sessionStorageKey.setItem(sessionStorageKey, JSON.stringify(value));
//   }, [value]);
//
//   return [value, setValue];
// }
