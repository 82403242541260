import React from 'react';
import {Translation} from "react-i18next";
import {NavLink} from "react-router-dom";
import variables from "../../variables/env.js";

import MetodosPago from "../pago/MetodosPago";
import EjecutarPago from '../pago/EjecutarPago';

const renderHTML = (escapedHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

class CestaCompra extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            paso: 1,
            cestaCookieValue : null,
            mensaje : null,
            error : null,
            isLoaded : true,
            userSaldo : null,
            data:[],
            totales:0,
            siglasMoneda:null,
            metododepago:null,
            step:1,
            cantidaProducto: 0,
            dataFinal: null
        };
    }
    scrollUp = () =>{
      window.scrollTo(0, 0);
    }
    /** Al Cargar **/
    componentDidMount(){
        this.metodosPagosDisponibles = variables.auth('metodosPagosDisponibles');
        this.metodosPagosApp = variables.auth('metodosPagosApp');
        this.loggedIn = variables.auth();
        this.tokenAccess = variables.auth('token');
        this.idCesta = variables.getIdCesta();

        fetch(variables.apiurl +'index.php/cesta/listadoCestaActiva',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              token:this.tokenAccess, cestaCookie: variables.getIdCesta()
            })
        }).then(response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status === 'ok' && result.result.data && result.result.data.length){
                    this.setResponseInicial(result);
                }else{
                    this.setResponseInicial([]);
                }
            },
            // Handle error
            (error) => {
                console.log('error', error);
                this.setState({isLoaded: true});
            },
        )
    }
    setResponseInicial = (result) =>{
      this.setState({isLoaded: false});
      if (result.status ==='ok'){
          this.setState({
              totales : result.result.totales,
              cantidaProducto : (typeof result.result.data[0] !== 'undefined') ? result.result.data[0].cantidad_total_productos : null,
              siglasMoneda : 1
          });
          if (result.result.data.length){
            //controlamos la url de las imagenes
            for (let i=0; i<result.result.data.length; i++){
              result.result.data[i].imagenURL = this.getImagenURL(result.result.data[i].id_tipo_servicios)
            }
          }
          this.setState({
              data : result.result.data,
          });
      }else{
          this.setState({
              error: result.message
          });
          if (result.action==='login'){
              this.setState({isLoaded: true});
              window.location.href = '/login';
          }
      }
    }
    /** Obtener url de imagen servicio **/
    getImagenURL = (id_tipo_servicios) => {
        let imagenURL = variables.appurl+'/assets/img/cupones/';
        switch (Number(id_tipo_servicios)) {
            case 9:
              imagenURL += 'gif-card/';
              break;
        }
       return imagenURL;
    }
    /** Eliminar productos de la Cesta **/
    removeItem = e => {
      const idDetalleCesta = e.target.dataset.id_detalle_cesta;
      const idProducto = e.target.dataset.id_producto;
      const tipoServicio = e.target.dataset.id_tipo_servicios;
      this.setState({totales: ''});
      // remove on Server
      fetch(variables.apiurl +'index.php/cesta/removeItem',{
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              token:this.tokenAccess,
              data:{ cestaCookie:this.idCesta, idProducto:idProducto, idDetalleCesta:idDetalleCesta, tipoServicio:tipoServicio}
          })
      }).then( response => response.json())
      .then(
          // handle the result
          (result) => {
              if (result.status ==='ok'){
                if (result.result.data.length){
                  //controlamos la url de las imagenes
                  for (let i=0; i<result.result.data.length; i++){
                    result.result.data[i].imagenURL = this.getImagenURL(result.result.data[i].id_tipo_servicios)
                  }
                }
                  this.setState({
                      data : result.result.data,
                      totales : result.result.totales,
                  });
                  const divCountCesta = document.getElementById("cart_menu_num");
                  const resultCountCesta = parseInt(divCountCesta.innerHTML) - 1;
                  divCountCesta.innerHTML = (resultCountCesta === 0) ? '' : resultCountCesta;
              }else{
                  this.setState({
                      error: result.message
                  });
                  if (result.action==='login'){
                        window.location.href = '/Login';
                  }
              }
          },
          // Handle error
          (error) => {
              this.setState({
                  isLoaded: true,
                  error
              })
          },
      )
    };
    /** Obtener importe total de la Cesta **/
    obtenerImporteTotalCesta = ()=>{
        return this.state.totales.total ;
    }
    /** Seleccionar metodo de pago **/
    selecionarMetodoPago = (d)=>{
        if (d) {
          this.setState({metododepago:d});
        }
        this.setState({isLoaded:false});
    }
    confirmarProductos = (v) =>{
        this.setState({paso:v});
        this.scrollUp();
    }
    botonPagar = ()=> {
      if (!variables.auth('token')){
          window.location.href = '/login';
      }else{
        this.sendPago();
      }
    }

    borrarCountCesta = ()=> {
        this.divCountCesta = document.getElementById("cart_menu_num");
        this.divCountCesta.innerHTML = '';
    }

    sendPago = () =>{
        const d = {
          totalApagar: this.state.totales,
          datos : this.state.data,
          token : this.tokenAccess,
          metodosPago: this.state.metododepago,
          cestaCookie: this.idCesta
        };
        this.setState({
          dataFinal:d,
          paso: 3
        });
        this.scrollUp();

    }
    getDataFinal = () =>{
      return this.state.dataFinal;
    }

    render() {
        const {error, isLoaded, userSaldo, data, totales, paso, cantidaProducto, metododepago, mensaje} = this.state;
        if(isLoaded) {
            return <div className="loading CestaCompra"><div className="loading cestaCompra">Loading</div></div>
        }else{
            return(<Translation ns={['cesta']}>
            {(t) =>
                <div className="container">
                { (error) &&
                    <>
                        <div className="row mt-5 mb-5">
                            <div className="col-12">
                                <div className="profile_wrap">
                                    { error && <div className="result_body">
                                        <div className="p-2 bg-danger text-white text-center m-5">
                                            <h3>{error}</h3>
                                        </div>
                                        <a href="/cesta-compra" className={"transparent_btn m-auto"} >{t('Volver a la Cesta')}</a>
                                        <div className="spinner-border text-muted"></div>
                                    </div>
                                    }
                                    <div className="result_footer "><div className="polygon_item"></div></div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                { (!error) && (data.length === 0) &&
                  <div className="text-center m-5">
                      <img src={variables.appurl + 'assets/img/cesta_vacia.png'} alt={'cesta-vacia'} title={'cesta-vacia'} width={'50%'}/>
                      {error && <h3 className={"mt-4"}>{t(''+error)}</h3>}
                  </div>

                }
                { !error && data && data.length > 0 &&
                  <div className="row mt-4">
                      {paso === 1 && <>
                        {/* tabla productos en la cesta */}
                        <div className="col-lg-8 mb-4">
                            <div className="dashboard_tab_item cesta_wrap">
                                <div className=" dashboard_table">
                                  {/* cabecera */}
                                  <div className="lastest_saldo_title dashboard_table_row dashboard_table_row_title">
                                    <div className="saldo_id_title">{t('Producto/Servicio')}</div>
                                    <div className="saldo_date_title hidden-xs"></div>
                                    <div className="saldo_imported_title">{t('Precio')} <span className="d-none d-md-block"></span></div>
                                    <div className="saldo_balance_title">{t('Cant')}</div>
                                    <div className="saldo_comision_title">{t('Comisión')}</div>
                                    <div className="saldo_condition_title">{t('Subtotal')}</div>
                                    <div></div>
                                  </div>
                                  {/* detalles */}
                                  {data.map((prod, index) => {
                                    return  <div className="dashboard_table_row" id={"producto_item_"+index} key={index}>
                                          <div className="saldo_id_item">
                                            <div className="d-block d-md-none">{prod.imagen  &&
                                                  <img src={prod.imagenURL+prod.imagen} alt={(prod.descripcion) ? prod.descripcion.replace(/(<([^>]+)>)/ig, ''): ''} title={(prod.descripcion) ? prod.descripcion.replace(/(<([^>]+)>)/ig, ''): ''} width={'30%'}/>
                                            }</div>
                                            {renderHTML(prod.descripcion)}
                                          </div>
                                          <div className="saldo_date_item hidden-xs">
                                            {prod.imagen  &&
                                                  <img src={prod.imagenURL+prod.imagen} alt={(prod.descripcion) ? prod.descripcion.replace(/(<([^>]+)>)/ig, ''): ''} title={(prod.descripcion) ? prod.descripcion.replace(/(<([^>]+)>)/ig, ''): ''} width={'30%'}/>
                                            }
                                          </div>
                                          <div className="saldo_imported_item">{prod.importe_producto.replace(".", ",")} EUR</div>
                                          <div className="saldo_balance_item text-center">{prod.cantidad}</div>
                                          <div className="saldo_balance_item text-center">{prod.comision_producto.replace(".", ",")} EUR</div>
                                          <div className="saldo_condition_item"><div className="accent_btn">{prod.subtotal.replace(".", ",")}</div></div>
                                          <div className="saldo_delete_item pointer" onClick={this.removeItem} data-key={index} data-id_detalle_cesta={prod.id_detalle_cesta} data-id_producto={prod.id_producto} data-id_tipo_servicios={prod.id_tipo_servicios}>
                                            <img src={variables.appurl + 'assets/img/delete.svg'} alt={variables.sitename} className="noClick"/>
                                          </div>
                                      </div>
                                  })}
                            </div>
                            </div>
                        </div>
                        {/* opciones de cesta */}
                        <div className="col-lg-4 mb-4">

                            {/*----SALDO----------------------*/}
                            {userSaldo &&
                              <div className="dashboard_tab_item course_item">
                                <div className="courses_wrap ">
                                  <div className="saldo_wrap">
                                    <div className="courses_item_btn">
                                      <div className="courses_item_btn__currency">
                                        <img src={variables.appurl + "assets/img/EURi.svg"} alt={variables.sitename}/>
                                      </div>
                                      <div className="courses_item_btn__info">
                                        <div className="first_value">
                                          EURi {t('Saldo')}
                                        </div>
                                        <div className="second_value">{userSaldo}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            {/*----TOTALES----------------------*/}
                            <div className="dashboard_table dashboard_tab_item">
                              {/*----ITEMS COUNT----------------------*/}
                              <div className="dashboard_table_row">
                                  <div>{t('Items')}: </div> <div className="second_value"> {data.length}</div>
                              </div>
                              <div className="dashboard_table_row">
                                  <div>{t('Comisiones')}: </div> <div className="second_value"> {data[0].total_comision_producto && data[0].total_comision_producto !== '' ? data[0].total_comision_producto.replace(".", ",")  : 0.00} EUR</div>
                              </div>
                              <div className="dashboard_table_row">
                                  <div>{t('IVA')}:</div><div className="second_value">0,00</div>
                              </div>

                              <div className="dashboard_table_row">
                                  <div className="second_value">{t('Total a pagar')}: </div>
                                  <div className="second_value">
                                      {totales.total && totales.total !== '' ? totales.total.replace(".", ",")  : 0.00} EUR
                                  </div>

                              </div>
                              </div>
                                {  totales && <div className="dashboard_table_row metodo-pago d-block">
                                    <MetodosPago
                                        tipoPago={'cesta'}
                                        tokenAccess={this.tokenAccess}
                                        loggedIn={this.loggedIn}
                                        getCantidadEnviar={this.obtenerImporteTotalCesta}
                                        setMetodoPago={this.selecionarMetodoPago}

                                    />
                                </div>}

                            {totales.total && totales.total !== '' &&
                            <button type={"button"}  onClick={ (e) => this.confirmarProductos(2)} className='accent_btn w-100 d-block'>{t('Siguiente')}</button>
                            }
                        </div>
                      </>}
                      {paso === 2 && <>
                        {/* RESUMEN */}
                        <div className="col-lg-8">
                            <div className="card-cesta-productos mt-4 mb-5">
                                <article className="card-body p-4"><h4 className="card-title mb-4">{t('Resumen de compra')}</h4>
                                    <div className="row">
                                        {data.map((prod, index) => {
                                            return <div className="col-md-6" id={"resumen_producto_item_"+index} key={index}>
                                                <div className="itemside mb-4">
                                                    <div className="aside float-left mb-3 mr-1">
                                                        <img src={prod.imagenURL+prod.imagen} alt={prod.descripcion} title={prod.descripcion} className="border img-sm rounded" width={'100px'}/>
                                                    </div>
                                                    <div className="info">
                                                        {renderHTML(prod.descripcion)}
                                                        <span className="text-muted">{prod.cantidad} x {prod.importe_producto.replace(".", ",")} EUR </span>
                                                        <div>
                                                            <strong className="price"> {prod.subtotal.replace(".", ",")} EUR</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </article>
                                <article className="card-body border-top">
                                    <button className="btn btn-light bg-white text-primary shadow-sm mt-4 ml-4" onClick={ (e) => this.confirmarProductos(1)}>{t('Editar pedido')} </button>
                                    <table className="table table-sm table-detalle-compra">
                                        <tbody>
                                            <tr>
                                                <td>{t('Subtotal')} ({cantidaProducto}  articulos):</td>
                                                <td>{totales.total && totales.total !== '' && <span> {totales.total.replace(".", ",")}  EUR</span> }</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Gastos de envio')}:</td>
                                                <td> 0,00 EUR</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Total')}:</td>
                                                <td>{totales.total && totales.total !== '' && <strong className="h5 price"> {totales.total.replace(".", ",")}  EUR</strong> } </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </article>
                            </div>
                        </div>

                        <div className="col-lg-4  mt-4">
                            <div className="card-cesta-productos">
                                <article className="card-body p-4">
                                    {metododepago && metododepago.nombre && <p>
                                        {t('Metodo de pago indicado')}: {metododepago.nombre}
                                    </p>
                                    }
                                    {!this.tokenAccess && <small>
                                        {t('Antes de pagar debes identificarte como usuario registrado')}
                                    </small>
                                    }
                                    <button type="button" onClick={this.botonPagar} className="accent_btn w-100 d-block mt-3"> {t('Pagar')} </button>
                                </article>
                            </div>
                        </div>
                      </>}
                      {paso === 3 &&

                        <EjecutarPago
                            tipoPago={'cesta'}
                            tokenAccess={this.tokenAccess}
                            getDataFinal={() => (this.getDataFinal())}
                        />
                      }

                  </div>
                }


              </div>
            }
            </Translation>

        );
        }
    }
}

export default CestaCompra;
