import React from 'react';
import { Translation } from 'react-i18next';
import '../../../assets/css/form.css';
//recogida u ordenante
class Recogida extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            initdata:[], 
            camposRequeridos: [],
            servicioRecogida:'',
            direccion:'',
            codigo_postal:'',
            telefono:'',
            ciudad:'',
            provincia:''
        }
        this._isMounted = false;
        //stepzilla
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.isValidated = this.isValidated.bind(this);
        
        //props functions
        this.fetchApi = props.fetchApi.bind(this); //bind from props 
        this.handleToast = props.handleToast.bind(this); //bind from props 
        this.setValuesRecogida = props.setValuesRecogida.bind(this); //bind from props 
        this.getValuesRecogida = props.getValuesRecogida.bind(this); //bind from props 
    }
    componentDidMount(){
        const _self = this;
        this.fetchApi({
            endpoind:'index.php/paqueteria/RecogidaForm',
          }, function(result){
                if (result && result.status ==='ok' && result.result){
                    _self.setState({isLoaded: false,  camposRequeridos: result.result.campos});
                }else{
                    _self.setState({isLoaded: true, error: result.message})
                }
                 //obtenemos valores ya informados
                 const values = _self.getValuesRecogida();
                 if (values){
                     if (values.incluye_recogida) _self.setState({servicioRecogida:values.incluye_recogida});
                    result.result.campos.forEach(function(a, i) {
                        _self.setState({ [a]: values[a] });
                     }, this);
                 }
                 _self._isMounted = true;
            }
        );
    }
    componentWillUnmount() {
        this._isMounted = false;
    }//componentWillUnmount

    isValidated() {
        let isDataValid = this._validateData();
        if (!isDataValid){
            this.handleToast(`Todos los campos (*) son requeridos`,'bg-warning');
        }else{
            let dataFinal = {};
            this.state.camposRequeridos.forEach(function(a, i) {
                dataFinal[a] =  this.state[a]; 
            }, this);

            dataFinal.incluye_recogida = this.state.servicioRecogida;
            this.setValuesRecogida(dataFinal);
        }
        return isDataValid;
    }
    _validateData() {
        //todos los campos son requeridos
        let valid = true;
        if (this.state.servicioRecogida == 'si') {
            this.state.camposRequeridos.forEach(function (a, i) {
                if (!this.state[a]) valid = false;
            }, this);
        }
        return valid;
    }

   
    render() {
        const {error, isLoaded, initdata, servicioRecogida} = this.state;
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
       
            return(
                <Translation ns={['paqueteria']}>{(t) =>
                <>
                    <div className="step_content mt-3">
                    <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                        {/*BANNER*/}
                        <div className={"d-flex align-items-end banner-header recogida" }>
                            <div className="title">{t('Información de recogida')} </div> 
                        </div>   
                        <div className="first_step_content_item_wrap d-block p-3">
                            {/**pregunta SINO */}
                            <div className='row mb-3'> 
                                <div className='col-12 mt-5'><h6>¿{t('Incluir servicio de recogida')}?</h6></div>
                                <div className='col-6'>
                                    <span 
                                        id="opcionsi"
                                        onClick={(e) => this.setState({servicioRecogida:'si'})}
                                        className={servicioRecogida === 'si' ? 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3 active' : 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3'}
                                        > {t('Si')}
                                    </span>
                                    <span     
                                        id="opcionno"
                                        onClick={(e) => this.setState({servicioRecogida:'no'})}
                                        className={servicioRecogida === 'no' ? 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3 active' : 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3'}
                                        > {t('No')}
                                    </span> 
                                </div>
                            
                            </div>
                            {servicioRecogida == 'si' &&
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label className='text-left label'>{t('Dirección')} <span
                                            className="required"> (*)</span></label>
                                        <input type="text" id="direccion" name="direccion" className="form-control"
                                               onChange={e => this.setState({direccion: e.target.value})}
                                               value={this.state.direccion}
                                               autoFocus
                                        />
                                    </div>
                                    <div className='col-lg-6 pt-3 pb-3'>
                                        <label className='label'>{t('Código postal')} <span
                                            className="required"> (*)</span></label>
                                        <input type="text" id="codigo_postal" name="codigo_postal"
                                               className="form-control"
                                               onChange={e => this.setState({codigo_postal: e.target.value})}
                                               value={this.state.codigo_postal}
                                        />
                                    </div>
                                    <div className='col-lg-6 pt-3 pb-3'>
                                        <label className='label'>{t('Teléfono')} <span className="required"> (*)</span></label>
                                        <input type="text" id="telefono" name="telefono" className="form-control"
                                               onChange={e => this.setState({telefono: e.target.value})}
                                               value={this.state.telefono}
                                        />
                                    </div>
                                    <div className='col-lg-6 pt-3 pb-3'>
                                        <label className='label'>{t('Ciudad')} <span className="required"> (*)</span></label>
                                        <input type="text" id="ciudad" name="ciudad" className="form-control"
                                               onChange={e => this.setState({ciudad: e.target.value})}
                                               value={this.state.ciudad}
                                        />
                                    </div>
                                    <div className='col-lg-6 pt-3 pb-3'>
                                        <label className='label'>{t('Provincia')} <span className="required"> (*)</span></label>
                                        <input type="text" id="provincia" name="provincia" className="form-control"
                                               onChange={e => this.setState({provincia: e.target.value})}
                                               value={this.state.provincia}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    
                    </div>
                    </div>
                </>
                }
                </Translation>
            );
        }    
  }
}

export default Recogida;
