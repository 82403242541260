/*
recibe token
muestra un input para enviar el codigo de activacion que llega al teléfono y cambiar el campo activacion = 1
redirige a login_url
*/

import React from 'react';
import withRouter from '../../withRouter';
import SendFormData from "../../server/sendFormData";
import { Translation, Trans } from 'react-i18next';
import { NavLink } from "react-router-dom";
import variables from "../../variables/env.js";

import '../../assets/css/form.css';

class ActivarNuevaCuenta extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            message : null,
            loading : null,
            cuentaActivada:null
        };
        this.token = this.props.params.token;
        this.codigoActivacion = React.createRef();

    }

    onSubmit = (e) => {
      this.setState({loading:true,message : '',error : null});
      const data = {
        token:this.token,
        codigo: this.codigoActivacion.current.value
      };
      SendFormData(data, 'index.php/cliente/activar')
      .then((resp) => {
          this.setState({
            message : resp.message,
            error : (resp.status === 'ko')? true:null
          });
          this.setState({loading:null});
          if (resp.status === 'ok'){

            if (resp.result && resp.result.url){
              setTimeout(function(){ window.location.href = resp.result.url; }, 3000);

            }else{
              this.setState({cuentaActivada:true});
            }

          }
      });
    }

  render() {
    const {error, loading, message,cuentaActivada} = this.state;

        return(

<Translation ns={['translation', 'login']}>
{
(t) => <main id="wrapper" className="pt-md-5 pb-md-5" role="main">
  <div className="py-5"></div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-9 col-lg-7 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title py-4 text-center font-weight-bold">{t('Activa tu cuenta') +' '+ variables.appname}</h5>
                              {loading && <div className="loading"><div className="spinner-border text-muted"></div></div>}
                              {!error && message && <div className="card"><div className="card-body">{message}</div></div>}
                              {!loading && (error || !cuentaActivada) &&
                                <form>
                                  <div className="input-wrapper">
                                      <div className="input-group mb-2">
                                          <input type="text" ref={this.codigoActivacion} name='codigoActivacion' placeholder={t('Código SMS')} className="form-control rounded" required='required'/>
                                      </div>
                                      {error && <div className="alert alert-danger text-uppercase" role="alert"><i className="fa fa-times" aria-hidden="true"></i> {message}</div>}
                                  </div>
                                  <div className="text-center pt-4">
                                    <button type="button" className="accent_btn" onClick={this.onSubmit}>{t('Enviar')}</button>
                                  </div>
                              </form>
                            }
                        </div> </div>
                </div>
            </div>
        </div>
        <div className="py-5"></div>
    </main>
  }
  </Translation>

)//end return

  }//end render
}
export default withRouter(ActivarNuevaCuenta);
