import React from 'react';
import useGetHTMLFetch from "../../server/useGetHTMLFetch";
import { useTranslation } from 'react-i18next';

export default function PreguntasFrecuentes(props) {
    const content = useGetHTMLFetch('index.php/contenidos/preguntasFrecuentes');
    const { t } = useTranslation(['translation', 'menu']);

    return(
        <>
            <div dangerouslySetInnerHTML={{__html: content}} />
        </>
    );
}

