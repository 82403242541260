import React, {useEffect, useState} from "react";
import withRouter from '../../withRouter';
import CustomToast from '../common/CustomToast';
import StepZilla from "react-stepzilla";
import "react-stepzilla/src/css/main.css";
import PrivateArea from '../cliente/PrivateArea';
import RemesaBeneficiario from './RemesaBeneficiario';
import RemesaCantidadEnviar from './RemesaCantidadEnviar';
import RemesaConfirmacion from './RemesaConfirmacion';
import MetodosPago from '../pago/MetodosPago';
import EjecutarPago from '../pago/EjecutarPago';

import {useTranslation} from 'react-i18next';
import '../../assets/css/remesas.css';

import variables from "../../variables/env.js";


 function Remesa(props) {
    // window.scrollTo(0, 0);
    const { t } = useTranslation(['remesas']);

    const [step, setStep] = useState(0);
    const [monedaDestinoData, setMonedaDestinoData] = useState(false);
    const [monedaOrigenData, setMonedaOrigenData] = useState(false);
    const [tarifaData, setTarifaData] = useState(false);
    const [beneficiarioData, setBeneficiarioData] = useState(false);
    const [cantidadData, setCantidadData] = useState(false);
    const [metodoPagoData, setMetodoPagoData] = useState(false);
    const [codigoData, setCodigoData] = useState(false);
    const [toastMsg, setToastMsg] = useState(false);
    const [toastShow, setToastShow] = useState(false);
    const [toastClass, setToastClass] = useState(false);
    const [dataFinal, setDataFinal] = useState(false);
    const [saldo, setSaldo] = useState(0);
    const moneda_destino = props.params.moneda_destino || false;
    const tokenAccess = variables.auth('token');
    const handleSaldo = props.handleSaldo;
    const servicioRemesaActivo = variables.auth('servicioRemesaActivo');
    const [serviciosActivos, setServiciosActivos] = useState(null);
    let arrayServi = [];

    //const stepName = [t('Selecciona moneda destino'),t('Cantidad a enviar'),t('Beneficiario'),t('Confirmación'), t('Pago seguro')];
    const stepName = [t('Cantidad a enviar'),t('Beneficiario'),t('Método de pago'),t('Confirmación'), t('Pago')];

     /****** movemos scroll menu ******/
     useEffect(() => {
         getServiciosActivos();
     }, [location]);

    /*-- STEP 0--*/
    /* se obtiene la moneda destino por parámetro de  url */
    const getMonedaDestinoByParams = () => {
        return moneda_destino;
    }
    /* moneda destino seleccionada (json) */
    const setMonedaDestino = (moneda_destino_data)=>{
        //console.dir('Remesa.setMonedaDestino');
        // console.dir(moneda_destino_data);
        setMonedaDestinoData(moneda_destino_data);
    }
    const setMonedaOrigen = (moneda_origen_data)=>{
        //console.dir('Remesa.setMonedaOrigen');
        //console.dir(moneda_origen_data);
        setMonedaOrigenData(moneda_origen_data);
    }
    const setTarifa = (tarifa)=>{
        //console.dir('Remesa.setTarifa');
        //console.dir(tarifa);
        tarifaData(tarifa);
    }
    const getMonedaDestino = () => {//console.dir(monedaDestinoData);
        return monedaDestinoData;
    }
    const getMonedaOrigen = () => {//console.dir(monedaOrigenData);
        return monedaOrigenData;
    }
   
    const getTarifa = () => {//console.dir(tarifaData);
        return tarifaData;
    }
    /* datos de beneficiario seleccionado (json) */
    const setBeneficiario = (beneficiario_data)=>{
        //console.log('remesa setBeneficiario', beneficiario_data);
        setBeneficiarioData(beneficiario_data);
    }
    const getBeneficiario = () => {
        return beneficiarioData;
    }
    /* cantidad enviar (json) */
    const setCantidad = (cantidad_enviar_recibir)=>{
        //console.log('remesa cantidadData', cantidad_enviar_recibir);
        setCantidadData(cantidad_enviar_recibir);
    }
    const getCantidadEnvia = () =>{ //console.log('getCantidadEnvia',cantidadData);
        return (cantidadData.valueEnvia) || 0;
    }
    const getCantidadRecibe = () =>{
        return (cantidadData.valueRecibe) || 0;
    }
    /* CODIGO PROMO */
    const setCodigo = (codigopromocion)=>{
        //console.log('remesa codigopromocion', codigopromocion);
        setCodigoData(codigopromocion);
    }
    const setMetodoPago = (metodo)=>{
        //console.log('Remesa.setMetodoPago',metodo);
        setMetodoPagoData(metodo);
    }
    const getMetodoPagoSeleccionado = (metodo)=>{
        //console.log('Remesa.setMetodoPago',metodo);
        return metodoPagoData || null;
    }
    /* regresa toda la información para enviar */
    const getRemesaDataFinal = () =>{
        return {
          moneda_destino: monedaDestinoData,
          moneda_origen: monedaOrigenData,
          id_beneficiario: beneficiarioData.id,
          nombre_beneficiario: beneficiarioData.nombre_beneficiario,
          n_cuenta: beneficiarioData.cuentas[0].n_cuenta,
          tipo_de_cuenta: beneficiarioData.cuentas[0].tipo_de_cuenta,
          banco: beneficiarioData.cuentas[0].banco,
          cantidad_enviar: cantidadData.valueEnvia,
          cantidad_recibir: cantidadData.valueRecibe,
          tarifa_actual: monedaDestinoData.tarifa,
          comision: monedaDestinoData.comision,
          numero_oficina: (beneficiarioData.cuentas[0].numero_oficina) ? beneficiarioData.cuentas[0].numero_oficina : '',
          total: Number(cantidadData.valueEnvia) + Number(monedaDestinoData.comision),
          codigo: codigoData,
          metodo_pago: metodoPagoData
      };
    }
    const getRemesaDataFinalConfirmada = () => {
        return dataFinal;
    }
    const setDataFinalConfirmada = (data)=>{
        setDataFinal(data);
    }
     /*-- TODOS LOS PASOS --*/
    /* al cambiar de paso */
    const handleStepChange = (step)=>{
        setStep(step);
        //console.log('handleStepChange',step)
    }
    /* mensajes de sistema */
    const handleToast = (tmsg,tclass)=>{
        setToastClass(tclass);
        setToastMsg(tmsg);
        setToastShow(true);
        document.querySelector('#v-pills-dashboard-tab').scroll({top: 0,behavior: 'smooth'});
    }
    const hideToast = ()=>{
        setToastShow(false);
    }
    const getServiciosActivos = () => {
         if (!serviciosActivos){
             fetch(variables.apiurl + 'index.php/cliente/serviciosActivos', {
                 method: 'post',
                 headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json'
                 },
                 body: JSON.stringify({ token:tokenAccess})
             }).then(response => response.json())
                 .then(
                     (result) => {
                         if (result.status === 'ok') {
                             setServiciosActivos(result.result.data);
                         }
                     }
                 );
         }
     }
     if (serviciosActivos !== null){arrayServi = serviciosActivos.split(',')}
    const steps =
    [{name: stepName[0], component: <RemesaCantidadEnviar
                                        tokenAccess={tokenAccess}
                                        handleToast={(tmsg,tclass) => (handleToast(tmsg,tclass))}
                                        getMonedaDestinoDefault={() => (getMonedaDestinoByParams())}
                                        getMonedaOrigen={() => (getMonedaOrigen())}
                                        getMonedaDestino={() => (getMonedaDestino())}
                                        getTarifa={() => (getTarifa())}
                                        setCantidad={(cantidad) => (setCantidad(cantidad))}
                                        getCantidadEnvia={() => (getCantidadEnvia())}
                                        getCantidadRecibe={() => (getCantidadRecibe())}
                                        setMonedaDestinoData={(moneda_destino_data) => (setMonedaDestino(moneda_destino_data))}
                                        setMonedaOrigenData={(moneda_origen_data) => (setMonedaOrigen(moneda_origen_data))}
                                        setCodigo={(codigo) => (setCodigo(codigo))}
        />},
        {name: stepName[1], component: <RemesaBeneficiario
                                            tokenAccess={tokenAccess}
                                            handleToast={(tmsg,tclass) => (handleToast(tmsg,tclass))}
                                            getMonedaOrigen={() => (getMonedaOrigen())}
                                            getMonedaDestino={() => (getMonedaDestino())}
                                            beneficiarioSeleccionadoLabel={(beneficiarioData.nombre_beneficiario)}
                                            beneficiarioSeleccionadoValue={(beneficiarioData.id)}
                                            setBeneficiario={(beneficiario_data) => (setBeneficiario(beneficiario_data))}
        />},

        {name: stepName[2], component: <MetodosPago
                                            tipoPago={'remesa'}
                                            tokenAccess={tokenAccess}
                                            loggedIn={1}
                                            getCantidadEnviar={() => (getCantidadEnvia())}
                                            setMetodoPago={(data) => (setMetodoPago(data))}
                                            getMetodoPagoSeleccionado={() => (getMetodoPagoSeleccionado())}
        />},

        {name: stepName[3], component: <RemesaConfirmacion
                                            tokenAccess={tokenAccess}
                                            getRemesaDataFinal={() => (getRemesaDataFinal())}
                                            setDataFinalConfirmada={(data) => (setDataFinalConfirmada(data))}
        />},
        {name: stepName[4], component: <EjecutarPago
                                            tipoPago={'remesa'}
                                            tokenAccess={tokenAccess}
                                            getDataFinal={() => (getRemesaDataFinalConfirmada())}
                                        />}
    ]
    return(

      <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="payment_tab" activeLink="remesa">
          <CustomToast msg={toastMsg} show={toastShow} tclass={toastClass} hideParentToast={() => (hideToast())}/>
            <div className="payment_wrap">
              {arrayServi && arrayServi.length > 0 && arrayServi.indexOf('4') > -1 && <StepZilla
                      steps={steps}
                      nextButtonText={t('translation:Siguiente')} backButtonText={t('translation:Atrás')}
                      preventEnterSubmission={true}
                      prevBtnOnLastStep={false}
                      onStepChange={handleStepChange}/>
              }
              {arrayServi && arrayServi.length > 0  && arrayServi.indexOf('4') === -1 &&
              <div className="profile_wrap">
                  <div className="result_header">
                      <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.sitename} /></a>  </div>
                  </div>
                  <div className="result_body">
                      <p className="lead font-weight-bold"><i className="fa fa-exclamation text-danger font-weight-600" aria-hidden="true"></i>  {t('Su cuenta no está habilitada para enviar Remesas. Por favor contacte con atención al cliente.')}</p>
                      <div className="orange_divider"></div>
                  </div>
                  <div className="result_footer ">
                      <div className="polygon_item"></div>
                  </div>
              </div>
              }
            </div>

        </PrivateArea>
    );
}


export default withRouter(Remesa);
