import React from 'react';
import useGetHTMLFetch from "../../server/useGetHTMLFetch";

export default function ComoFunciona() {
    const content = useGetHTMLFetch('index.php/contenidos/comoFunciona');
    return(
        <div className="hiw">
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
    );
}

