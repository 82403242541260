import React from "react";
import Toast from 'react-bootstrap/Toast';
import { Translation } from 'react-i18next';

class CustomToast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show || false,
      msg: props.msg || '',
      tclass:props.tclass
    };
    this.hideParentToast = props.hideParentToast || null;
  }
  hideToast() {
    this.setState({ show: false });
    if (this.hideParentToast) this.hideParentToast();
  }
  /*Whenever a class component is updated*/
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({show: this.props.show});
      if (this.props.show === true){
         window.scrollTo(0, 0);
      }
    }
    if (prevProps.msg !== this.props.msg) {
      this.setState({msg: this.props.msg});
    }
    if (prevProps.tclass !== this.props.tclass) {
      this.setState({tclass: this.props.tclass});
    }
  }
  render() {
    return (
      <Translation ns={['translation']}>
      {
        (t) =>
        <div aria-live="polite" aria-atomic="true" style={{position: 'relative',width:'100%'}}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex:'99'
            }}
          >
            <Toast onClose={() => this.hideToast()} delay={5000} className={"toast "+this.state.tclass} show={this.state.show} autohide>
              <Toast.Body><i className="fa fa-exclamation" aria-hidden="true"></i> {t(this.state.msg)}</Toast.Body>
            </Toast>
          </div>
      </div>
    }
    </Translation>
  );
  }
}

export default CustomToast;
