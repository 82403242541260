import React from 'react';
import BeneficiarioFormData from "./BeneficiarioFormData";
import variables from "../../variables/env.js";


class BeneficiarioEditData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded:true,
            error:null,
            data:null
        };
        this.id_beneficiario = props.id_beneficiario;
        this.isPopup = props.isPopup;
        this.handleToast = props.handleToast;
        this.handleClose = props.handleClose;
        this.guardarBeneficiario = props.guardarBeneficiario;
        this.requiredFields = props.requiredFields;
    }

    guardarBeneficiarioBridge(b) {
        this.guardarBeneficiario(b);
    }
    componentDidMount(){
        this.tokenAccess = variables.auth('token');
        let dataPost = {id_beneficiario: this.id_beneficiario};
        fetch(variables.apiurl+'index.php/beneficiario/obtenerDatosPersonalesForm',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              token:this.tokenAccess,
              data:dataPost,
              required_fields:this.requiredFields,
              lang:variables.lang
            })
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        data : result,
                        isLoaded:false
                    });
                }else{
                    if (result.action==='login'){
                        window.location.href = '/login';
                    }
                    this.setState({
                        error: result.message
                    })
                }
            },
            // Handle error
            (error) => {
                this.setState({
                    error
                })
            },
        )
    }
    render() {
        const {isLoaded,data} = this.state;
        if(isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(
                <BeneficiarioFormData
                      tokenAccess = {this.tokenAccess}
                      data = {data}
                      handleToast={() => (this.handleToast())}
                      handleClose={() => (this.handleClose())}
                      guardarBeneficiario={(b) => (this.guardarBeneficiarioBridge(b))}
                  />
            );
        }
    }
}

export default BeneficiarioEditData;
