import React from "react";
import {MdInfoOutline} from 'react-icons/md';
import variables from "../../variables/env.js";


class AlertasTrimestre extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            alertasTrimestre:[],
        };
        this.infoHidden = props.infoHidden || false;
        this.tokenAccess = props.tokenAccess;
    }

    componentDidMount(){
        fetch(variables.apiurl+'index.php/dashboard/alertasEnviadoRemesasTrimestre',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess, lang:variables.lang})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        alertasTrimestre : result.result
                    });
                }else if (result.action==='login'){
                    // window.location.href = '/Login'
                }
            }
        );
    }

    render() {
        const {alertasTrimestre} = this.state;
        return(
                <div className="row mt-2 mb-1">

                    <div className="col-12">
                    {/* si  trimestre (info)*/}
                    { !this.infoHidden && alertasTrimestre && alertasTrimestre.info && alertasTrimestre.info.map((info, index) => {
                        return <div key={index}  className="alert alert-info alert-dismissible" role="alert">
                                  <MdInfoOutline /> {info}
                                </div>
                        })
                    }
                    {/* si trimestre (alertas)*/}
                    { alertasTrimestre && alertasTrimestre.alertas && alertasTrimestre.alertas.map((alerta, index) => {
                        return <div key={index}  className="alert alert-warning alert-dismissible" role="alert">
                                <MdInfoOutline /> {alerta}
                                </div>
                        })
                    }
                    {/* si trimestre (errores)*/}
                    { alertasTrimestre && alertasTrimestre.errores && alertasTrimestre.errores.map((error, index) => {
                        return  <div key={index}  className="alert alert-danger" role="alert">
                                <MdInfoOutline /> {error}
                                </div>
                        })
                    }
                    </div>
                </div>
        );
    }
}

export default AlertasTrimestre;
