import React, {useEffect, useState} from "react";
import withRouter from '../../withRouter';
import getLanguage from '../../language/getLanguage';
import PrivateArea from '../cliente/PrivateArea';
import Pasos from './Pasos.js';
import '../../assets/css/paqueteria.css';
import variables from "../../variables/env.js";
const wrapperClass = (variables.mienv === 'ivenes' || variables.mienv === 'ivencom') ? 'iven' : (variables.mienv === 'wwwscom')  ? 'wws' : '';

class Paqueteria extends React.Component {
    constructor(props){
        super(props); 
   
        this.state = {
            error : null,
            isLoaded : false,
            dataFinalEnvio:null, 
            tipopaqueteria: props.params.tipo || '1', //aerea
            pasosDesactivados: []
        }
        this._isMounted = false;
        
    }
    componentDidMount(){
        const lang = getLanguage();
        const _self = this;
        const params = {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
          };
         
          fetch(variables.apiurl + 'index.php/paqueteria/pasosDesactivados', params).then(
            (response) => response.json()
          ).then(
            (result) => {
                _self.setState({pasosDesactivados: result.result});
            },
            (error) => {
                console.log(error);
            },
          );
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.add('dashboard', 'logged');
    }//componentWillUnmount

    render() {
        const {tipopaqueteria, pasosDesactivados} = this.state;
        return(
            <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="payment_tab pt-0" activeLink={(tipopaqueteria == '1')?"PaqueteriaAerea":"PaqueteriaMaritima"}>
                <div className={wrapperClass + " payment_wrap paqueteria_wrap"}>
                    <Pasos idTipoPaqueteria = {tipopaqueteria} pasosDesactivados = {pasosDesactivados}/>
                </div>
            </PrivateArea>
        )
     }
}

export default withRouter(Paqueteria);





