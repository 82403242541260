import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import variables from "../../variables/env.js";
import getLanguage from '../../language/getLanguage';
const lang = getLanguage();

function RecPass() {
    const {t, i18n } = useTranslation(['login']);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [KOmessage, setKOmessage] = useState('');
    const [OKmessage, setOKmessage] = useState('');
    const [apiBanner, setApiBanner] = useState('');

     /*BANNER DESDE API-----------------------------*/
     if (apiBanner === ''){
        fetch(variables.apiurl+'index.php/contenidos/bannerlogin',{
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({lang:lang})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                  setApiBanner(result.result);
                }
            }
        );
      }



    const handleInputFocus = event => {
        setKOmessage('');
    }
    const onSubmit = data => {
      console.log(data);
        fetch(variables.apiurl+'login/recuperar-contrasena.php', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((Response) => Response.json())
            .then((result) => {
                if (result.status === 'ok'){
                    setOKmessage(result.message);
                }else{
                    setKOmessage(result.message);
                }
            })
    }

  return (<main className="forgot-main">
      <section className="forgot_section">
          <div className='container'>
              <div className='row align-items-center bg-light'>
                    <div className="col-lg-6 col-12 pt-4 pr-md-4 order-lg-2">
                      <h2>{t('Recuperar.titulo')}</h2>
                      <div className="forgot_form">
                          <p>{t('Recuperar.ayuda')}</p>
                          <form onSubmit={handleSubmit(onSubmit)}>
                              <input {...register('site_name',{ required: true})}
                                type="hidden" name='site_name' defaultValue={variables.mienv} className="form-control"/>
                              <input {...register('enviar_email',{ required: true})}  type="hidden" name='enviar_email' defaultValue={0}  className="form-control"/>
                              <label htmlFor="email">
                                  Email
                                  <input {...register('email',{ required: true, pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })}
                                    type="text" name='email' onFocus={handleInputFocus} placeholder={t('Recuperar.placeholder')} aria-describedby="emailHelp" className="form-control"/>
                                  {KOmessage && <div className="alert alert-danger text-jutifify" role="alert"> <i className="fa fa-times" aria-hidden="true"></i> {t('Recuperar.KOmessage')}</div>}
                                  {OKmessage && <div className="alert alert-success pr-25 pl-25" role="alert"><i className="fa fa-check" aria-hidden="true"></i> {OKmessage}</div>}
                                  {errors && errors.email && errors.email.type==='required' && <small id="emailHelp" className="form-text text-danger">{t('form.error.Email requerido')}</small>}
                                  {errors && errors.email && errors.email.type==='pattern' && <small id="emailHelp" className="form-text text-danger">{t('form.error.Email inválido')}</small>}
                              </label>
                              <button type="submit" className="transparent_btn">{t('Recuperar.boton')}</button>
                              <a href="/login" className="to_login_link">{t('Recuperar.inicia')} </a>
                              <b>{t('Nueva cuenta')} <a href="/registro">{t('REGISTRATE')} </a> </b>
                          </form>
                      </div>
                  </div>
                  <div className='col-lg-6 col-12 order-lg-1'>
                    <div dangerouslySetInnerHTML={{__html: apiBanner}} />
                  </div> 
                 
              </div>
          </div>
      </section>
  </main>);
}

export default RecPass;
