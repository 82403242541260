import React from 'react';
import { Translation } from 'react-i18next';
class Impuesto extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            initdata:null, 
            sino:null,
        }
        this._isMounted = false;
        //stepzilla
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.isValidated = this.isValidated.bind(this);
        
        //props functions
        this.idTipoPaqueteria = props.idTipoPaqueteria; 
        this.fetchApi = props.fetchApi.bind(this); //bind from props 
        this.handleToast = props.handleToast.bind(this); //bind from props 
        this.setValuesImpuesto= props.setValuesImpuesto.bind(this); //bind from props 
        this.getValuesImpuesto = props.getValuesImpuesto.bind(this); //bind from props 
    }
    componentDidMount(){
        const _self = this;
        this.fetchApi({
            endpoind:'index.php/paqueteria/impuestoForm',
            body:{ id: this.idTipoPaqueteria }
          }, function(result){
                if (result && result.status ==='ok' && result.result){
                    _self.setState({isLoaded: false, initdata: result.result});
                }else{
                    _self.setState({isLoaded: true, error: result.message})
                }
                 //obtenemos valores ya informados
                 const values = _self.getValuesImpuesto();
                 _self.setState({sino: values});
                 _self._isMounted = true;
            },
        )
    }
    componentWillUnmount() {
        this._isMounted = false;
    }//componentWillUnmount

    isValidated() {
        let isDataValid = true; //no validamos, la respuesta puede ser si o no
        this.setValuesImpuesto(this.state.sino);
        return isDataValid;
    }
   
    render() {
        const {error, isLoaded, initdata, sino, respuestavalor} = this.state;
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
       
            return(
                <Translation ns={['paqueteria']}>{(t) =>
                <>
                    <div className="step_content mt-3">
                    {initdata &&
                    <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                        {/*BANNER*/}
                        <div className={"d-flex align-items-end banner-header impuestos" }>
                            <div className="title">{t(initdata.concepto)} </div> 
                        </div>   
                        <div className="first_step_content_item_wrap d-block p-3">

                            
                            {/**pregunta SINO */}
                            <div className='row'> 
                                <div className='col-12 mt-5'><h6>{t(initdata.preguntasino)}</h6></div>
                                <div className='col-6'>
                                    <span 
                                        id="opcionsi"
                                        onClick={(e) => this.setState({sino:'si'})}
                                        className={sino === 'si' ? 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3 active' : 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3'}
                                        > {t('Si')}
                                    </span>
                                    <span     
                                        id="opcionno"
                                        onClick={(e) => this.setState({sino:'no'})}
                                        className={sino === 'no' ? 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3 active' : 'btn-sino btn btn-sm mr-2 mt-2 pl-3 pr-3'}
                                        > {t('No')}
                                    </span> 
                                </div>
                            
                            </div>
                            {/**pregunta SI */}
                            {sino == 'si' && initdata.preguntavalor && <div className='row'> 
                                
                                <div className='col-12 mt-5'>
                                    <label className='label'>{t(initdata.preguntavalor )}<span className="required"> (*)</span></label>
                                    <input type="number" id="respuestavalor" className="form-control"
                                        onChange={(e) => this.setState({respuestavalor:e.target.value})}
                                        value={respuestavalor}
                                        autoFocus
                                        required
                                    />
                                </div>    
                            </div>}

                            {initdata.descripcion && <div className='mt-5 row'>
                                <div className='col-12'>
                                    <div className=' p-5 bg-light mb-5'><p dangerouslySetInnerHTML={{__html: initdata.descripcion}} /></div>
                                </div>
                            </div>}   
                        </div>
                    </div>
                    }
                    </div>    
                </>
                }
                </Translation>
            );
        }  
  }
}

export default Impuesto;
