
import React from 'react';
import { Translation } from 'react-i18next';
import EjecutarPago from '../../pago/EjecutarPago';
import variables from "../../../variables/env.js";

class Pago extends React.Component {
    constructor(props){
        super(props);
       //props functions
       this.idTipoPaqueteria = props.idTipoPaqueteria; 
       this.fetchApi = props.fetchApi.bind(this);  
       this.handleToast = props.handleToast.bind(this); 
       this.getDataFinalEnvio = props.getDataFinalEnvio.bind(this); 
   }
   componentDidMount(){
        this._isMounted = true;
   }
   componentWillUnmount() {
       this._isMounted = false;
   }//componentWillUnmount

    render() {
        return(
            <Translation ns={['traslation', 'paqueteria']}>
            {(t) =><>
                <div className="step_content mt-3">
                <div className="step_content_item step_content_item_active first_step_content_item pt-0">
                    
                     <div className="first_step_content_item_wrap d-block p-3">
                      
                        <EjecutarPago
                            tipoPago={'paqueteria'}
                            tokenAccess={variables.auth('token')}
                            getDataFinal={this.getDataFinalEnvio}
                        />
                        
                                  
                     </div>
                </div>
                </div>    
            </>
            }
            </Translation>
        );
  }
}

export default Pago;
