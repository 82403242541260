import React from "react";
import PrivateArea from './PrivateArea';
import { Translation } from 'react-i18next';

import variables from "../../variables/env.js";

const numberFormat = (value, currency) =>
    new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: (currency) ? currency : 'EUR'
    }).format(value);

class Dashboard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isLoaded : true,
            datosPersonales : {},
            tarifaEUR:{},
            tarifas:[],
            totalEnviado:{},
            beneficiarios:[],
            saldo:0,
            saldoMovimientos:[],
            transferencias:[],
            paqueteria:[],
            servicios:[],
            serviciosActivos:[],
            arrayServi:[],
        };
    }
    componentDidMount(){
        this.tokenAccess = variables.auth('token');
        if (!this.tokenAccess){
          window.location.href = '/login';
        }else{
          this.getDatosPersonales();
          this.serviciosActivoUsuario();
          this.tarifas();
          this.beneficiarios();
          this.totalEnviadoRemesaHistorico();
          this.saldoDisponibleIntercom();
          this.movimientosSaldo();
          this.transferencias();
          this.paqueteria();
          this.servicios();
          this.metodosPagosDisponibles = variables.auth('metodosPagosDisponibles');
          this.metodosPagosApp = variables.auth('metodosPagosApp');
        }

    }
    checkMetodosPago = (metodo)=>{
      return this.metodosPagosDisponibles.includes(Number(metodo)) || this.metodosPagosDisponibles.includes(String(metodo));
    }
    /** datos personales **/
    getDatosPersonales = () =>{
        fetch(variables.apiurl+'index.php/cliente/datosPersonales',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
              body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        datosPersonales : result.result
                    });
                }
            }
        );
    }
    /** Servicios Activos Usuario **/
    serviciosActivoUsuario = () =>{
        fetch(variables.apiurl+'index.php/cliente/serviciosActivos',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
              body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok' && result.result.data && result.result.data.length > 0 ){
                    this.setState({
                        serviciosActivos : result.result.data.split(',')
                    });
                }
            }
        );
    }
    /** tarifas actualizadas **/
    tarifas = () =>{
        fetch(variables.apiurl+'index.php/remesa/consultaTarifasMarquee',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        isLoaded:false,
                        tarifaEUR : result.result[0],
                        tarifas : result.result.slice(1)
                    });
                }
            }
        );
    }
    /** beneficiarios **/
    beneficiarios = () =>{
      fetch(variables.apiurl+'index.php/beneficiario/usuarioBeneficiarios',{
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({dashboard:1, token:this.tokenAccess})
      }).then( response => response.json())
      .then(
          (result) => {
              if (result.status ==='ok'){
                  this.setState({
                      isLoaded:false,
                      beneficiarios : result.result.data
                  });

              }
          }
      )
    }
    /** total enviado por histórico **/
    totalEnviadoRemesaHistorico = () =>{
        fetch(variables.apiurl+'index.php/dashboard/totalEnviadoRemesasHistorico',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        isLoaded:false,
                        totalEnviado : (result.result.total_enviado.length > 0) ? result.result.total_enviado[0] : {moneda_destino: 'VES',total: 0}
                    });
                }
            }
        );
    }
    /** total saldo disponible **/
    saldoDisponibleIntercom = () =>{
        fetch(variables.apiurl+'index.php/remesa/consultaSaldo',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess})
        }).then( response => response.json())
            .then(
                (result) => {
                    if (result.status ==='ok'){
                        this.setState({
                            isLoaded:false,
                            saldo : result.result || 0
                        });
                    }
                }
            );
    }
    /** movimientosSaldo **/
    movimientosSaldo = () =>{
        fetch(variables.apiurl+'index.php/cliente/obtenerMovimientosSaldo',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({dashboard:1,  token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        isLoaded:false,
                        saldoMovimientos : result.result.data
                    });
                }
            }
        );
    }
    /** transferencias === histórico de remesas **/
    transferencias = () =>{
        fetch(variables.apiurl+'index.php/cliente/obtenerHistoricoRemesas',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({dashboard:1,  token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){

                    this.setState({
                        isLoaded:false,
                        transferencias : result.result.data
                    });
                }
            }
        );
    }
    /** Grid envio paqueteria  **/
    paqueteria = () =>{
        fetch(variables.apiurl+'index.php/cliente/obtenerHistoricoPaqueteria',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({dashboard:1,  token:this.tokenAccess})
        }).then( response => response.json())
        .then(
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        isLoaded:false,
                        paqueteria : result.result.data
                    });
                }
            }
        );
    }
    /** Grid Servicios  **/
    servicios = () =>{
        fetch(variables.apiurl+'index.php/cliente/obtenerServicios',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({dashboard:1, token:this.tokenAccess})
        }).then( response => response.json())
            .then(
                (result) => {
                    if (result.status ==='ok'){
                        this.setState({
                            isLoaded:false,
                            servicios : result.result.data
                        });
                    }
                }
            );
    }

    render() {
        document.body.classList.add('dashboard', 'logged');
        const {isLoaded, datosPersonales, totalEnviado, saldo,
                tarifaEUR, tarifas, beneficiarios, saldoMovimientos,
                transferencias, paqueteria, metodos, servicios, serviciosActivos} = this.state;
        return(
          <Translation ns={['translation', 'dashboard', 'cupones-digitales']}>
              {}
          {
            (t) =><>
             {isLoaded &&
                <div className="loading Dashboard">
                <div className="spinner-border"></div>
                </div>
            }
            {!isLoaded && <>
                <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="dashboard_tab" activeLink="dashboard">
                      {/** TARIFA ACTUAL **/}
                      {serviciosActivos.length > 0 && serviciosActivos.indexOf('4') > -1 && tarifas.length >0 && <div className="dashboard_tab_item course_item">
                            <div className="courses_wrap">
                              <div className="btn-group">
                                <button type="button" className="courses_item_btn">
                                  <span className="courses_item_btn__currency">€</span>
                                  <span className="courses_item_btn__info">
                                    <span className="first_value">
                                         1,00 EUR
                                    </span>
                                    <span className="second_value">{numberFormat(tarifaEUR.tarifa , 'VES')}</span>
                                  </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  {tarifas.map((tarifa, index) => {
                                    return <div key={index} className="dropdown-item courses_item_btn">
                                              <div className="courses_item_btn__currency">$</div>
                                                  <div className="courses_item_btn__info">
                                                    <div className="first_value">
                                                      1 {tarifa.moneda_origen}
                                                    </div>
                                                    <div className="second_value">{numberFormat(tarifa.tarifa, tarifa.monedas_destino)} </div>
                                                  </div>
                                              </div>
                                  })}
                                </div>
                              </div>
                            </div>
                      </div>  }
                      {/** FIN TARIFA ACTUAL **/}

                    {/*** SALDO **/}
                    {serviciosActivos.length > 0 && serviciosActivos.indexOf('4') > -1 && tarifas.length > 0 && this.checkMetodosPago(this.metodosPagosApp.saldo) &&
                      <div className="dashboard_tab_item course_item">
                        <div className="courses_wrap ">
                          <div className="saldo_wrap">
                            <div className="courses_item_btn">
                              <div className="courses_item_btn__currency">
                                <img src={variables.appurl + "assets/img/EURi.svg"} alt={variables.sitename}/>
                              </div>
                              <div className="courses_item_btn__info">
                                <div className="first_value">
                                  EURi {t('Saldo')}
                                </div>
                                <div className="second_value">{saldo}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {/** PROFILE **/}
                      <div className="dashboard_tab_item user_info_item">
                        <div className="user_info_wrap w-100">
                          <div className="user_info m-0">
                            <div className="user_name ml-0">{datosPersonales.nombre}</div>
                          </div>
                        </div>
                        <div className="user_adress_wrap w-100">
                          <p>{datosPersonales.direccion_postal}</p>
                          <p className="mb-2">{datosPersonales.codigo_postal} {datosPersonales.ciudad} {datosPersonales.pais}</p>
                          <p className="icon-phone mb-2"><span>{datosPersonales.telefono}</span></p>
                          <p className="icon-email"><span>{datosPersonales.correo}</span></p>
                          <div className="edit_btn edit_user float-right"><a href="/perfil" className="edit_user"></a></div>
                        </div>

                        <div className="divider mt-3"></div>
                        <div className="user_money_wrap">
                            {serviciosActivos.length > 0 && serviciosActivos.indexOf('4') > -1 &&
                                <>
                                    <p>
                                        {t('titulototalenviado', { ns: 'dashboard' })}
                                    </p>
                                    <b> {totalEnviado.total} {totalEnviado.moneda_destino}</b>
                                </>
                            }
                            {serviciosActivos.length > 0 && (serviciosActivos.indexOf('11') > -1 || serviciosActivos.indexOf('12') > -1 || serviciosActivos.indexOf('13') > -1 ) &&
                                <a href="/nuestros-servicios" className="accent_btn pl-4 pr-4">{t('Servicios')}</a>
                            }
                        </div>
                      </div>
                      {/************************** TASAS ACTIVAS **************************/}

                    {serviciosActivos.length > 0 && serviciosActivos.indexOf('4') > -1 && tarifas.length > 0 &&
                          <div className="dashboard_tab_item lastest_saldo_wrap">
                              <div className="dashboard_tab_item_title">
                                  <h4>{t('Tasas Vigentes Otras Monedas', { ns: 'dashboard' })}</h4>
                              </div>
                              <div>
                                  {tarifas.map((tarifa, index) => {
                                      return <div className="lastest_saldo_title dashboard_table_row dashboard_table_row_title d-block" key={index}>
                                          <div className="saldo_id_title d-block">
                                            <div className="accent_btn">1  {tarifa.moneda_origen}  =  {numberFormat(tarifa.tarifa, tarifa.monedas_destino)}</div>
                                          </div>
                                      </div>
                                  })}
                              </div>
                          </div>
                      }

                      {/********* MOVIMIENTOS DE SALDO ************/}
                      {serviciosActivos.length > 0 && serviciosActivos.indexOf('4') > -1 && tarifas.length > 0 && this.checkMetodosPago(this.metodosPagosApp.saldo) &&
                          <div className="dashboard_tab_item lastest_saldo_wrap">
                            <div className="dashboard_tab_item_title">
                              <h4>{t('Último Movimientos de Saldo', { ns: 'dashboard' })}</h4>
                              <a href="/saldo-movimientos"> {t('Ver')}</a>
                            </div>
                              <div className="lastest_saldo dashboard_table">
                                <div className="lastest_saldo_title dashboard_table_row dashboard_table_row_title">
                                  <div className="saldo_id_title">ID</div>
                                  <div className="saldo_date_title"> {t('Fecha')}</div>
                                  <div className="saldo_imported_title">{t('Importe')}</div>
                                  <div className="saldo_balance_title">{t('Saldo')}</div>
                                  <div className="saldo_condition_title">{t('Estado')}</div>
                                </div>
                                {saldoMovimientos && saldoMovimientos.map((m, index) => {
                                  return  <div className="lastest_saldo_item dashboard_table_row" key={index}>
                                        <div className="saldo_id_item">{m.id}</div>
                                        <div className="saldo_date_item">{m.fecha}</div>
                                        <div className="saldo_imported_item">{numberFormat(m.importe, '')} </div>
                                        <div className="saldo_balance_item">{numberFormat(m.total, '')}</div>
                                        <div className="saldo_condition_item"><div className="accent_btn">{m.status}</div></div>
                                    </div>
                                })}
                                </div>
                          </div>
                      }

                        {serviciosActivos.length > 0 && serviciosActivos.indexOf('4') > -1 && tarifas.length > 0 &&<>
                            {/**************** Remesas *****************/}
                            <div className="dashboard_tab_item lastest_transfers_wrap">
                        <div className="dashboard_tab_item_title">
                          <h4>{t('Últimas Remesas enviadas', { ns: 'dashboard' })}</h4>
                          <a href="/remesas-historico">  {t('Ver')}</a>
                        </div>
                        <div className="lastest_transfers dashboard_table">
                          <div className="lastest_saldo_title dashboard_table_row dashboard_table_row_title">
                            <div className="transfers_condition_title">{t('Estado')}</div>
                            <div className="transfers_date_title">{t('Fecha')}</div>
                            {/* <div className="transfers_invoice_title">{t('Factura')}</div> */}
                            <div className="transfers_amount_sent_title">{t('Cantidad enviada')}</div>
                            <div className="transfers_received_amount_title">{t('Cantidad recibida')}</div>
                            <div className="transfers_beneficiary_amount_title">{t('Beneficiario')}</div>
                          </div>
                          {transferencias && transferencias.map((trans, index) => {
                            return  <div className="lastest_transfers_item dashboard_table_row" key={index}>
                              <div className="transfers_condition_item">
                                <div className="accent_btn green_btn">{trans.estado}</div>
                              </div>
                              <div className="transfers_date_item">{trans.fecha_timestamp}</div>
                              {/* <div className="transfers_invoice_item">
                                  <a href={variables.apiurl+'factura-remesa.php?token='+this.tokenAccess+'&id=' + trans.id+'&variables.mienv=' + variables.mienv} target="_blank" rel="norel">{'Factura-'+trans.n_factura}</a>
                              </div> */}
                              <div className="transfers_amount_sent_item">{trans.cantidad_enviada}</div>
                              <div className="transfers_received_amount_item">{trans.cantidad_recibida}</div>
                              <div className="transfers_beneficiary_amount_item">{trans.beneficiario}</div>
                            </div>
                          })}
                        </div>
                      </div>
                            {/**************** Fin Remesas *****************/}
                            {/***************** BENEFICIARIOS *****************/}
                            <div className="dashboard_tab_item beneficiaries_wrap">
                                <div className="dashboard_tab_item_title">
                                    <h4>{t('Beneficiario')}</h4>
                                    <a href="/beneficiarios">{t('Ver')}</a>
                                </div>
                                <div className="beneficiaries_wrap_table">
                                    {beneficiarios && beneficiarios.map((b, index) => {
                                        return  <div className="beneficiaries_wrap_table_item"  key={index}>
                                            <p>{b.nombre_beneficiario}</p>
                                            <div className="edit_btn"><a href={'/beneficiario/'+b.id}></a></div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </>
                        }
                      {/*************** PAQUETERIA ****************/}
                        {serviciosActivos.length > 0 && (serviciosActivos.indexOf('11') > -1 || serviciosActivos.indexOf('12') > -1 || serviciosActivos.indexOf('13') > -1 ) &&
                          <div className="dashboard_tab_item lastest_transfers_wrap">
                              <div className="dashboard_tab_item_title">
                                  <h4>{t('Últimos envios de paquetería', { ns: 'dashboard' })} </h4>
                              </div>
                              <div className="lastest_transfers dashboard_table">
                                  <div className="lastest_saldo_title dashboard_table_row dashboard_table_row_title">
                                      <div className="transfers_condition_title">{t('Estado')}</div>
                                      <div className="transfers_date_title">{t('Fecha')}</div>
                                      <div className="transfers_date_title">{t('N Seguimiento')}</div>
                                      <div className="transfers_invoice_title">{t('Factura')}</div>
                                      <div className="transfers_beneficiary_amount_title">{t('Beneficiario')}</div>
                                  </div>
                                  {paqueteria && paqueteria.map((paq, index) => {
                                      return  <div className="lastest_transfers_item dashboard_table_row" key={index}>
                                          <div className="transfers_condition_item">
                                              <div className="accent_btn green_btn">{paq.estado}</div>
                                          </div>
                                          <div className="transfers_date_item">{paq.fecha_timestamp}</div>
                                          <div className="transfers_date_item">{paq.numero_seguimiento}</div>
                                          <div className="transfers_invoice_item">
                                              <a href={variables.adminurl+'/facturas-ticket-comprobantes/paqueteria/ficha-envio.php?paqueteria='+ paq.id_paqueteria} target="_blank" rel="norel">{t('Factura')}-{paq.id_paqueteria}</a>
                                          </div>
                                          <div className="transfers_amount_sent_item">{paq.beneficiario}</div>
                                      </div>
                                  })}
                              </div>
                          </div>
                        }
                    {/*************** Resumen Servicios ****************/}
                    {serviciosActivos.length > 0 && (serviciosActivos.indexOf('6') > -1 || serviciosActivos.indexOf('7') > -1 || serviciosActivos.indexOf('8') > -1 || serviciosActivos.indexOf('9') > -1) &&
                        <div className="dashboard_tab_item lastest_transfers_wrap">
                          <div className="dashboard_tab_item_title">
                              <h4>{t('Últimos servicios Adquiridos', { ns: 'dashboard' })} </h4>
                          </div>
                          <div className="lastest_servicios dashboard_table">
                              <div className="lastest_saldo_title dashboard_table_row dashboard_table_row_title">
                                  <div className="transfers_condition_title">{t('Estado')}</div>
                                  <div className="transfers_date_title">{t('Fecha')}</div>
                                  <div className="transfers_date_title">{t('Tipo de Producto')}</div>
                                  <div className="transfers_invoice_title">{t('Cantidad')}</div>
                                  <div className="transfers_invoice_title">{t('Importe')}</div>
                                  <div className="transfers_beneficiary_amount_title">{t('Código Digital')}</div>
                              </div>
                              {servicios && servicios.map((ser, index) => {
                                  return  <div className="lastest_transfers_item dashboard_table_row" key={index}>
                                      <div className="transfers_condition_item">
                                          <div className="accent_btn green_btn">{ser.estado}</div>
                                      </div>
                                      <div className="transfers_date_item">{ser.fecha_timestamp}</div>
                                      <div className="transfers_date_item">{ser.tipo_producto}</div>
                                      <div className="transfers_date_item">{ser.cantidad}</div>
                                      <div className="transfers_date_item">{ser.cantidad_pagada}</div>
                                      <div className="transfers_date_item">{ser.codigo_digital}</div>
                                  </div>
                              })}
                          </div>
                      </div>
                    }

            </PrivateArea>
            </>}
            </>
          }
          </Translation>
        );
    }
}

export default Dashboard;
