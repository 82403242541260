import React from 'react';
import PrivateArea from '../cliente/PrivateArea';
import BeneficiariosAdmin from "./BeneficiariosAdmin";


class Beneficiarios extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return(
        <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper beneficiaries_wrap" tabClass="beneficiaries-tab" activeLink="beneficiarios" >
          <BeneficiariosAdmin/>
        </PrivateArea>

      );

    }
}

export default Beneficiarios;
