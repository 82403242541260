import React, { useState } from 'react';
import PrivateArea from '../cliente/PrivateArea';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import variables from "../../variables/env.js";

export default function CambiarPass(props) {

    const apiURL = process.env.REACT_APP_API_URL;
    const { t } = useTranslation(['login']);
    const {register, watch, handleSubmit, formState: { errors }  } = useForm();
    const [KOmessage, setKOmessage] = useState('');
    const [OKmessage, setOKmessage] = useState('');
    const [redirectTimeout, setRedirectTimeout] = useState(false);

    const handleInputFocus = event => {
            setKOmessage('');
    }
    const onError = (errors, e) => { console.log('errors', errors)};
    const onSubmit = formData => {
      fetch(apiURL+'index.php/cliente/cambiarPass', {
          method: 'POST',
          body: JSON.stringify({
              password1: document.getElementById('password1').value,
              password2: document.getElementById('password2').value,
              token: variables.auth('token'),
              env: variables.mienv
          })
        })
        .then(response => response.json())
        .then((result) => {
            if (result.status === 'ok'){
                setOKmessage(result.message);
            }else{
                setKOmessage(result.message);
            }
            setTimeout(() => window.location.href = 'login', 3000);
        })
        .catch(error => {
          console.error(error);
          setKOmessage('error');
        });

    }

  return (
<PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="password-tab" activeLink="password" servicioRemesaActivo={props.servicioRemesaActivo}>
            <div className="change_password_wrap">
              {KOmessage && <div className="alert alert-danger text-uppercase" role="alert"><i className="fa fa-times" aria-hidden="true"></i> {KOmessage}</div>}
              {OKmessage && !KOmessage && <div className="alert alert-success text-uppercase" role="alert"><i className="fa fa-check" aria-hidden="true"></i> {OKmessage}</div>}
              {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
               <form onSubmit={handleSubmit(onSubmit, onError)}>
                <p className="text-center">{t('newPass.titulo')}</p>
                <label className="text-center w-100">{t('newPass.subtitulo')}</label>
                <br/>
                {/* register your input into the hook by invoking the "register" function */}
                <div className="form-group">
                  <label htmlFor="password" className="mt_4">{t('form.Escribe tu nueva Contraseña')}</label>
                  <input {...register('password1', { required: true,minLength: 6 })}
                   type="password" id='password1' name='password1' onFocus={handleInputFocus}  placeholder={t('form.Escribe tu nueva Contraseña')} className="form-control rounded"/>
                   {errors && errors.password1 && errors.password1.type==='required' && <small id="passHelp" className="form-text text-danger">{t('form.error.Contraseña requerida')}</small>}
                   {errors && errors.password1 && errors.password1.type==='minLength' && <small id="passHelp" className="form-text text-danger">{t('form.error.Contraseña inválida')}</small>}

                </div>
                <div className="form-group">
                    <label htmlFor="password_confirm">{t('form.Confirma tu nueva Contraseña')}</label>
                      <input {...register('password2',{
                            required: true,
                            minLength: 6,
                            validate: (value) => {
                              return value === watch('password1'); // value is from password2 and watch will return value from password1
                            }
                          })}  type="password" id='password2' name='password2' onFocus={handleInputFocus} placeholder={t('form.Confirma tu nueva Contraseña')} aria-describedby="passHelp" className="form-control rounded"/>

                    {errors && errors.password2 && errors.password2.type==='required' && <small id="passHelp" className="form-text text-danger">{t('form.error.Contraseña requerida')}</small>}
                    {errors && errors.password2 && errors.password2.type==='minLength' && <small id="passHelp" className="form-text text-danger">{t('form.error.Contraseña inválida')}</small>}
                    {errors && errors.password2 && errors.password2.type==='validate' && <small id="passHelp" className="form-text text-danger">{t('form.error.Contraseñas diferentes')}</small>}
                  </div>
                  <button type="submit" className="transparent_btn  ">{t('newPass.boton')}</button>
              </form>
        </div>
</PrivateArea>
   );
}
