import React, { Component } from 'react';
import Calculadora from './Calculadora';
import DatosPersonalesFormPopup from '../cliente/DatosPersonalesFormPopup'; //condiciones
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';


import SendFormData from "../../server/sendFormData";

import { Translation } from 'react-i18next';

const publicURL = process.env.REACT_APP_IMAGES_URL;
const apiURL = process.env.REACT_APP_API_URL;

class RemesaCantidadEnviar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            monedaDestinoData:props.getMonedaDestino(),
            monedaOrigenData:props.getMonedaOrigen(),
            tarifa:props.getTarifa(),
            valueRecibe: props.getCantidadRecibe(),valueEnvia: props.getCantidadEnvia(),
            buttonSubmit:false,
            valueEnviaMaximo : null,
            valueEnviaMinimo : null,
            codigo :null,
            popupCondicionesProfile : null, condicionesProfile:null,
            codigoValid : null,tarifaPromo:null

        }
        this.tokenAccess = props.tokenAccess;
        this.handleToast = props.handleToast.bind(this);
        //this.setMonedaOrigenData = props.setMonedaOrigenData.bind(this);
        this.setMonedaDestinoData = props.setMonedaDestinoData.bind(this);
        this.setMonedaOrigenData = props.setMonedaOrigenData.bind(this);

        this.getMonedaDestinoDefault = props.getMonedaDestinoDefault.bind(this);
        this.setCantidad = props.setCantidad.bind(this);
        this.setCodigo = props.setCodigo.bind(this);
        //  console.dir(this.state);
    }
    /* setMonedaDestinoData (json), setCantidadEnviar (€)*/
    setStateData = (data) => {
        if (data.monedaDestinoData){
            //  console.dir('RemesaCantidadEnviar setStateData monedaDestinoData'); console.dir(data.monedaDestinoData);
            this.setState({monedaDestinoData:data.monedaDestinoData});
            this.setMonedaDestinoData(data.monedaDestinoData);
        }
        if (data.monedaOrigenData){
            //    console.dir('RemesaCantidadEnviar setStateData monedaOrigenData'); console.dir(data.monedaOrigenData);
            this.setState({monedaOrigenData:data.monedaOrigenData});
            this.setMonedaOrigenData(data.monedaOrigenData);
        }
        if (data.valueEnvia){
            let values = {
                valueEnvia: data.valueEnvia,
                valueRecibe : data.valueRecibe
            }
            this.setState(values);
            this.setCantidad(values);

        }
        if (data.maximo && data.minimo){
            this.setState({
                valueEnviaMaximo : data.maximo,
                valueEnviaMinimo : data.minimo
            });
        }
    }
    submitForm = (e) => {
        // console.log(e);
    }
    isValidated = () => {
       // console.log('isValidated valueEnvia', this.state.valueEnvia);
        let validCondiciones = false;
        let max = (this.state.valueEnviaMaximo)?Number(this.state.valueEnviaMaximo):this.state.monedaDestinoData.maximo;
        let min = (this.state.valueEnviaMinimo)?Number(this.state.valueEnviaMinimo):this.state.monedaDestinoData.minimo;
        //console.log(this.state.valueEnviaMaximo,this.state.valueEnviaMinimo)
        let validValue = (this.state.valueEnvia && Number(this.state.valueEnvia) >= min && Number(this.state.valueEnvia) <= max && this.state.valueRecibe) || false;
        if (!validValue){
            this.handleToast(`Debe ingresar una cantidad a enviar entre ${min}€ y ${max}€`,'bg-warning');
        }else{
            validCondiciones = this.checkCondiciones();
        }
        return (validValue && validCondiciones) || false;

    }
    checkCondiciones = ()=>{
        let v = null;
        /*condiciones profile*/
        if (!this.state.condicionesProfile && this.state.monedaDestinoData.condiciones.profile && this.state.monedaDestinoData.condiciones.profile.length > 0){
            this.setState({popupCondicionesProfile:true});
            v = false;
        }else{
            v = true;
        }
        return v;
    }
    handleSubmitCondicionesProfile = (r)=>{
        this.setState({popupCondicionesProfile:false});
        if (r){
            this.setState({condicionesProfile:r});
        }
    }
    envioCodigo = (event)=>{
      const self = this;
      const codigo = event.target.value;

      if (codigo.length > 0){
        self.setState({codigo:codigo});
        fetch(apiURL +'index.php/remesa/validarCodigoPromocional',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              token:this.tokenAccess,
              data:{codigo:codigo}
            })
        })
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                self.setState({
                  codigoValid:result.result.valid,
                });
                if (result.result.porcentaje) {
                    const tarifa_actual = Number(self.state.monedaDestinoData.tarifa);
                    const nueva_tarifa = tarifa_actual + (tarifa_actual * Number(result.result.porcentaje)/100);
                    self.setState({
                      tarifaPromo:nueva_tarifa.toFixed(4),
                      tarifa:nueva_tarifa.toFixed(4)
                    });
                    this.setCodigo(codigo);
                }else{
                  self.setState({tarifaPromo:null});
                  self.setState({tarifa:self.state.monedaDestinoData.tarifa});
                  this.setCodigo('');
                }
            },
            // Handle error
            (error) => {
                console.log(error);
            },
        );
      }else{
        self.setState({
          codigo:null,
          tarifaPromo:null,
          tarifa:self.state.monedaDestinoData.tarifa
        });
        this.setCodigo('');
      }

    }
/*----------------------------*/

/*--------- RENDER -----------*/
    render() {
        return(
<Translation ns={['remesas']}>
{
  (t) =>

  <div className="step_content">
    <div className="step_content_item step_content_item_active first_step_content_item" style={{'paddingBottom':'40px'}}>
        <h4 className="text-uppercase">{t('Completa todos los pasos para enviar remesa a')}  <span className="primary-color">{this.state.monedaDestinoData.pais}</span></h4>


        <div className="first_step_content_item_wrap">
           {/*this.state.monedaDestinoData && <div className="content_item_image d-none d-md-block">
                <img src={publicURL + this.state.monedaDestinoData.pais.toLowerCase()+'_vista.jpg'} alt={this.state.monedaDestinoData.pais} />
            </div>*/}

            <div className='send_to_wrap mb-0'>
                <Calculadora
                    getMonedaDestinoDefault={() => (this.getMonedaDestinoDefault())}
                    idDestinoTarifa={this.state.monedaDestinoData.id}
                    pais={this.state.monedaDestinoData.pais}
                    moneda={this.state.monedaDestinoData.moneda}
                    valueRecibe = {this.state.valueRecibe}
                    valueEnvia = {this.state.valueEnvia}
                    setStateData={(data) => (this.setStateData(data))}
                    submitForm={() => (this.submitForm())}
                    buttonSubmit ={this.state.buttonSubmit}
                    tarifasCondicionadas = {true}
                    tarifa={this.state.tarifa}
                    tokenAccess={this.tokenAccess}
                />

              </div>{/*send_to_wrap*/}
              {/* CODIGO PAGOS ESPECIALES onChange={this.envioCodigo}*/}
              <div className='promocode_wrap'>
                <div className="promocode_title">{t('codigo input')}</div>
                <FormControl fullWidth  variant="outlined">

                    <OutlinedInput
                      id="outlined-adornment-amount"
                      onChange={this.envioCodigo}
                      startAdornment={<InputAdornment position="start"> </InputAdornment>}
                      labelWidth={300}
                    />

                    <InputLabel htmlFor="outlined-adornment-amount">
                      {this.state.codigo!==null && this.state.codigoValid && <span><Icon className="fa fa-check text-success" /></span>}
                      {this.state.codigo!==null && !this.state.codigoValid && <span><Icon className="fa fa-times text-danger" /></span>}
                    </InputLabel>

                    {this.state.monedaDestinoData && <div className="text-center pb-3">
                        <p className="mt-5">
                            {t('Tipo de cambio')}: <br/>1.00 {this.state.monedaOrigenData.moneda} = &nbsp;
                            {this.state.tarifaPromo===null && <span className="font-weight-bold"> {this.state.monedaDestinoData.tarifa} {this.state.monedaDestinoData.moneda}</span>}
                            {this.state.tarifaPromo!==null && <span className="font-weight-bold text-success"><del className="text-danger">{this.state.monedaDestinoData.tarifa}</del> {this.state.tarifaPromo} {this.state.monedaDestinoData.moneda}</span>}
                        </p>
                        <p>{t("Comisión")} = <span className="font-weight-bold">{this.state.monedaDestinoData.comision} €</span></p>
                    </div>}
                </FormControl>

              </div>





        </div>{/*first_step_content_item_wrap*/}



        <DatosPersonalesFormPopup
            intro={t('Para continuar con el envío de dinero a') + ' ' + this.state.monedaDestinoData.pais + ', ' + t('deberá antes completar algunos datos requeridos en su perfil.')+' (*)'}
            isOpen={this.state.popupCondicionesProfile} tokenAccess={this.tokenAccess}
            requiredFields={(this.state.monedaDestinoData.condiciones)?this.state.monedaDestinoData.condiciones.profile:[]}
            guardarRegistro={(r) => (this.handleSubmitCondicionesProfile(r))}
            onClose={() => (this.handleSubmitCondicionesProfile(null))}
            />
    </div>
      </div>

 }
</Translation>
            );
    }

}
export default RemesaCantidadEnviar;
