/*
Respuesta stripe
*/
import React from 'react';
import withRouter from '../../withRouter';
import { NavLink } from "react-router-dom";
import { Translation } from 'react-i18next';
import PrivateArea from "../cliente/PrivateArea";
import variables from "../../variables/env.js";


class RespuestaPagosServiciosEfectivas extends React.Component {
    constructor(props){
        super(props);
        this.mensaje = this.props.params.mensaje || 'GRACIAS POR USAR NUESTROS SERVICIOS.';
    }
    render() {
        return(
            <Translation ns={['servicios']}>
                {
                    (t) =>
                    <>
                      { variables.auth() &&
                        <PrivateArea mainClass="dashboard-main" wrapperClass="dashboard_wrapper" tabClass="payment_tab">
                            <div className="profile_wrap">
                                <div className="result_header">
                                    <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.appname} /></a>  </div>
                                </div>
                                <div className="result_body">
                                    <p className="lead font-weight-bold"> <i className="fa fa-check text-success" aria-hidden="true"></i> {t(this.mensaje)}</p>
                                    <div className="orange_divider"></div>
                                    <p className="text-center mt-5">
                                        <NavLink className="btn btn-outline-default" to="/nuestros-servicios">{t('Nuestros Servicios')}</NavLink>
                                    </p>
                                </div>
                                
                                <div className="result_footer ">
                                    <div className="polygon_item"></div>
                                </div>
                            </div>
                        </PrivateArea>
                      }
                      {!variables.auth() &&
                        <div className="container">
                        <div className="profile_wrap">
                            <div className="result_header">
                                <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.appnamename} /></a>  </div>
                            </div>
                            <div className="result_body">
                                <p className="lead font-weight-bold"><i className="fa fa-exclamation text-danger font-weight-600" aria-hidden="true"></i> {t(this.mensaje)}</p>
                                <div className="orange_divider"></div>
                                <p className="text-center mt-5">
                                  <NavLink className="btn btn-outline-default" to="/nuestros-servicios">{t('Nuestros Servicios')}</NavLink>
                                </p>
                            </div>
                            <div className="result_footer ">
                                <div className="polygon_item"></div>
                            </div>
                        </div>
                        </div>
                      }
                    </>
                }
            </Translation>
        );

    }
}

export default withRouter(RespuestaPagosServiciosEfectivas);
