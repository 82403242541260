import React from 'react';
import useGetHTMLFetch from "../../server/useGetHTMLFetch";
import { useTranslation } from 'react-i18next';

export default function NuestrosServicios(props) {
    const content = useGetHTMLFetch('index.php/contenidos/nuestrosServicios');
    return(
        <div className="hiw">
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
    );
}

