import React, { Component } from 'react';
import { Translation } from 'react-i18next';

const publicURL = process.env.REACT_APP_URL;
const sitename = process.env.REACT_APP_NAME;
const mienv = process.env.REACT_APP_MIENV;

class detalleServicios extends Component{
    constructor(props){
        super(props);
        this.state = {
            isLoaded : true,
            active: false,
            data:[],
            importes:[],
            informacionServicio: null
        };
        this.handle = this.props.match.params;
        this.servicio = this.handle.servicio;
        this.atributo = this.handle.atributo;
    }

    componentDidMount(){
        const apiURL = process.env.REACT_APP_API_URL;
        fetch(apiURL+'index.php/servicios/actionDetallesServicios',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({servicio:this.servicio,
                                        producto:this.atributo
            })
        }).then( response => response.json())
            .then(
                // handle the result
                (result) => {
                    if (result.status ==='ok'){
                        this.setState({
                            isLoaded:false,
                            data : result.result.data,
                            importes: result.result.data.importes,
                            informacionServicio: result.result.data.instrucciones,
                        });
                    }else{
                        this.setState({
                            isLoaded:false,
                            error: result.message
                        });
                    }
                },
                // Handle error
                (error) => {
                    this.setState({
                        error
                    })
                },
            )
    }

    activePrice = (event, data, key) =>{
        this.setState({ active: key});
    }

    render() {
        const {isLoaded, error, data, importes, servicio, informacionServicio} = this.state;
        return(
                <Translation ns={['detalles-servicios']}>
                    {
                        (t) =>
                            <main id="wrapper" className="pt-1" role="main">
                                <div className="container mb-5 container-height-low">
                                    {isLoaded && <div className="loading">Loading</div>}
                                   <div className="row mt-4">
                                       <div className="col-12">
                                           <h4 className="mb-0">Tarjeta de regalo {data.nombre && data.nombre}</h4>
                                           <span className="text-secondary text-small">Envío inmediato del código por e-mail <img src={publicURL + 'assets/img/icono-check.png'} alt={sitename}/></span>
                                       </div>
                                       <div className="col-6">
                                           <div className="mt-4">
                                               <img src={publicURL + 'assets/img/' + data.nombre_logo} alt={sitename}/>
                                           </div>
                                           <div className="mt-4">
                                               {this.servicio == 'telefonia' ?  <ul>
                                                       <li className="m-2">
                                                           <img src={publicURL + 'assets/img/icono-check.png'} alt={sitename}/> Recarga Digital<br/>
                                                           <span className="text-secondary pl-34 text-pequeno">Canjea el producto directamente</span>
                                                       </li>
                                                       <li className="m-2">
                                                           <img src={publicURL + 'assets/img/icono-check.png'} alt={sitename}/>Envió instantáneo por e-mail<br/>
                                                           <span className="text-secondary pl-34 text-pequeno">Todos los productos se envían al instante por e-mail</span>
                                                       </li>
                                                       <li className="m-2"> <img src={publicURL + 'assets/img/icono-check.png'} alt={sitename}/>El mejor precio<br/>
                                                           <span className="text-secondary pl-34 text-pequeno">Mejor precio garantizado</span>
                                                       </li>
                                                   </ul> :
                                                   <ul>
                                                       <li className="m-2">
                                                           <img src={publicURL + 'assets/img/icono-check.png'} alt={sitename}/> Envío al e-mail<br/>
                                                           <span className="text-secondary pl-34 text-pequeno">Se envía el código al correo con el cual se realiza la compra</span>
                                                       </li>
                                                       <div className="text-secondary pl-34 text-pequeno" dangerouslySetInnerHTML={{ __html: informacionServicio }}></div>
                                                   </ul>
                                               }
                                           </div>
                                       </div>
                                       <div className="col-4">
                                           <div className="mt-5">
                                               <h3>Seleccionar un crédito</h3>
                                           </div>
                                           <div className="mt-4 col-12 pl-0">

                                               {importes && importes.map((importe, index) => {
                                                   return <button key={index}
                                                                  type="button"
                                                                  className={this.state.active === index ? 'btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3 border-active-intercom' : 'btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3'}
                                                                  onClick={(e) => this.activePrice(e, {importe}, index)}
                                                                  role="button">{importe.importes} €</button>
                                                   })
                                               }
                                               <a href={ this.servicio == 'cupones' ? '/cupones-digitales' : '/servicios-telefonicos' }
                                                  className="btn btn-block btn-color-default mt-4"
                                                  role="button">Comprar
                                               </a>
                                           </div>

                                           <div className="mt-4 col-12 pl-0">
                                               <h6>Descripción</h6>
                                               <p className="mt-3 text-secondary">{data.descripcion && data.descripcion}</p>
                                           </div>

                                       </div>
                                   </div>
                                </div>
                            </main>
                    }
                </Translation>
        );
    }
}
export default detalleServicios;
