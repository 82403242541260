import React, { Component } from 'react';
import Calculadora from './Calculadora';
import { Translation } from 'react-i18next';


class RemesaCalculadora extends Component {
    constructor(props){
        super(props);
        this.state = {
            id:null,
            pais:null,
            tarifa:0,
            moneda:'EUR',
            valueRecibe: '',valueEnvia: '',
            fireRedirect: false
        }
    }
    submitForm = (e) => {
        window.location.href = `/Remesa/${this.state.id}`;
    }
    setStateData = (data) => {
        this.setState(data);
    }

    render() {
        return(
<Translation ns={['remesas']}>
{
  (t) =>
            <div className="pt-5 calc-wrapper">
                <div className="container-fluid">
                    <header className="text-center">
                        <h4 className="text-uppercase">{t('CALCULA TU ENVIO')}</h4>
                    </header>
                    <div className="row justify-content-md-center mb-3 mt-3">
                        <div className="col-12 col-form">
                            <Calculadora
                                setStateData={(data) => (this.setStateData(data))}
                                submitForm={() => (this.submitForm())}
                                buttonSubmit ={(true)}
                                buttonText={t('Enviar dinero ahora')}
                            />

                            <p className="small text-center pt-1">
                                Tipo de cambio: <br/>
                                    1.00 {this.state.moneda}&nbsp;=&nbsp;<span className="font-weight-bold"> {this.state.tarifa} {this.state.moneda}</span><br/>
                                    {t('Tranferencia el mismo día')}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
 }
</Translation>
            );
    }

}
export default RemesaCalculadora;
