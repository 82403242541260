import React from 'react';
import Form from "react-jsonschema-form";
import FormTools from '../common/formTools'

import '../../assets/css/form.css';

import CustomToast from '../common/CustomToast';
import { Translation } from 'react-i18next';

import variables from "../../variables/env.js";


class DatosPersonalesForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            imagenIdentificacionFile : [],
            data: null,
            toastMsg:null,toastShow:null,toastClass:null
        };
        this.tokenAccess = variables.auth('token');
        this.requiredFields = props.requiredFields || null;
        this.guardarRegistro = props.guardarRegistro || null;

    }

    customFieldTemplate = (props)=>{
        const {id, classNames, label, help, required, description, errors, children, hidden, readonly} = props;
        return (
          <Translation ns={['registro']}>
          {
            (t) =>
            <div className={classNames}>
              {!hidden && label && <label htmlFor={id}>{t(label)}
              {required && <span className="required"> ({t('dato obligatorio')})</span>}
              {!required &&<span className="norequired"> ({t('opcional')})</span>}
              </label>}

                  {description}
                  {children}
                  {help}
                  {errors.props && errors.props.errors && errors.props.errors.length > 0 && errors.props.errors.map((k,index) => {
                      return  <div key={index} className="text-danger small">
                          {t(''+errors.props.errors[index])}
                      </div>
                    })
                  }
            </div>
          }
          </Translation>
        );

    }

    hideParentToast = () => {
        this.setState({toastShow:false});
    }

    onChangeFile = (event) => {
        const eleImg = document.querySelector('#img_'+event.target.id);
        if (eleImg){
            eleImg.src = URL.createObjectURL(event.target.files[0]);
            if (eleImg.classList.contains('d-none')){ eleImg.classList.remove('d-none'); }
            if (!eleImg.classList.contains('img-thumbnail')){ eleImg.classList.add('img-thumbnail'); }
        }
        let files = this.state.imagenIdentificacionFile;
        files[event.target.name] = event.target.files[0];
        this.setState({imagenIdentificacionFile:files});
        let d = this.state.data;
        d.result.schema.definitions['ref_identificacion']['properties'][event.target.name]['default'] =  event.target.files[0].name;
        this.setState({data:d});
    }
    onChange = ({formData}) => {
        let d = this.state.data;
        Object.keys(formData).map((key) => {
            if (formData[key] == null || formData[key] == undefined) { formData[key] = ''; }
            if (d.result.schema.properties[key] && d.result.schema.properties[key]['properties'] && typeof formData[key]==='object'){
                Object.keys(formData[key]).map((k, idx) => {
                    if (formData[key][k] == null || formData[key][k] == undefined) { formData[key][k] = ''; }
                    if (d.result.schema.properties[key]['properties'][k]){
                        d.result.schema.properties[key]['properties'][k]['default'] = formData[key][k];
                    }
                });
            }else if (d.result.schema.properties[key]){
              if  (key.includes('nombre') || key.includes('apellidos')){
                 if(formData[key].match(/^[a-zA-Z\s\b]*$/)){
                   d.result.schema.properties[key].default = formData[key].toUpperCase();
                 }
              }else{
                d.result.schema.properties[key].default = formData[key];
              }
            }
        });
        this.setState({data:d});
    }

    validate= (formData, errors) => {
      //console.dir('fn validate');
      //console.dir(formData);
      //  if (!formData.identificacion.imagen_documento_reverso){ formData.identificacion.imagen_documento_reverso = 'no aplica';}
      return false;
    }
    onSubmit = ({formData}) => {
        let form_data = new FormData();
        Object.keys(formData).map((key, i) => {
            if (typeof formData[key]!=='object') { form_data.append(key,formData[key]); }
            if (typeof formData[key]==='object') {
                Object.keys(formData[key]).map((k, idx) => {
                  form_data.append(k,formData[key][k]);
                });
            }
        });
        Object.keys(this.state.imagenIdentificacionFile).map((key, i) => {
            form_data.append(key,this.state.imagenIdentificacionFile[key]);
        });
        form_data.append('token',this.tokenAccess);
        //for debug;
        // for (var p of form_data) {
        //   console.log(p);
        // }
        fetch(variables.apiurl+'index.php/cliente/actualizaDatosPersonales', {
            method: 'POST',
            body: form_data
          })
          .then(response => response.json())
          .then(res => {
            this.setState({
                toastMsg:res.message,
                toastShow:true,
                toastClass:(res.status==='ok')?'success':'error'
            });
            if (this.guardarRegistro && res.status==='ok') {this.guardarRegistro(res.result);}
          })
          .catch(error => {
            console.error(error)
          });

    }
    componentDidMount(){

        fetch(variables.apiurl+'index.php/cliente/datosPersonalesForm',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:this.tokenAccess, required_fields:this.requiredFields})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                    this.setState({
                        isLoaded:false,
                        data : result
                    });
                }else{
                    this.setState({
                        isLoaded:false,
                        error: result.message
                    });
                    if (result.action==='login'){
                          window.location.href = '/Login';
                    }
                }
            },

            // Handle error
            (error) => {
                this.setState({
                    error
                })
            },
        )
    }
    render() {
        const {error, data, isLoaded} = this.state;
        return(
<Translation ns={['translation']}>
{
  (t) =>
            <>
                <CustomToast msg={this.state.toastMsg} show={this.state.toastShow} tclass={this.state.toastClass} hideParentToast={() => (this.hideParentToast())}/>
                {/* profile */}
                {isLoaded && <div className="loading"><div className="spinner-border text-muted"></div></div>}
                {/* si KO */}
                    {data && data.status==='ko' &&
                    <div className="col-12 pb-2">{<h6>data.message</h6>}</div>
                    }
                {/* si OK */}
                    { data && data.status==='ok' && <Form className="col-12 contact_form_wrap frmDatosPersonales p-0"
                        schema={data.result.schema}
                        uiSchema={data.result.uiSchema}
                        customFormats={data.result.customFormats}
                        transformErrors={FormTools.transformErrors}
                        onChange={this.onChange}
                        onSubmit={this.onSubmit}
                        FieldTemplate={this.customFieldTemplate}
                        validate={this.validate}
                        >
                        <button className="mb-4" type="submit">{t('Guardar cambios')}</button>
                        </Form>

                    }

            </>
}
</Translation>
        );

    }
}
export default DatosPersonalesForm;
